import * as React from 'react';
import styled from 'styled-components';
import { MEDIA_QUERY } from 'properties/constant';
import type { NewsCategoryListType, NewsCategoryType } from 'types/types';

interface Props {
  category: NewsCategoryListType;
  current: NewsCategoryType;
  setCategory: (category: NewsCategoryType) => void;
}

const Category: React.FC<Props> = ({ category, current, setCategory }) => {
  return (
    <Container>
      {Object.entries(category).map((el, index, els) => {
        const categoryKey = el[0] as NewsCategoryType;
        const categoryValue = el[1];

        return (
          <React.Fragment key={categoryKey}>
            <CategoryItem
              isActive={!!current && current === categoryKey}
              onClick={(e) => setCategory(categoryKey)}
            >
              {categoryValue}
            </CategoryItem>
            {els.length !== index + 1 ? <Divider /> : null}
          </React.Fragment>
        );
      })}
    </Container>
  );
};

export default Category;

const Container = styled.div`
  height: 32px;
  margin-bottom: 58px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 46px;
  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    column-gap: 32px;
  }
`;

const CategoryItem = styled.span<{ isActive: boolean }>`
  font-family: basic-sans, sans-serif;
  font-style: normal;
  font-weight: ${({ isActive }) => (isActive ? 600 : 300)};
  font-size: 21px;
  line-height: 31px;
  text-align: center;
  letter-spacing: -0.05em;
  color: #000000;
  cursor: pointer;
  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    font-size: 19px;
    line-height: 31px;
  }
`;

const Divider = styled.div`
  height: 16px;
  width: 1px;
  background: #000000;
`;
