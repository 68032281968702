import {
  AUDIT_REPORT,
  DEVELOPER_DOC,
  GITHUB,
  PROTOCON_DASHBOARD,
  PROTOCON_EXPLORER,
  PROTOCON_WALLET,
  WHITEPAPER_EN,
  WHITEPAPER_KR,
  YELLOWPAPER_1_EN,
  YELLOWPAPER_1_KR,
  YELLOWPAPER_2_EN,
  YELLOWPAPER_2_KR,
} from './links';
import type { Sitemap } from 'types/types';

export const SITE_MAP: Sitemap[] = [
  {
    id: 'about',
    label: 'About',
    sub: [
      // {
      //   id: "intro-to-protocon",
      //   label: "Intro to Protocon",
      // },
      {
        id: 'pen-token',
        label: 'Pen Token',
        link: '/about/pen-token',
      },
      {
        id: 'whitepaper-en',
        label: 'Whitepaper(ENG)',
        link: WHITEPAPER_EN,
      },
      {
        id: 'whitepaper-kr',
        label: 'Whitepaper(KOR)',
        link: WHITEPAPER_KR,
      },
    ],
  },
  {
    id: 'developer',
    label: 'Developer',
    link: '/developer',
    sub: [
      {
        id: 'documents',
        label: 'Documents',
        link: DEVELOPER_DOC,
      },
      {
        id: 'github',
        label: 'Github',
        link: GITHUB,
      },
      {
        id: 'yellowpaper-en',
        label: 'Yellowpaper1(ENG)',
        link: YELLOWPAPER_1_EN,
      },
      {
        id: 'yellowpaper-en-2',
        label: 'Yellowpaper2(ENG)',
        link: YELLOWPAPER_2_EN,
      },
      {
        id: 'yellowpaper-kr',
        label: 'Yellowpaper1(KOR)',
        link: YELLOWPAPER_1_KR,
      },
      {
        id: 'yellowpaper-kr-2',
        label: 'Yellowpaper2(KOR)',
        link: YELLOWPAPER_2_KR,
      },
      {
        id: 'audit-report',
        label: 'Audit Report',
        link: AUDIT_REPORT,
      },
    ],
  },
  {
    id: 'ecosystem',
    label: 'Ecosystem',
    sub: [
      {
        id: 'dashboard',
        label: 'Dashboard',
        link: PROTOCON_DASHBOARD,
      },
      {
        id: 'explorer',
        label: 'Explorer',
        link: PROTOCON_EXPLORER,
      },
      {
        id: 'protocon-wallet',
        label: 'Protocon Wallet',
        link: PROTOCON_WALLET,
      },
    ],
  },
  {
    id: 'community',
    label: 'Community',
    sub: [
      {
        id: 'news',
        label: 'News',
        link: '/community/news',
      },
      {
        id: 'channel',
        label: 'Official Channel',
        link: '/community/channel',
      },
      {
        id: 'grants',
        label: ['Protocon Grants', '(To be updated)'],
      },
    ],
  },
];
