import { createGlobalStyle } from 'styled-components';
import { mixinCss } from './mixin';
import { resetCss } from './reset';

export default createGlobalStyle`
  ${resetCss}
  ${mixinCss}
  
  html,
  body {
  }

  #root {
    overflow-x: hidden;
  }

  *,
  ::before,
  ::after {
    box-sizing: border-box;
  }

  body {
    overflow-x: hidden; /*height:100%;*/
    font-family:"ofelia-text", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 12px;
    line-height: 1.3;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: none;
    -moz-osx-font-smoothing: gratranslate3d(0, 0, 0) yscale;
    -ms-overflow-style: none;
  }

  img {
    width: 100%;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  .max-w {
    max-width: 960px;
    margin: 0 auto;
  }

  article {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
  }
`;
