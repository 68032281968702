import * as React from 'react';

const Naver: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => {
  const name = 'icon-naver';
  const gradientId = `${name}-gradient`;

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={34.187}
        cy={34.17}
        r={32.915}
        fill={`url(#${gradientId})`}
        stroke="#fff"
        strokeWidth={0.981}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m48.46 20.605.003.006-.036-.031a.768.768 0 0 1 .032.025Zm-.73-.25c.268-.012.546-.024.73.25.281.239.254.552.228.855a3.042 3.042 0 0 0-.015.26c.009 8.095.009 16.19 0 24.285-.003.093.004.19.01.286.022.306.043.62-.234.87l-.223.1c-.112.382-.396.366-.67.35-.053-.002-.105-.005-.156-.005h-8.184c-.045 0-.092.002-.138.004-.281.011-.572.023-.666-.377a26.035 26.035 0 0 0-2.113-3.201c-.488-.661-.95-1.34-1.413-2.019a72.334 72.334 0 0 0-1.267-1.82c-.567-.784-1.09-1.6-1.614-2.416-.683-1.066-1.367-2.133-2.15-3.13a1.296 1.296 0 0 0-.364-.224c-.292.25-.265.57-.238.882.007.089.015.177.015.263-.014 3.484-.014 6.965-.014 10.45 0 .087.008.176.016.265.027.312.054.632-.239.883l-.223.089c-.085.365-.362.362-.637.36h-8.1c-.266.005-.536.009-.629-.347l-.223-.1c-.29-.256-.264-.579-.238-.893.007-.089.015-.177.015-.263-.01-8.095-.01-16.19 0-24.286 0-.084-.008-.171-.015-.259-.027-.307-.054-.624.239-.864l-.01.015.031-.032a.742.742 0 0 0-.021.017c.166-.238.424-.238.678-.238h8.523a.738.738 0 0 1 .612.186c.053.021.116.038.178.054.132.036.264.07.305.151.575 1.158 1.31 2.216 2.044 3.272.465.67.93 1.338 1.353 2.032 1.224 2.012 2.615 3.92 4.02 5.811.143.193.254.41.364.626.208.406.415.812.836 1.062.36-.255.327-.608.295-.95a3.098 3.098 0 0 1-.018-.287c.008-1.837.008-3.675.008-5.512 0-1.696 0-3.392.007-5.088 0-.087-.008-.177-.016-.267-.028-.312-.058-.633.264-.869a.29.29 0 0 0 .188-.038c.195-.208.444-.2.688-.19l.145.002h7.86c.057 0 .116-.002.175-.005Z"
        fill="#fff"
      />
      <defs>
        <linearGradient
          id={gradientId}
          x1={11.972}
          y1={-16.469}
          x2={100.639}
          y2={19.229}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4AE6E6" />
          <stop offset={1} stopColor="#FC72FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Naver;
