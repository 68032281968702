import * as React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  isActive?: boolean;
}

const Medium: React.FC<Props> = ({ isActive = false, ...props }) => {
  const name = 'icon-medium';
  const gradientIdA = `${name}-gradient-a`;
  const gradientIdB = `${name}-gradient-b`;
  const gradientIdC = `${name}-gradient-c`;
  const gradientIdD = `${name}-gradient-d`;

  const circleFill = isActive ? '#fff' : `url(#${gradientIdA})`;
  const circleStroke = isActive ? `url(#${gradientIdA})` : '#fff';
  const pathFillA = isActive ? 'none' : '#fff';
  const pathFillB = isActive ? `url(#${gradientIdB})` : 'none';
  const pathFillC = isActive ? `url(#${gradientIdC})` : 'none';
  const pathFillD = isActive ? `url(#${gradientIdD})` : 'none';

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={25.001}
        cy={25.001}
        r={24.001}
        fill={circleFill}
        stroke={circleStroke}
        strokeWidth={0.7}
        strokeMiterlimit={10}
      />
      {/* Default */}
      <path
        d="M18.644 33.08a8.02 8.02 0 1 0 0-16.04 8.02 8.02 0 0 0 0 16.04ZM31.475 32.012c2.067 0 3.743-3.112 3.743-6.95 0-3.84-1.676-6.951-3.743-6.951s-3.742 3.111-3.742 6.95 1.675 6.95 3.742 6.95ZM37.892 32.012c.886 0 1.604-3.112 1.604-6.95 0-3.84-.718-6.951-1.604-6.951-.886 0-1.604 3.111-1.604 6.95s.718 6.95 1.604 6.95Z"
        fill={pathFillA}
      />
      {/* Hover */}
      <path
        d="M18.644 33.08a8.02 8.02 0 1 0 0-16.04 8.02 8.02 0 0 0 0 16.04Z"
        fill={pathFillB}
      />
      <path
        d="M31.475 32.012c2.067 0 3.743-3.112 3.743-6.95 0-3.84-1.676-6.951-3.743-6.951s-3.742 3.111-3.742 6.95 1.675 6.95 3.742 6.95Z"
        fill={pathFillC}
      />
      <path
        d="M37.892 32.012c.886 0 1.604-3.112 1.604-6.95 0-3.84-.718-6.951-1.604-6.951-.886 0-1.604 3.111-1.604 6.95s.718 6.95 1.604 6.95Z"
        fill={pathFillD}
      />
      <defs>
        <linearGradient
          id={gradientIdA}
          x1={8.803}
          y1={-11.923}
          x2={73.456}
          y2={14.106}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4AE6E6" />
          <stop offset={1} stopColor="#FC72FF" />
        </linearGradient>
      </defs>
      <defs>
        <linearGradient
          id={gradientIdB}
          x1={15.317}
          y1={12.723}
          x2={44.947}
          y2={34.195}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4AE6E6" />
          <stop offset={1} stopColor="#FC72FF" />
        </linearGradient>
        <linearGradient
          id={gradientIdC}
          x1={15.317}
          y1={12.723}
          x2={44.947}
          y2={34.195}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4AE6E6" />
          <stop offset={1} stopColor="#FC72FF" />
        </linearGradient>
        <linearGradient
          id={gradientIdD}
          x1={15.317}
          y1={12.723}
          x2={44.947}
          y2={34.195}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4AE6E6" />
          <stop offset={1} stopColor="#FC72FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Medium;
