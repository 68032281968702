import * as React from 'react';
import styled from 'styled-components';
import { useResponsive } from 'hooks/useResponsive';
import { image } from 'assets';
import { MEDIA_QUERY } from 'properties/constant';

const Background: React.FC = () => {
  const { isMobile, isTablet } = useResponsive();

  const background = (() => {
    if (isTablet) return image.background.PageHomeGradientMobile;
    if (isMobile) return image.background.PageHomeGradientTablet;
    return image.background.PageHomeGradientDesktop;
  })();

  return (
    <Container>
      <BgGradient src={background} alt="background" />
      <Object1 src={image.object.Obj3} alt={'bg-obj'} />
      <Object2 src={image.object.Obj20} alt={'bg-obj'} />
      <Object3 src={image.object.Obj5} alt={'bg-obj'} />
      <Object4 src={image.object.Obj23} alt={'bg-obj'} />
      <Object5 src={image.object.Obj23} alt={'bg-obj'} />
      <Object6 src={image.object.Obj26} alt={'bg-obj'} />
      <Object7 src={image.object.Obj17} alt={'bg-obj'} />
      <>
        <Object8 src={image.object.Obj6} alt={'bg-obj'} />
        <ObjectDot1 src={image.object.Obj0} alt={'bg-obj'} />
        <ObjectDot2 src={image.object.Obj0} alt={'bg-obj'} />
      </>
      <>
        <Object9 src={image.object.Obj8} alt={'bg-obj'} />
        <ObjectDot3 src={image.object.Obj0} alt={'bg-obj'} />
        <ObjectDot4 src={image.object.Obj0} alt={'bg-obj'} />
        <ObjectDot5 src={image.object.Obj0} alt={'bg-obj'} />
      </>
      <Object10 src={image.object.Obj9} alt={'bg-obj'} />
    </Container>
  );
};

export default Background;

const Container = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
  * {
    pointer-events: none;
    user-select: none;
  }
`;

const BgGradient = styled.img`
  width: 100%;
  position: absolute;
  height: 2318px;
  top: 6760px;
  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    top: 11000px;
    height: 2543px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    top: 8400px;
    height: 2135px;
  }
  @media (max-width: 350px) {
    top: 9000px;
  }
`;

const Object1 = styled.img`
  position: absolute;
  width: 204.1px;
  height: 209.94px;
  left: 796.02px;
  top: -127px;

  opacity: 0.5;
  filter: blur(7.5px);
  transform: rotate(21.52deg);

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    width: 146.41px;
    height: 150.6px;
    left: 512.24px;
    top: -75px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 86.6px;
    height: 89.08px;
    left: 136.68px;
    top: -35px;
    filter: blur(4.5px);
  }
`;

const Object2 = styled.img`
  position: absolute;
  width: 352.77px;
  height: 290.95px;
  left: 1513.2px;
  top: 199.68px;

  filter: blur(7.5px);
  transform: rotate(-14.56deg);
  opacity: 0.3;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    width: 256.43px;
    height: 211.49px;
    left: 636px;
    top: 412.83px;
  }

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 136.92px;
    height: 112.92px;
    left: 303px;
    top: 131.42px;
    filter: blur(2.5px);
  }
`;

const Object3 = styled.img`
  position: absolute;
  width: 446px;
  height: 565px;
  left: -160px;
  top: 816.75px;
  opacity: 0.5;
  filter: blur(7.5px);
  transform: matrix(0.99, 0.16, -0.16, 0.99, 0, 0);

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    width: 446px;
    height: 565px;
    left: -340px;
    top: 1200.57px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 263.47px;
    height: 333.77px;
    opacity: 0.2;
    left: -170px;
    top: 769.98px;
    filter: blur(2px);
  }
`;

const Object4 = styled.img`
  position: absolute;
  width: 175.2px;
  height: 175.2px;
  right: 160px;
  top: 2489px;
  opacity: 0.3;
  filter: blur(7.5px);
  transform: rotate(27.12deg);

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    position: absolute;
    right: -60px;
    top: 2560px;
    width: 150.47px;
    height: 150.47px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 57.63px;
    height: 57.63px;
    right: 50px;
    top: 3278px;
    filter: blur(3px);
  }
`;

const Object5 = styled.img`
  position: absolute;
  width: 697px;
  height: 697px;
  right: -300px;
  top: 2678.56px;

  opacity: 0.3;
  filter: blur(7.5px);
  transform: rotate(-27.47deg);

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    width: 535.3px;
    height: 535.3px;
    right: -400px;
    top: 2747.69px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    top: 3317.82px;
    width: 229.28px;
    height: 229.28px;
    right: -140px;
    filter: blur(3px);
  }
`;

const Object6 = styled.img`
  position: absolute;
  width: 662px;
  height: 640px;
  left: -131px;
  top: 3510px;

  opacity: 0.2;
  filter: blur(7.5px);
  transform: rotate(49.46deg);

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    width: 543.41px;
    height: 578.85px;
    left: -300px;
    top: 5132.81px;
  }

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 262.3px;
    height: 279.41px;
    left: -80px;
    top: 4329px;
    filter: blur(4px);
  }
`;

const Object7 = styled.img`
  position: absolute;
  width: 679px;
  height: 596px;
  left: -192px;
  top: 5000px;

  filter: blur(7.5px);
  opacity: 0.2;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    width: 535px;
    height: 400px;
    left: -220px;
    top: 7122px;
  }

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 393px;
    height: 301px;
    left: -150px;
    top: 5477px;
    filter: blur(3px);
  }
`;

const Object8 = styled.img`
  position: absolute;
  width: 835px;
  height: 524px;
  right: -400px;
  top: 6339px;

  opacity: 0.3;
  filter: blur(7.5px);
  transform: rotate(-35.74deg);

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    width: 682.49px;
    height: 375.02px;
    left: 355px;
    top: 9032.62px;
  }

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 461px;
    height: 291px;
    left: 237px;
    top: 8045px;
    filter: blur(4.5px);
    transform: rotate(0deg);
  }
`;

const Object9 = styled.img`
  position: absolute;
  width: 555.37px;
  height: 555.37px;
  left: -156px;
  top: 7964px;

  filter: blur(7.5px);
  transform: rotate(-18.39deg);
  opacity: 0.3;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    width: 483.8px;
    height: 483.8px;
    left: -300px;
    top: 11799.47px;
  }

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 219.36px;
    height: 219.36px;
    left: -100px;
    top: 9205.22px;
    filter: blur(2.5px);
  }
`;

const Object10 = styled.img`
  position: absolute;
  width: 670.96px;
  height: 659.83px;
  left: 0px;
  top: 9050.29px;

  opacity: 0.2;
  filter: blur(5px);
  transform: rotate(-13.12deg);

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    width: 551px;
    height: 545.3px;
    left: -223px;
    top: auto;
    bottom: 400px;
  }

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 228.88px;
    height: 225.09px;
    left: -40px;
    filter: blur(3px);
  }
`;

const ObjectDot1 = styled.img`
  position: absolute;
  width: 54px;
  height: 54px;
  left: 233px;
  top: 7864px;

  opacity: 0.2;
  filter: blur(1.5px);

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    display: none;
  }
`;

const ObjectDot2 = styled.img`
  position: absolute;
  width: 29px;
  height: 29px;
  left: 390px;
  top: 8411px;
  opacity: 0.2;
  filter: blur(1.5px);

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    display: none;
  }
`;

const ObjectDot3 = styled.img`
  position: absolute;
  width: 29px;
  height: 29px;
  left: 620px;
  top: 9249.99px;
  opacity: 0.2;
  filter: blur(5px);

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    width: 19.89px;
    height: 19.89px;
    top: auto;
    bottom: 750px;
    left: 350px;
    filter: blur(3px);
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 11px;
    height: 11px;
    left: 180px;
    top: auto;
    bottom: 560px;
    filter: blur(2px);
    opacity: 0.5;
  }
`;

const ObjectDot4 = styled.img`
  position: absolute;
  width: 67px;
  height: 67px;
  left: 47px;
  top: 9367.99px;
  opacity: 0.2;
  filter: blur(1.5px);

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    width: 37.04px;
    height: 37.04px;

    top: auto;
    bottom: 650px;
    left: 265px;
    filter: blur(5px);
  }

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 25px;
    height: 24px;
    left: -5px;
    top: auto;
    bottom: 500px;
    filter: blur(1px);
    opacity: 0.3;
  }
`;

const ObjectDot5 = styled.img`
  position: absolute;
  width: 54px;
  height: 54px;
  left: 566px;
  top: 9391.99px;
  opacity: 0.2;
  filter: blur(5px);
  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    display: none;
  }
`;
