import { useState, useEffect } from 'react';

export function useScrollY() {
  const [scrollY, setScrollY] = useState<number>(0);

  const onScroll = () => {
    setScrollY(window.pageYOffset);
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const getOffset = (speed: number, offsetTop: number) => {
    return (scrollY - offsetTop) * speed;
  };
  return {
    scrollY,
    getOffset,
  };
}
