import * as React from 'react';

const SliderPrev: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => {
  // const name = "icon-slider-prev";

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 17 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M16 1 1 16l15 15" stroke="#000" strokeWidth={1.2} />
    </svg>
  );
};

export default SliderPrev;
