import * as React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  isActive?: boolean;
}

const GoToTopV2: React.FC<Props> = ({ isActive = false, ...props }) => {
  const name = 'icon-go-to-top-v2';
  const filterId = `${name}-filter`;
  const gradientId = `${name}-gradient`;
  const gradientId2 = `${name}-gradient-2`;

  const circleFill = isActive ? '#fff' : `url(#${gradientId})`;
  const circleStroke = isActive ? `url(#${gradientId})` : '#fff';
  const pathStroke = isActive ? `url(#${gradientId2})` : '#fff';

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter={`url(#${filterId})`}>
        <circle cx={16} cy={16} r={16} fill={circleFill} />
        <circle cx={16} cy={16} r={15.5} stroke={circleStroke} />
      </g>
      <path
        d="M9 20.533 15.933 12l6.934 8.533"
        stroke={pathStroke}
        strokeWidth={2}
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id={gradientId}
          x1={5.202}
          y1={-8.615}
          x2={48.303}
          y2={8.737}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4AE6E6" />
          <stop offset={1} stopColor="#FC72FF" />
        </linearGradient>
        <linearGradient
          id={gradientId2}
          x1={11.254}
          y1={9.703}
          x2={26.453}
          y2={19.646}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4AE6E6" />
          <stop offset={1} stopColor="#FC72FF" />
        </linearGradient>
        <filter
          id={filterId}
          x={-20}
          y={-20}
          width={72}
          height={72}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation={10} />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_535_819"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_backgroundBlur_535_819"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default GoToTopV2;
