import styled, { keyframes } from 'styled-components';
import { useEffect, useRef } from 'react';
import { image } from 'assets';
import { useResponsive } from 'hooks/useResponsive';
import { Section } from 'components';
import { GradientText, Relative } from 'styles/Common';
import { MEDIA_QUERY } from 'properties/constant';
import { HOME_FEEFI } from 'properties/contents';

const FeeFi = () => {
  const animationRef = useRef<HTMLDivElement>(null);
  const { isTablet, isMobile } = useResponsive();

  useEffect(() => {
    let observer = new IntersectionObserver((elements) => {
      if (elements[0].isIntersecting) {
        elements[0].target.classList.add('animate');
      } else {
        elements[0].target.classList.remove('animate');
      }
    });

    observer.observe(animationRef.current!);
  }, []);

  const { text, card } = HOME_FEEFI;

  return (
    <Container>
      <Relative>
        <div>
          <Title>
            <GradientText>Fee Fi</GradientText>nancing
          </Title>
          <Text>
            {isMobile ? text.mobile : isTablet ? text.tablet : text.desktop}
          </Text>
          {/* TODO: 문서 작업 미완료으로 인한 숨김 처리, 문서 완료 후 오픈 예정 (23.2.10) */}
          {/* <ButtonWithArrow label={"Learn more"} onClick={() => {}} /> */}
        </div>

        <Main>
          <ImageContainer>
            <Object2 src={image.object.ObjBrokenLine} alt="broken line" />
            <Animation ref={animationRef}>
              <Object1 src={image.object.Obj0} alt={'bg-obj'} />
              <Object1 src={image.object.Obj0} alt={'bg-obj'} />
              <Object1 src={image.object.Obj0} alt={'bg-obj'} />
            </Animation>
          </ImageContainer>
          <Object3 src={image.object.BgObjFeeFi} alt="object" />
          <CardContainer>
            <CardSubTitle>{card.subTitle}</CardSubTitle>
            <CardTitle>{card.title}</CardTitle>
            <CardButton>
              <span>{card.buttonLabel}</span>
            </CardButton>
          </CardContainer>
        </Main>
      </Relative>
    </Container>
  );
};

export default FeeFi;

const Container = styled(Section)`
  padding-top: 0px;
  padding-bottom: 200px;
  width: 945px;
  margin: 0 auto;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    width: 100%;
    padding: 680px 77px 200px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    padding: 150px ${MEDIA_QUERY.MOBILE.BASE_X_PADDING}px 200px;
    width: 100%;
  }
`;

const SlideDown = keyframes`
0% {
  transform: translateY(0px);
}

100% {
  transform: translateY(60px);
}
`;

const SlideDownMobile = keyframes`
0% {
  transform: translateY(0px);
}

100% {
  transform: translateY(37px);
}
`;

const Title = styled.div`
  font-family: ofelia-text, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 58px;
  letter-spacing: -0.06em;
  text-align: left;
  padding-bottom: 20px;
  white-space: pre-line;

  padding-bottom: 20px;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    padding-bottom: 30px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 32px;
    line-height: 46px;
    padding-bottom: 18px;
  }
`;

const Text = styled.div`
  font-family: basic-sans, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 21px;
  line-height: 31px;
  letter-spacing: -0.06em;
  white-space: pre-line;

  padding-bottom: 36px;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    padding-bottom: 40px;
  }

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 17.5px;
    line-height: 30px;
  }

  @media (max-width: 340px) {
    white-space: initial;
  }
`;

const ImageContainer = styled.div`
  position: absolute;
  left: -80px;
  top: 420px;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    left: -20px;
    top: 420px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    top: 335px;
    right: 0px;
    left: auto;
    z-index: -1;
  }
`;

const Animation = styled.div`
  &.animate {
    img {
      animation-name: ${SlideDown};
      animation-duration: 0.6s;
      animation-fill-mode: forwards; /* 종료 후 위치 */
      @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
        animation-name: ${SlideDownMobile};
      }
    }
  }
`;

const Object1 = styled.img`
  width: 14px;
  height: 14px;
  position: absolute;

  &:nth-child(1) {
    left: 32px;
    top: 60px;
    animation-delay: 0.2s;
  }
  &:nth-child(2) {
    left: 170px;
    top: -30px;
    animation-delay: 0.5s;
  }

  &:nth-child(3) {
    left: 300px;
    top: -90px;
    animation-delay: 0.8s;
  }

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 6.57px;
    height: 6.57px;
    &:nth-child(1) {
      left: 15px;
      top: 25px;
    }
    &:nth-child(2) {
      left: 80px;
      top: -15px;
    }

    &:nth-child(3) {
      left: 140px;
      top: -45px;
    }
  }
`;

const Object2 = styled.img`
  width: 323px;
  height: 260px;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    min-width: 152px;
    margin-top: 10px;
    width: 152px;
    height: 122px;
  }
`;

const Object3 = styled.img`
  transform: rotate(-41.24deg);
  position: absolute;
  width: 692px;
  height: 358.21px;
  right: -119px;
  top: 386px;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    min-width: 727.52px;
    height: 360px;
    right: 105px;
    top: 750px;
    left: -16px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    display: none;
  }
`;

const Main = styled.div`
  width: 463px;
  margin: 0 0 0 auto;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    margin-top: 380px;
    margin-left: 0px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 100%;
    margin: 120px 0 auto;
    box-sizing: border-box;
  }
`;

const CardContainer = styled.div`
  width: 463px;
  height: 399px;

  background: linear-gradient(
    174.83deg,
    rgba(151, 242, 239, 0.3) 6.7%,
    rgba(252, 114, 255, 0.3) 146.55%
  );
  backdrop-filter: blur(2.5px);
  border-radius: 20px;
  padding: 35px 30px;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  flex: 1;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    padding: 30px;
    margin-left: 60px;
    height: 400px;
    width: 531px;
  }

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 100%;
    margin-left: 0px;
    height: 307px;
    padding: 25px;
  }
`;

const CardSubTitle = styled.div`
  font-family: ofelia-text, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 36px;
  letter-spacing: -0.05em;
  padding-bottom: 10px;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 16px;
    line-height: 36px;
    padding-bottom: 5px;
  }
`;
const CardTitle = styled.div`
  font-family: ofelia-text, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  letter-spacing: -0.05em;
  padding-bottom: 30px;
  white-space: pre-line;
  line-height: 42px;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 19px;
    line-height: 30px;
  }
`;
const CardButton = styled.div`
  color: #000000;
  border: 1px solid #000000;
  border-radius: 13px;
  padding: 20px 34px;
  position: absolute;
  right: 35px;
  bottom: 35px;

  span {
    font-family: ofelia-text, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: -0.05em;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    right: 25px;
    bottom: 25px;
    padding: 20px 34px;
  }
`;
