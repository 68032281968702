import * as React from 'react';
import styled from 'styled-components';
import { hiddenPreview } from 'assets/images/poster';

const HiddenPreview: React.FC = () => {
  return <Container src={hiddenPreview} alt={'protocon-preview'} />;
};

export default HiddenPreview;

const Container = styled.img`
  position: fixed;
  top: -200px;
  height: 100px;
  object-fit: contain;
  opacity: 0;
  user-select: none;
  pointer-events: none;
`;
