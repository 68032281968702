import * as React from 'react';
import { useResponsive } from 'hooks/useResponsive';
import { image } from 'assets';
import { Content } from 'components';
import Background from './Background';
import WhatIsPen from './WhatIsPen';
import Service from './Service';
import ProtoconWallet from './ProtoconWallet';
import PriceGraph from './PriceGraph';
import BuyPen from './BuyPen';

// TODO: PenToken 으로 디렉토리 수정 필요
const PenToken: React.FC = () => {
  const { isTablet, isMobile } = useResponsive();

  const background = isMobile
    ? image.background.PageAboutMobile
    : isTablet
    ? image.background.PageAboutTablet
    : image.background.PageAboutDesktop;

  return (
    <Content background={background}>
      <Background />
      <WhatIsPen />
      <Service />
      <ProtoconWallet />
      <PriceGraph />
      <BuyPen />
    </Content>
  );
};

export default PenToken;
