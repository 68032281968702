import * as React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  isActive?: boolean;
}

const Mexc: React.FC<Props> = ({
  isActive = false,
  width = 208,
  height = 35,
  ...props
}) => {
  // const name = "icon-mexc";
  // const gradientId = `${name}-gradient`;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 208 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1701_1448"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="6"
        y="5"
        width="196"
        height="24"
      >
        <path
          d="M43.9425 21.8635L35.6494 7.48525C33.8185 4.49651 29.4027 4.44266 27.6525 7.61988L18.9286 22.5905C17.3131 25.3369 19.3056 28.7834 22.5636 28.7834H40.0114C43.2963 28.7834 45.7465 25.2292 43.9425 21.8635Z"
          fill="#00B897"
        />
        <path
          d="M31.9607 22.9943L31.4491 22.1058C30.9645 21.2711 29.9144 19.494 29.9144 19.494L22.8868 7.29671C21.4867 5.16959 18.6326 4.60415 16.5054 6.00428C15.8323 6.43509 15.2938 7.05438 14.9438 7.78137L6.78531 21.9173C5.51981 24.0983 6.27373 26.8985 8.48162 28.164C9.18169 28.5679 9.96253 28.7833 10.7703 28.7833H39.9307C35.4341 28.8102 34.0071 26.4677 31.9607 22.9943Z"
          fill="#76FCB2"
        />
        <path
          d="M31.9607 22.9946L31.4492 22.106C30.9645 21.2714 29.9144 19.4943 29.9144 19.4943L25.3909 11.5243L18.8749 22.6176C17.2594 25.364 19.2519 28.8105 22.5099 28.8105H39.9307C35.4072 28.7836 34.0071 26.468 31.9607 22.9946Z"
          fill="url(#paint0_linear_1701_1448)"
        />
        <path
          d="M200.434 27.6526C199.438 27.6795 198.469 27.4372 197.634 26.9525C196.907 26.4948 196.476 25.687 196.53 24.8254V10.9049H198.388C199.061 10.9049 199.626 11.4703 199.626 12.1435V24.3946C199.653 24.7985 199.815 25.1755 200.111 25.4716C200.407 25.7678 200.811 25.9294 201.215 25.9294H201.565C201.7 25.9294 201.834 26.0371 201.834 26.1986V27.7065L200.434 27.6526Z"
          fill="#00B897"
        />
        <path
          d="M160.557 27.6526C158.646 27.6526 157.084 27.141 155.899 26.1179C155.334 25.6601 154.903 25.0678 154.634 24.3946C154.365 23.7484 154.257 22.8329 154.257 21.5944C154.257 20.3558 154.391 19.4134 154.634 18.7941C154.903 18.1479 155.334 17.5825 155.872 17.1247C157.057 16.1016 158.619 15.59 160.53 15.59C162.442 15.59 164.004 16.1016 165.189 17.1247C165.727 17.5555 166.158 18.1479 166.427 18.7941C166.723 19.7096 166.858 20.652 166.804 21.6213C166.804 22.8599 166.67 23.8023 166.427 24.4216C166.158 25.0947 165.727 25.6871 165.162 26.1448C164.058 27.141 162.469 27.6526 160.557 27.6526ZM160.557 17.3671C158.457 17.3671 157.407 18.7941 157.407 21.6482C157.407 24.5023 158.457 25.9563 160.557 25.9563C162.658 25.9563 163.708 24.5293 163.708 21.6751C163.708 18.821 162.631 17.3671 160.557 17.3671Z"
          fill="#00B897"
        />
        <path
          d="M173.778 27.6526C172.189 27.6795 170.627 27.2218 169.281 26.3333C169.066 26.1448 168.823 25.9832 168.581 25.8486C168.5 25.7947 168.446 25.714 168.446 25.6332V10.9049H170.331C171.004 10.9049 171.57 11.4703 171.57 12.1435V16.4516L172.135 16.1285C172.808 15.7784 173.697 15.6169 174.855 15.6169C176.874 15.6169 178.409 16.2362 179.405 17.394C180.294 18.4441 180.751 19.8442 180.751 21.5674C180.751 23.2907 180.24 24.6908 179.217 25.714C178.059 27.0333 176.201 27.6526 173.778 27.6526ZM174.074 17.367C173.374 17.367 172.566 17.6363 171.677 18.1748L171.543 18.2287V25.1485L171.677 25.2562C172.404 25.714 173.212 25.9294 174.074 25.9294C175.151 25.9563 176.174 25.4178 176.712 24.5023C177.251 23.6407 177.547 22.6445 177.52 21.6213C177.52 18.7941 176.362 17.367 174.074 17.367Z"
          fill="#00B897"
        />
        <path
          d="M188.129 27.6257C184.629 27.6257 182.421 25.337 182.421 21.5944C182.421 17.8517 184.575 15.5899 188.075 15.5899C189.287 15.563 190.499 15.9131 191.495 16.6131L191.683 16.7477V15.8323H194.134C194.268 15.8323 194.376 15.94 194.376 16.0746V27.4103H191.629V26.3871L191.441 26.5487C190.687 27.2487 189.475 27.6257 188.129 27.6257ZM188.748 17.6363C186.541 17.6363 185.248 19.3326 185.248 21.5944C185.248 23.8561 186.567 25.6063 188.748 25.6063C189.448 25.6063 190.66 25.5524 191.629 24.5831L191.656 24.5562V18.7402L191.629 18.7133C190.983 17.9594 189.987 17.6363 188.748 17.6363Z"
          fill="#00B897"
        />
        <path
          d="M74.7998 27.518V10.9049H88.6126V11.955C88.6126 12.7897 87.9395 13.4628 87.1048 13.4628H78.2194V17.9056H87.1048V20.4635H78.2194V24.9331H87.1048C87.9395 24.9331 88.6126 25.6063 88.6126 26.441V27.4911L74.7998 27.518Z"
          fill="black"
        />
        <path
          d="M108.429 10.8781H105.171C104.578 10.8781 104.013 11.1473 103.636 11.605L99.7317 16.5593L95.8006 11.632C95.4236 11.1742 94.8582 10.905 94.2658 10.905H91.0078L97.6046 19.1981L91.0078 27.4911H94.2658C94.8582 27.4911 95.4236 27.2219 95.8006 26.7641L99.7317 21.8637L103.69 26.818C104.067 27.2757 104.632 27.545 105.225 27.545H108.483L101.859 19.2519L108.429 10.8781Z"
          fill="black"
        />
        <path
          d="M63.9757 25.7407L68.4992 17.8246V27.4639H71.9457V10.8508H69.8186C69.1454 10.8508 68.5261 11.2009 68.203 11.7932L62.791 21.6749L57.379 11.7932C57.0558 11.2009 56.4366 10.8239 55.7634 10.8508H53.6094V27.4639H57.0558V17.8246L61.5793 25.7407H63.9757Z"
          fill="black"
        />
        <path
          d="M117.423 27.7064C112.711 27.8141 108.806 24.0715 108.726 19.3595C108.726 19.3056 108.726 19.2787 108.726 19.2249C108.726 16.6131 109.533 14.4321 111.014 12.9512C112.495 11.4703 114.676 10.6895 117.261 10.6895C120.304 10.6895 122.673 11.7396 124.396 13.7859L123.319 14.7014C122.646 15.2668 121.65 15.2937 120.923 14.7552C119.9 14.0013 118.661 13.6243 117.396 13.6782C114.003 13.6782 112.011 15.7245 112.011 19.171C112.011 22.6175 114.084 24.7984 117.396 24.7984C118.661 24.8254 119.9 24.4484 120.923 23.7214C121.65 23.1829 122.619 23.2098 123.319 23.7753L124.396 24.6907C122.7 26.6832 120.384 27.7064 117.423 27.7064Z"
          fill="black"
        />
        <path
          d="M138.64 27.6793C133.282 27.6793 129.97 24.4213 129.97 19.1977C129.97 13.9742 133.282 10.7162 138.586 10.7162C141.144 10.7162 143.271 11.4432 144.94 12.951L144.133 13.8396C143.567 14.4588 142.679 14.6204 141.925 14.2434C140.875 13.7319 139.744 13.4626 138.586 13.4626C135.139 13.4626 133.012 15.6705 133.012 19.1977C133.012 22.725 135.247 24.9598 138.828 24.9598C140.551 24.9598 141.898 24.6367 142.867 23.9366L142.921 23.9097V20.4902H139.124V19.3862C139.124 18.5515 139.798 17.8784 140.632 17.8784H145.317C145.452 17.8784 145.587 17.9861 145.587 18.1476V25.5252C143.944 26.9523 141.602 27.6793 138.64 27.6793Z"
          fill="#00B897"
        />
        <path
          d="M151.725 27.6526C150.729 27.6795 149.76 27.4372 148.925 26.9525C148.198 26.4948 147.767 25.687 147.794 24.8254V10.9049H149.652C150.352 10.9049 150.891 11.4703 150.891 12.1435V24.3946C150.918 24.7985 151.079 25.1755 151.375 25.4716C151.671 25.7678 152.075 25.9294 152.479 25.9294H152.829C152.964 25.9294 153.098 26.0371 153.098 26.1986V27.7065L151.725 27.6526Z"
          fill="#00B897"
        />
      </mask>
      <g mask="url(#mask0_1701_1448)">
        <rect
          x="2.91992"
          y="-18.4901"
          width="202.419"
          height="72.0144"
          fill={isActive ? '#ffffff' : '#878787'}
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1701_1448"
          x1="16.5776"
          y1="17.5788"
          x2="36.7368"
          y2="25.0264"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#53E57A" stopOpacity="0" />
          <stop offset="1" stopColor="#00A977" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Mexc;
