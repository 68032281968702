import * as React from 'react';
import styled from 'styled-components';
import { MEDIA_QUERY } from 'properties/constant';

interface Props {
  desktop: number;
  tablet?: number;
  mobile?: number;
}

const Space: React.FC<Props> = ({ desktop, tablet, mobile }) => {
  return <Base desktop={desktop} tablet={tablet} mobile={mobile} />;
};

export default Space;

const Base = styled.div<Props>`
  height: ${({ desktop }) => desktop + 'px'};

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    height: ${({ tablet }) => tablet && tablet + 'px'};
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    height: ${({ mobile }) => mobile && mobile + 'px'};
  }
`;
