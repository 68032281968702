import * as React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  isActive?: boolean;
}

const Telegram: React.FC<Props> = ({ isActive = false, ...props }) => {
  const name = 'icon-telegram';
  const gradientId = `${name}-gradient`;
  const gradientId2 = `${name}-gradient-2`;

  const circleFill = isActive ? '#fff' : `url(#${gradientId})`;
  const circleStroke = isActive ? `url(#${gradientId})` : '#fff';
  const pathFill = isActive ? `url(#${gradientId2})` : '#fff';

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={25.001}
        cy={24.51}
        r={24.001}
        fill={circleFill}
        stroke={circleStroke}
        strokeWidth={0.7}
      />
      <path
        d="M27.151 21.671c.632-.656 1.264-2.195-1.379-.334a401.59 401.59 0 0 1-7.456 5.212 3.036 3.036 0 0 1-2.435.06c-1.586-.49-3.435-1.157-3.435-1.157s-1.264-.823.896-1.706c0 0 9.145-3.9 12.315-5.271 1.218-.55 5.343-2.302 5.343-2.302s1.907-.764 1.746 1.097c-.058.763-.471 3.459-.896 6.369-.632 4.115-1.321 8.623-1.321 8.623s-.104 1.264-1 1.48a4.228 4.228 0 0 1-2.642-.99c-.207-.168-3.964-2.637-5.343-3.841a1.061 1.061 0 0 1-.264-1.444.903.903 0 0 1 .322-.31 204.304 204.304 0 0 0 5.549-5.486Z"
        fill={pathFill}
      />
      <defs>
        <linearGradient
          id={gradientId}
          x1={8.803}
          y1={-12.414}
          x2={73.456}
          y2={13.616}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4AE6E6" />
          <stop offset={1} stopColor="#FC72FF" />
        </linearGradient>
        <linearGradient
          id={gradientId2}
          x1={15.439}
          y1={11.246}
          x2={41.954}
          y2={23.7}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4AE6E6" />
          <stop offset={1} stopColor="#FC72FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Telegram;
