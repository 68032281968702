import * as React from 'react';
import styled from 'styled-components';
import { image } from 'assets';
import { MEDIA_QUERY } from 'properties/constant';

const Background: React.FC = () => {
  return (
    <Container>
      <Object1 src={image.object.Obj12} alt={'bg-obj'} />
      <Object2 src={image.object.Obj0} alt={'bg-obj'} />
      <Object4 src={image.object.Obj0} alt={'bg-obj'} />
      <Object5 src={image.object.Obj31} alt={'bg-obj'} />
    </Container>
  );
};

export default Background;

const Container = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
  * {
    pointer-events: none;
    user-select: none;
  }
`;

const Object1 = styled.img`
  position: absolute;
  width: 256px;
  height: 256px;
  left: -7px;
  top: -59.74px;
  filter: blur(5px);
  transform: rotate(-15deg);
  opacity: 0.3;
  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    width: 113.49px;
    height: 113.49px;
    left: -45.25px;
    top: 85.75px;
    filter: blur(1.5px);
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 103.7px;
    height: 103.7px;
    left: -62.35px;
    top: 84.65px;
    filter: blur(1.5px);
  }
`;

const Object2 = styled.img`
  position: absolute;
  width: 27px;
  height: 27px;
  left: 294px;
  top: 109px;
  opacity: 0.5;
  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    width: 12px;
    height: 12px;
    left: 62px;
    top: 193px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 11px;
    height: 11px;
    left: 36px;
    top: 139px;
  }
`;

const Object4 = styled.img`
  position: absolute;
  width: 32px;
  height: 32px;
  right: 300px;
  top: 291px;
  filter: blur(2px);
  opacity: 0.5;
  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    width: 15.35px;
    height: 15.35px;
    right: 143.1px;
    top: 142.05px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 17.97px;
    height: 17.97px;
    right: 138.68px;
    top: 765.9px;
  }
`;

const Object5 = styled.img`
  position: absolute;
  width: 304.33px;
  height: 472.37px;
  right: -61px;
  top: 122px;
  filter: blur(4px);
  transform: rotate(35.95deg);
  opacity: 0.2;
  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    width: 145.94px;
    height: 226.53px;
    right: -29.54px;
    top: 82.27px;
    filter: blur(2.5px);
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 170.89px;
    height: 265.26px;
    right: -63.48px;
    top: 695.9px;
    filter: blur(2.5px);
  }
`;
