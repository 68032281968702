import * as React from 'react';
// import { Link } from "react-router-dom";
import styled from 'styled-components';
import Slider from 'react-slick';
// TODO: 최종적으로 custom style 적용
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useResponsive } from 'hooks/useResponsive';
import { useCommunityNewsBannerQuery } from 'apis/hooks';
import { Icon, Section } from 'components';
import { MEDIA_QUERY } from 'properties/constant';
import type { Settings as SliderOptions } from 'react-slick';

const TopBanner: React.FC = () => {
  const { data: topBanner } = useCommunityNewsBannerQuery();

  const { isMobile } = useResponsive();

  const sliderRef = React.useRef<Slider | null>(null);

  const handleClickPrev = () => {
    if (sliderRef.current === null) return;
    sliderRef.current.slickPrev();
  };

  const handleClickNext = () => {
    if (sliderRef.current === null) return;
    sliderRef.current.slickNext();
  };

  const options: SliderOptions = {
    dots: isMobile,
    infinite: false,
    speed: 500,
    prevArrow: <Icon.SliderPrev />,
    nextArrow: <Icon.SliderNext />,
    appendDots: (dots) => (
      <DotsArea>
        <Icon.SliderPrev className="custom-arrow" onClick={handleClickPrev} />
        <ul>{dots}</ul>
        <Icon.SliderNext className="custom-arrow" onClick={handleClickNext} />
      </DotsArea>
    ),
    customPaging: (_i) => <div className="custom-dot" />,
  };

  if (topBanner === undefined) return null;

  return (
    <Container>
      <Carousel {...options} ref={sliderRef}>
        {topBanner.map((el) => {
          const { key, image, link, title = '', text = '' } = el.fields;
          if (key === undefined) return null;
          if (image === undefined) return null;
          return (
            <div key={el.id}>
              <Slide>
                <SlideImage
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={image[0].url} alt={image[0].filename} />
                </SlideImage>
                <SlideContent>
                  <div className="title">{title}</div>
                  <div className="text">{text}</div>
                </SlideContent>
              </Slide>
            </div>
          );
        })}
      </Carousel>
    </Container>
  );
};

export default TopBanner;

const Container = styled(Section)`
  margin-bottom: 106px;
  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    margin-bottom: 148px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    margin-bottom: 200px;
  }
`;

const Carousel = styled(Slider)`
  padding: 0 44px;

  .slick-arrow {
    width: 15px;
    height: 30px;
    box-sizing: content-box;
    padding: 10px;
    z-index: 1;
  }
  .slick-prev {
    left: 0;
    right: auto;
  }
  .slick-next {
    left: auto;
    right: 0;
  }
  .slick-disabled {
    cursor: default;
    path {
      stroke: #9e9e9e;
      stroke-width: 0.7;
    }
  }

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    .slick-arrow {
      top: calc(33vw - 30px);
    }
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    padding: 0;
    .slick-arrow {
      display: none !important;
    }
  }
`;

const Slide = styled.div`
  height: 368px;
  display: flex;
  align-items: center;
  column-gap: 32px;
  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    height: fit-content;
    flex-direction: column;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    height: fit-content;
    flex-direction: column;
  }
`;

const SlideImage = styled.a`
  flex: 550 0 0;
  display: flex;
  img {
    object-fit: contain;
  }

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    flex: 1 0 auto;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    flex: 1 0 auto;
  }
`;

const SlideContent = styled.div`
  flex: 467 0 0;
  height: 270px;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  row-gap: 24px;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    flex: 1 0 auto;
    height: 232px;
    margin-top: 14px;
    justify-content: flex-start;
    row-gap: 24px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    flex: 1 0 auto;
    height: 172px;
    margin-top: 24px;
    justify-content: flex-start;
    row-gap: 20px;
  }

  .title {
    max-height: 100px;
    font-family: ofelia-text, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 50px;
    letter-spacing: -0.06em;
    color: #000000;

    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
      max-height: 100px;
    }
    @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
      max-height: 62px;
      font-size: 21px;
      line-height: 31px;
      letter-spacing: -0.05em;
    }
  }

  .text {
    height: 144px;

    font-family: basic-sans, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 21px;
    line-height: 36px;
    letter-spacing: -0.06em;
    color: #000000;

    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;

    @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
      height: 108px;
      -webkit-line-clamp: 3;
    }
    @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
      height: 90px;
      font-size: 17.5px;
      line-height: 30px;
      -webkit-line-clamp: 3;
    }
  }
`;

// custom dot & arrow style for mobile
const DotsArea = styled.div`
  position: static;
  width: 100%;
  padding: 0;
  margin: 0;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 46px;
  list-style: none;

  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
    li {
      width: 9px;
      height: 9px;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      .custom-dot {
        width: 9px;
        height: 9px;
        background: #d9d9d9;
        border-radius: 50%;
      }

      &.slick-active .custom-dot {
        background: linear-gradient(111.93deg, #4ae6e6 3.86%, #fc72ff 115.46%);
      }
    }
  }

  .custom-arrow {
    display: flex;
    width: 6px;
    height: 12px;
    box-sizing: content-box;
    padding: 4px;
    z-index: 1;
    cursor: pointer;
  }
`;
