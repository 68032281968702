import * as React from 'react';
import styled from 'styled-components';
import { Section } from 'components';
import { CONTENT_ABOUT } from 'properties/contents';
import { MEDIA_QUERY } from 'properties/constant';
import { useResponsive } from 'hooks/useResponsive';

const Service: React.FC = () => {
  const { isTablet, isMobile } = useResponsive();
  const { services } = CONTENT_ABOUT;

  return (
    <Container>
      <CardList>
        {services.map((service) => {
          const { id, title, subTitle, description, image, imageSize } =
            service;

          const { width, height } = isMobile
            ? imageSize.mobile
            : isTablet
            ? imageSize.tablet
            : imageSize.desktop;

          return (
            <ServiceCard key={id}>
              <TextContainer>
                <Title>{title}</Title>
                <SubTitle>{subTitle}</SubTitle>
                <Description>{description}</Description>
              </TextContainer>
              <Image width={width} height={height} src={image} alt={title} />
            </ServiceCard>
          );
        })}
      </CardList>
    </Container>
  );
};

export default Service;

const Container = styled(Section)``;

const CardList = styled.div`
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  max-width: 945px;
  margin: 0 auto;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    grid-gap: 16px;
    max-width: 744px;
  }

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    grid-template-columns: 1fr;
    grid-gap: 50px;
  }
`;

const ServiceCard = styled.div`
  background: linear-gradient(
    174.83deg,
    rgba(151, 242, 239, 0.3) 6.7%,
    rgba(252, 114, 255, 0.3) 146.55%
  );
  backdrop-filter: blur(2.5px);
  border-radius: 20px;
  height: 487px;
  box-sizing: border-box;
  padding: 40px 32px;
  text-align: left;
  position: relative;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    height: 400px;
    padding: 30px 24px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    min-height: 400px;
    height: fit-content;
    padding: 30px 24px;
  }
`;

const TextContainer = styled.div`
  position: relative;
  z-index: 10;
`;
const Title = styled.div`
  font-family: ofelia-text, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.05em;
  color: #000000;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    font-size: 22.5652px;
  }
`;

const SubTitle = styled.div`
  font-family: ofelia-text, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 36px;
  letter-spacing: -0.05em;
  color: #606060;

  padding-bottom: 24px;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    font-size: 16.5478px;
    line-height: 27px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    padding-bottom: 16px;
  }
`;
const Description = styled.div`
  font-family: basic-sans, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 34px;
  letter-spacing: -0.05em;

  color: #000000;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    font-size: 17.5px;
    line-height: 30px;
    letter-spacing: -0.06em;
  }
`;
const Image = styled.img<{ width: string; height: string }>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  position: absolute;
  right: 0px;
  bottom: 0px;
`;
