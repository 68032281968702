import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { SITE_MAP } from 'properties/sitemap';

const Navigation: React.FC = () => {
  const location = useLocation();
  const currentPath = location.pathname.split('/');

  const navigate = useNavigate();

  const handleLink = (link?: string) => {
    if (link === undefined) return;
    // TODO: useNavigate와 location.href 통합
    if (link[0] === '/') {
      navigate(link);
    } else {
      window.open(link, '_blank', 'noopener noreferrer');
    }
  };

  return (
    <Container>
      {SITE_MAP.map((el) => (
        <Menu key={el.id} active={currentPath[1] === el.id}>
          <span>{el.label}</span>
          <span className="fake">{el.label}</span>
          {el.sub && (
            <Dropdown>
              {el.sub.map((subEl) => {
                return (
                  <DropdownItem
                    key={subEl.id}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleLink(subEl.link);
                    }}
                    isLink={!!subEl.link}
                    active={
                      currentPath[1] === el.id && currentPath[2] === subEl.id
                    }
                  >
                    {typeof subEl.label === 'string' ? (
                      <span>{subEl.label}</span>
                    ) : (
                      subEl.label.map((labelEl, _i) => (
                        <span key={_i}>{labelEl}</span>
                      ))
                    )}
                  </DropdownItem>
                );
              })}
            </Dropdown>
          )}
        </Menu>
      ))}
    </Container>
  );
};

export default Navigation;

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  column-gap: 52px;
  user-select: none;
`;

const Menu = styled.div<{ active: boolean }>`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  user-select: none;

  & > span {
    position: absolute;
    font-family: ofelia-text, sans-serif;
    font-style: normal;
    font-weight: ${({ active }) => (active ? 600 : 400)};
    font-size: 16px;
    line-height: 23px;
    color: #000000;
    cursor: pointer;

    :hover,
    :active {
      font-weight: 600;
    }
  }

  .fake {
    position: relative;
    left: 0;
    visibility: hidden;
    font-weight: bold;
  }

  :hover,
  :active {
    & > div {
      display: flex;
    }
  }
`;

const Dropdown = styled.div`
  position: absolute;
  z-index: 1;
  top: 58px;
  left: -28px;
  width: fit-content;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 24px;
  padding: 28px 32px;
  row-gap: 20px;
  background: linear-gradient(111.93deg, #c2ffff 3.86%, #fec4ff 115.46%);
  border-radius: 13px;
`;

const DropdownItem = styled.div<{ isLink: boolean; active: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  cursor: pointer;

  & > span {
    font-family: basic-sans, sans-serif;
    font-style: normal;
    font-weight: ${({ active }) => (active ? 600 : 400)};
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.05em;
    color: #000000;
    white-space: nowrap;
    cursor: ${({ isLink }) => (isLink ? 'pointer' : 'default')};
  }

  :hover,
  :active {
    & > span {
      ${({ isLink }) => isLink && `font-weight: 600`};
    }
  }
`;
