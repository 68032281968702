import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Icon } from 'components';
import {
  APP_MAX_WIDTH,
  BASE_X_PADDING,
  MEDIA_QUERY,
} from 'properties/constant';
import SiteMapList from './SiteMapList';
import SNSList from './SNSList';

const Footer: React.FC = () => {
  const navigate = useNavigate();

  const handleLink = (link?: string) => {
    if (link === undefined) return;
    // TODO: useNavigate와 location.href 통합
    if (link[0] === '/') {
      navigate(link);
    } else {
      window.open(link, '_blank', 'noopener noreferrer');
    }
  };

  return (
    <Container>
      <Inner>
        <SitemapBase>
          <Logo to={'/'}>
            <Icon.LogoProtocon />
          </Logo>
          <SiteMapList onClick={handleLink} />
        </SitemapBase>
        <SNSList onClick={handleLink} />
        <Copyright>{`© Digital Transformation Network Ltd. All Rights Reserved.`}</Copyright>
      </Inner>
    </Container>
  );
};

export default Footer;

const Container = styled.footer`
  width: 100%;
  padding: 84px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    174.83deg,
    rgba(151, 242, 239, 0.5) 6.7%,
    rgba(252, 114, 255, 0.5) 146.55%
  );
  backdrop-filter: blur(10px);

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    padding: 84px 0;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    padding: 50px 0;
  }
`;

const Inner = styled.div`
  width: 100%;
  max-width: ${APP_MAX_WIDTH}px;
  height: 100%;
  padding: 0 ${BASE_X_PADDING}px;
  display: flex;
  flex-direction: column;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    padding: 0 72px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    padding: 0 42px;
  }
  @media (max-width: 389px) {
    padding: 0 16px;
  }
`;

const SitemapBase = styled.div`
  width: 100%;
  margin-bottom: 26px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    margin-bottom: 165px;
    flex-direction: column;
    row-gap: 55px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    margin-bottom: 108px;
    flex-direction: column;
    row-gap: 50px;
  }
`;

const Logo = styled(Link)`
  flex: 0 0 auto;
  width: 173px;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Copyright = styled.div`
  font-family: basic-sans, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 31px;
  letter-spacing: -0.05em;
  color: #000000;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    font-size: 16px;
    line-height: 31px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 14px;
    line-height: 22px;
  }
`;
