import * as React from 'react';
import styled from 'styled-components';
import CountUp from 'react-countup';
import { useAboutPenDataQuery } from 'apis/hooks';
import { Section } from 'components';
import { GradientText } from 'styles/Common';
import { image } from 'assets';
import { MEDIA_QUERY } from 'properties/constant';

interface GraphData {
  price: number;
  marketCap: number;
  tradingVolume24h: number;
  numberOfWallets: number;
}

const PriceGraph: React.FC = () => {
  const { data: panData } = useAboutPenDataQuery();

  const [graphData, setGraphData] = React.useState<GraphData | undefined>(
    undefined
  );

  React.useEffect(() => {
    const coinmarketcap = panData?.find(
      (el) => el.fields.key === 'coinmarketcap'
    );
    if (coinmarketcap === undefined) return;
    const data = {
      price: coinmarketcap.fields.price ?? 0,
      marketCap: coinmarketcap.fields.marketCap ?? 0,
      tradingVolume24h: coinmarketcap.fields.tradingVolume24h ?? 0,
      numberOfWallets: coinmarketcap.fields.numberOfWallets ?? 0,
    };
    setGraphData(data);
  }, [panData]);

  return (
    <Section>
      <Container>
        <Image src={image.group.Group84} alt="object" />
        {graphData && (
          <List>
            <div>
              <Name>PEN Price</Name>
              <Price>
                <CountUp
                  end={graphData.price}
                  start={0}
                  duration={2}
                  prefix={'$'}
                  decimals={3}
                  decimal={'.'}
                  enableScrollSpy
                  scrollSpyOnce
                />
              </Price>
            </div>
            <div>
              <Name>Market Cap</Name>
              <Price>
                <CountUp
                  end={graphData.marketCap}
                  start={0}
                  duration={2}
                  prefix={'$'}
                  separator={','}
                  enableScrollSpy
                  scrollSpyOnce
                />
              </Price>
            </div>
            <div>
              <Name>24h Trading Volume</Name>
              <Price>
                <CountUp
                  end={graphData.tradingVolume24h}
                  start={0}
                  duration={2}
                  prefix={'$'}
                  separator={','}
                  enableScrollSpy
                  scrollSpyOnce
                />
              </Price>
            </div>
            <div>
              <Name>Number of Wallets</Name>
              <Price>
                <CountUp
                  end={graphData.numberOfWallets}
                  start={0}
                  duration={2}
                  separator={','}
                  enableScrollSpy
                  scrollSpyOnce
                />
              </Price>
            </div>
          </List>
        )}
      </Container>
    </Section>
  );
};

export default PriceGraph;

const Container = styled.div`
  max-width: 1024px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto 210px;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    position: relative;
    padding: 0 70px;
    max-width: 744px;
    margin-bottom: 517px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    padding: 0px;
    margin-bottom: 664px;
  }
`;

const Image = styled.img`
  width: 855px;
  height: 680px;
  margin-left: -330px;
  margin-top: 150px;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    position: absolute;
    top: 630px;
    right: 0px;
    width: 542px;
    height: 476px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 16px;
    min-width: 347px;
    width: 347px;
    height: 292px;
    margin-top: 100px;
    margin-right: -40px;
  }
`;

const List = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 98px;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    gap: 58px;
  }
`;

const Name = styled.div`
  font-family: ofelia-text, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.05em;
  color: #000000;
  padding-bottom: 8px;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 16px;
  }
`;

const Price = styled(GradientText)`
  font-family: ofelia-text, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  line-height: 96px;
  :after {
    content: '';
  }

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 60px;
    line-height: 96px;
  }
  @media (max-width: 300px) {
    font-size: 46px;
    height: auto;
  }
`;
