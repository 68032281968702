import * as React from 'react';
import styled from 'styled-components';
import { image } from 'assets';
import { MEDIA_QUERY } from 'properties/constant';

const Background: React.FC = () => {
  return (
    <Container>
      <Object1 src={image.object.Obj6} alt={'bg-obj'} />
    </Container>
  );
};

export default Background;

const Container = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
  * {
    pointer-events: none;
    user-select: none;
  }
`;

const Object1 = styled.img`
  position: absolute;
  width: 516px;
  height: 324px;
  left: -126px;
  top: 2122px;
  opacity: 0.3;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    width: 456.67px;
    height: 286.75px;
    left: -189px;
    top: 2432px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 320px;
    height: 201px;
    left: -149px;
    top: 2475px;
  }
`;
