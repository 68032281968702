import { image } from 'assets';

export const HOME_WELCOME_TO_PROTOCON = {
  title: {
    desktop: `Building the Protocol Economy
through Web3`,
    tablet: `Building the 
Protocol Economy 
through Web3`,
    mobile: `Building the
    Protocol Economy
    through Web3`,
  },
  subTitle: 'Welcome to Protocon',
  text: {
    desktop: `Protocon is an interconnected ecosystem to provide fair distribution
    and transparency to all.`,
    tablet: `Protocon is an interconnected ecosystem 
to providefair distribution and transparency to all.`,
    mobile: `Protocon is an interconnected 
ecosystem to provide fair distribution 
and transparency to all.`,
  },
  buttonLabel: 'Documentation',
};
export const CONTENT_ABOUT = {
  title: 'What is PEN?',
  subTitle: 'About PEN Token',
  description: {
    desktop: `PEN is abbreviated for “Protocol Economy Network” which is Protocon’s mainnet token. 
  PEN will also act as a currency within the Protocon Network.
  
  PEN is versatile with many mechanisms and features that include staking, ecsosytem
  creation, participation rewards, governance, and more…`,
    tablet: `PEN is abbreviated for “Protocol Economy Network” 
  which is Protocon’s mainnet token.
  PEN will also act as a currency within the Protocon Network.
  
  PEN is versatile with many mechanisms and features 
  that include staking, ecosystem creation, 
  participation rewards, governance, and more…`,
    mobile: `PEN is abbreviated for “Protocol Economy Network” which is Protocon’s mainnet token.
  PEN will also act as a currency within the 
  Protocon Network.
  
  PEN is versatile with many mechanisms and
  features that include staking, ecosystem creation, participation rewards, governance, and more…`,
  },

  services: [
    {
      id: '1',
      title: 'Payment',
      subTitle: 'Transaction Fees',
      description:
        'PEN originally functions as “gas” within the Protocon ecosystem. PEN can be used as a means of payment for mainnet transaction fees on dApp services or when transferring dApp tokens.',
      image: image.group.Group49,
      imageSize: {
        desktop: { width: '200px', height: '200px' },
        tablet: { width: '139px', height: '139px' },
        mobile: { width: '145px', height: '145px' },
      },
    },
    {
      id: '2',
      title: 'Payment',
      subTitle: 'Purchasing dApp Tokens',
      description:
        'PEN not only functions as “gas” but the token also functions as a payment method for purchasing dApp tokens for dApps based on Protocon’s blockchain.',
      image: image.group.Group79,
      imageSize: {
        desktop: { width: '213px', height: '213px' },
        tablet: { width: '160px', height: '160px' },
        mobile: { width: '150px', height: '150px' },
      },
    },
    {
      id: '3',
      title: 'Staking',
      subTitle: null,
      description:
        'PEN Token holders can provide liquidity to PepperFi (Protocon’s DeFI) or operate nodes by staking PEN and receiving awards accordingly.',
      image: image.group.Group54,

      imageSize: {
        desktop: { width: '310px', height: '210px' },
        tablet: { width: '250px', height: '170px' },
        mobile: { width: '195px', height: '132px' },
      },
    },
    {
      id: '4',
      title: 'Governance',
      subTitle: null,
      description:
        'Anyone who has staked PEN will be able to propose and vote on all discussions regarding the improvement or “changes” of Protocon.',
      image: image.group.Group52,
      imageSize: {
        desktop: { width: '269px', height: '212px' },
        tablet: { width: '220px', height: '174px' },
        mobile: { width: '200px', height: '158px' },
      },
    },
  ],
  step1: {
    title: 'Create Protocon Wallet',
    description: `To hold your PEN, you’ll need a Protocon Wallet.
      Protocon Wallet is the first gateway to access the Protocon Ecosystem. As Protocon Wallet is a dApp Portal to connect to blockchain-based applications, Protocon Wallet provides the safest and simplest way to interact with the protocon Ecosystem.`,
  },

  step2: {
    title: 'Buy PEN',
    description: `PEN is available on multiple cryptocurrency exchanges.
      Please refer to the exchanges and links that are listed below.`,
  },

  statics: [
    { name: 'PEN Price', amount: '$0.043' },
    { name: 'Market Cap', amount: '$0.043' },
    { name: '24h Trading Vol', amount: '$1,432,702' },
    { name: 'Number of Wallets', amount: '2,340' },
  ],
};

export const CONTENT_CREATE_PROTOCON_WELLET = {
  title: 'Create\nProtocon Wallet',
  subTitle: 'STEP 1',
  text: {
    desktop: `To hold your PEN, you’ll need a Protocon Wallet.
  Protocon Wallet is the first gateway to access the Protocon Ecosystem. As Protocon Wallet is a dApp Portal to connect to blockchain-based applications, Protocon Wallet provides the safest and simplest way to interact with the protocon Ecosystem.`,
    tablet: `To hold your PEN, you’ll need a Protocon Wallet. Protocon Wallet is the first gateway to access the Protocon Ecosystem. 

    As Protocon Wallet is a dApp Portal to connect to blockchain-based applications, Protocon Wallet provides the safest and simplest way to interact with the protocon Ecosystem.`,
  },
  buttonLabel: 'Explore more',
  image: image.object.ObjMobileMockup,
  imageAlt: 'wallet',
};

export const CONTENT_BUY_PEN = {
  subTitle: 'STEP 2',
  title: 'Buy PEN',
  text: `PEN is available on multiple cryptocurrency exchanges.  
  Please refer to the exchanges and links that are listed below.`,
};

export const HOME_WHAT_IS_PROTOCON = {
  title: 'What is\nProtocon?',
  text: {
    desktop:
      'Protocon aims to solve personal information and data sensitivity issues\nthrough decentralization and de-monopolization \nby building the Protocon Economy in which fair distribution occurs by the system. \n\nProtocon will build a community based on values that people pursue \nwhile building a Protocol Economy based on Web3.',
    mobile:
      'Protocon aims to solve personal information\nand data sensitivity issues\nthrough decentralization and de-monopolization \nby building the Protocon Economy\nin which fair distribution occurs by the system. \n\nProtocon will build a community based on values \nthat people pursue while building a Protocol\nEconomy based on Web3.',
  },
};
export const HOME_FEEFI = {
  title: 'Fee Fi',
  text: {
    desktop: `FeeFi is a protocol built with the purpose of convenience. 
    FeeFi will remove the hassles of paying fees via only native tokens, 
    accepting and allowing the payment of fees via dApp tokens. 
    
    Network participants can share network benefits transparently and fairly to
    establish a true decentralized Web3 ecosystem.`,
    tablet: `FeeFi is a protocol built with the purpose of convenience. 
    FeeFi will remove the hassles of paying fees via only native tokens, 
    accepting and allowing the payment of fees via dApp tokens. 
    
    Network participants can share network benefits 
    transparently and fairly to establish 
    a true decentralized Web3 ecosystem.`,
    mobile: `FeeFi is a protocol built with the purpose of 
    convenience.  FeeFi will remove the hassles of 
    paying fees via only native tokens, 
    accepting and allowing the payment of fees 
    via dApp tokens. 
    
    Network participants can share network benefits
    transparently and fairly to establish a true
    decentralized Web3 ecosystem.`,
  },
  card: {
    title: 'Stake Tokens.\nProvide Liquidity.\nEarn Rewards.',
    subTitle: 'FeeFi Liquidity Pools',
    buttonLabel: 'comming soon',
  },
};

export const HOME_MITUM = {
  title: 'Protocon will build a decentralized\nProtocol Economy via',
  text: {
    desktop: `For a secure Protocol Economy, there mustn't be any violations or
  vulnerabilities. We are building a Public Mainnet with Mitum as the
  core, and the two pillars of Mitum are ISAAC++ and our Contract Model.`,
    tablet: `For a secure Protocol Economy, there mustn't be any violations or
vulnerabilities. We are building a Public Mainnet with Mitum as the
core, and the two pillars of Mitum are ISAAC++ and our Contract Model.`,
    mobile: `For a secure Protocol Economy, 
    there mustn't be any violations or vulnerabilities.
    We are building a Public Mainnet with Mitum 
    as the core, and the two pillars of Mitum are
    ISAAC++ and our Contract Model.`,
  },
};

export const HOME_PROTOCON_ECONOMY = {
  title: 'Protocol Economy',
  subTitle: 'The value of transparent and fair distribution',
  text: {
    desktop: `A Protocol Economy is an economic system in which individuals or groups partake in
    economic activities while receiving rewards based on their contributions. 
    This system is an alternative and a solution for
    proprietary business environments and problems. 
    
    Web3 is one of the solutions as it detaches itself from monopolies and centralization
    in addition to being user-centric, built, operated, and owned.
    We minimize unnecessary brokerage costs as we distribute fairly
    according to the rules upon which all stakeholders and participants have agreed.`,
    tablet: `A Protocol Economy is an economic system in which individuals or groups partake in
    economic activities while receiving rewards based on their contributions. 
    This system is an alternative and a solution for
    proprietary business environments and problems. 
    
    Web3 is one of the solutions as it detaches itself from monopolies and centralization
    in addition to being user-centric, built, operated, and owned.
    We minimize unnecessary brokerage costs as we distribute fairly
    according to the rules upon which all stakeholders and participants have agreed.`,
    mobile: `A Protocol Economy is an economic system in which individuals or groups partake in
    economic activities while receiving rewards based on their contributions. 
    This system is an alternative and a solution for
    proprietary business environments and problems. 
    
    Web3 is one of the solutions as it detaches itself from monopolies and centralization
    in addition to being user-centric, built, operated, and owned.
    We minimize unnecessary brokerage costs as we distribute fairly
    according to the rules upon which all stakeholders and participants have agreed.`,
  },
  pointText: 'Join Protocon and be a part of the\nmovement and values.',
};
