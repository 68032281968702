import * as React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  isActive?: boolean;
}

const Twitter: React.FC<Props> = ({ isActive = false, ...props }) => {
  const name = 'icon-twitter';
  const gradientId = `${name}-gradient`;
  const gradientId2 = `${name}-gradient-2`;

  const circleFill = isActive ? '#fff' : `url(#${gradientId})`;
  const circleStroke = isActive ? `url(#${gradientId})` : '#fff';
  const pathFill = isActive ? `url(#${gradientId2})` : '#fff';

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={25.001}
        cy={24.51}
        r={24.001}
        fill={circleFill}
        stroke={circleStroke}
        strokeWidth={0.7}
        strokeMiterlimit={10}
      />
      <path
        d="M21.534 34.481c7.803 0 12.07-6.818 12.07-12.73 0-.193-.003-.386-.011-.578a8.887 8.887 0 0 0 2.116-2.316 8.118 8.118 0 0 1-2.437.703 4.454 4.454 0 0 0 1.865-2.475 8.24 8.24 0 0 1-2.694 1.087c-.774-.87-1.877-1.414-3.096-1.414-2.343 0-4.243 2.004-4.243 4.474 0 .35.037.692.11 1.02-3.525-.187-6.652-1.967-8.744-4.675a4.656 4.656 0 0 0-.575 2.248c0 1.552.749 2.923 1.888 3.724a4.061 4.061 0 0 1-1.922-.56v.058c0 2.168 1.462 3.976 3.404 4.387a4.052 4.052 0 0 1-1.916.077c.54 1.777 2.106 3.071 3.963 3.107-1.453 1.2-3.281 1.916-5.269 1.916-.342 0-.68-.02-1.012-.062a11.56 11.56 0 0 0 6.503 2.01Z"
        fill={pathFill}
      />
      <defs>
        <linearGradient
          id={gradientId}
          x1={8.803}
          y1={-12.414}
          x2={73.456}
          y2={13.616}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4AE6E6" />
          <stop offset={1} stopColor="#FC72FF" />
        </linearGradient>
        <linearGradient
          id={gradientId2}
          x1={18.392}
          y1={11.986}
          x2={44.908}
          y2={24.44}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4AE6E6" />
          <stop offset={1} stopColor="#FC72FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Twitter;
