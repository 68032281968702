import * as React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  isActive?: boolean;
}

const Github: React.FC<Props> = ({ isActive = false, ...props }) => {
  const name = 'icon-github';
  const gradientId = `${name}-gradient`;
  const gradientId2 = `${name}-gradient-2`;

  const circleFill = isActive ? '#fff' : `url(#${gradientId})`;
  const circleStroke = isActive ? `url(#${gradientId})` : '#fff';
  const pathFill = isActive ? `url(#${gradientId2})` : '#fff';

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={25.001}
        cy={24.51}
        r={24.001}
        fill={circleFill}
        stroke={circleStroke}
        strokeWidth={0.7}
        strokeMiterlimit={10}
      />
      <path
        d="M21.071 33.553v1.763a1 1 0 0 0 .995 1l6.834.035a1 1 0 0 0 1.005-1V32.58a4.221 4.221 0 0 0-1.316-3.056c4.87-.832 6.907-3.716 6.907-7.148 0-1.665-.57-3.206-1.689-4.442.663-2.005-.09-3.704-.19-3.962-1.865-.165-3.798 1.185-4.189 1.474-1.15-.302-2.462-.467-3.92-.467-1.457 0-2.759.158-3.898.457-.163-.124-2.248-1.696-4.255-1.521-.104.258-.874 1.998-.17 4.03-1.104 1.231-1.667 2.759-1.667 4.421 0 3.425 2.024 6.306 6.88 7.152a4.208 4.208 0 0 0-1.2 2.041c-.9.218-2.68.44-3.59-.873-1.261-1.81-2.35-2.478-3.416-1.944-.45.504 1.053.772 1.688 1.827.315.503.505 3.518 5.191 2.985Z"
        fill={pathFill}
      />
      <defs>
        <linearGradient
          id={gradientId}
          x1={8.803}
          y1={-12.414}
          x2={73.456}
          y2={13.616}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4AE6E6" />
          <stop offset={1} stopColor="#FC72FF" />
        </linearGradient>
        <linearGradient
          id={gradientId2}
          x1={17.586}
          y1={7.855}
          x2={46.77}
          y2={19.045}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4AE6E6" />
          <stop offset={1} stopColor="#FC72FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Github;
