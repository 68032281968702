import * as React from 'react';
import styled from 'styled-components';
import { useCommunityProfileListQuery } from 'apis/hooks';
import { Section, Icon } from 'components';
import { useResponsive } from 'hooks/useResponsive';
import { MEDIA_QUERY } from 'properties/constant';

const ProfileList: React.FC = () => {
  const { data: profileList } = useCommunityProfileListQuery();

  const { isDesktop } = useResponsive();

  if (profileList === undefined) return null;

  return (
    <Container>
      <CardList>
        {profileList.map((el) => {
          const { key, image, name, role, linkedin } = el.fields;
          if (key === undefined) return null;
          if (image === undefined) return null;
          if (name === undefined) return null;

          return (
            <ProfileCard key={el.id}>
              <ProfileContainer>
                <ProfileImageBackground>
                  {isDesktop ? (
                    <Icon.GradientRing />
                  ) : (
                    <Icon.GradientRingMobile />
                  )}
                </ProfileImageBackground>
                <ProfileImage isActive={!!linkedin}>
                  <img src={image[0].url} alt={image[0].filename} />
                  <a href={linkedin} target="_blank" rel="noopener noreferrer">
                    <Icon.LinkedIn disable={!linkedin} />
                  </a>
                </ProfileImage>
              </ProfileContainer>
              <ProfileName>{name}</ProfileName>
              {role && <ProfileRole>{role}</ProfileRole>}
            </ProfileCard>
          );
        })}
      </CardList>
    </Container>
  );
};

export default ProfileList;

const Container = styled(Section)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CardList = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  column-gap: 10px;
  row-gap: 136px;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    max-width: 630px;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 70px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    max-width: 364px;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 15px;
  }
`;

const ProfileCard = styled.div`
  width: 274px;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    width: 176px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    max-width: 164px;
  }
  @media (max-width: 389px) {
    max-width: 114px;
  }
`;

const ProfileContainer = styled.div`
  position: relative;
  width: 274px;
  height: 197px;
  margin-bottom: 23px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    width: 100%;
    height: 140px;
    margin-bottom: 12px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 100%;
    height: 140px;
    margin-bottom: 12px;
  }
  @media (max-width: 389px) {
    width: 100%;
    height: 110px;
    margin-bottom: 10px;
  }
`;

const ProfileImageBackground = styled.div`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const ProfileImage = styled.div<{ isActive?: boolean }>`
  position: relative;
  z-index: 1;
  width: 184px;
  height: 184px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    width: 126px;
    height: 126px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 126px;
    height: 126px;
  }
  @media (max-width: 389px) {
    width: 96px;
    height: 96px;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  a {
    position: absolute;
    z-index: 3;
    right: 14px;
    bottom: 0;
    width: 32px;
    height: 32px;
    display: block;
    transition: all ease-out 0.3s;

    :hover {
      height: ${({ isActive }) => isActive && '40px'};
      padding-bottom: ${({ isActive }) => isActive && '8px'};
    }

    @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
      right: 0;
      width: 26px;
      height: 26px;
    }
    @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
      right: 0;
      width: 26px;
      height: 26px;
    }
  }
`;

const ProfileName = styled.div`
  margin-bottom: 8px;

  font-family: ofelia-text, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 31px;
  text-align: center;
  letter-spacing: -0.05em;
  color: #000000;
  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    margin-bottom: 2px;
    font-size: 18px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    margin-bottom: 2px;
    font-size: 18px;
  }
  @media (max-width: 389px) {
    margin-bottom: 2px;
    font-size: 14px;
  }
`;

const ProfileRole = styled.div`
  font-family: basic-sans, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 21px;
  line-height: 31px;
  text-align: center;
  letter-spacing: -0.06em;
  color: #000000;
  opacity: 0.5;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    margin-bottom: 2px;
    font-size: 17px;
    line-height: 31px;
    letter-spacing: -0.03em;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    margin-bottom: 2px;
    font-size: 17px;
    line-height: 31px;
    letter-spacing: -0.03em;
  }
  @media (max-width: 389px) {
    margin-bottom: 2px;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.03em;
  }
`;
