import { image } from 'assets';
import styled from 'styled-components';
import { MEDIA_QUERY } from 'properties/constant';
import { useScrollY } from 'hooks/useScrollY';
import { useEffect, useRef, useState } from 'react';

const ScrollAnimation1 = () => {
  const [offsetTop, setOffsetTop] = useState(0);
  const { getOffset } = useScrollY();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setOffsetTop(containerRef.current?.offsetTop!);
  }, []);

  const traslateY = getOffset(0.12, offsetTop);
  return (
    <Container ref={containerRef}>
      <OverlayBg />
      <MainObject src={image.group.Group67} alt="background" />
      <div>
        <DotContainer>
          <div
            style={{
              transform: `translateY(${traslateY}px)`,
            }}
          >
            <Object1 src={image.object.Obj0} alt={'dot'} />
          </div>
          <div
            style={{
              transform: `translateY(${traslateY}px)`,
            }}
          >
            <Object2 src={image.object.Obj0} alt={'dot'} />
          </div>
          <div
            style={{
              transform: `translateY(${traslateY}px)`,
            }}
          >
            <Object3 src={image.object.Obj0} alt={'dot'} />
          </div>
        </DotContainer>
      </div>
    </Container>
  );
};

export default ScrollAnimation1;

const Container = styled.section`
  position: relative;
`;

const OverlayBg = styled.div`
  margin: -120px auto 0;
  background: #ffffff;
  filter: blur(35px);
  width: 700px;
  z-index: 2;
  position: relative;
  height: 312px;
  border-radius: 50%;
  opacity: 0.9;
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    margin-top: -100px;
    width: 100%;
    height: 200px;
  }
`;

const MainObject = styled.img`
  z-index: 1;
  position: absolute;
  min-width: 1527px;
  width: 1527px;
  height: 872px;
  top: 160px;
  left: 50%;
  right: 50%;
  transform: translate(-50%);

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    margin-top: 0px;
  }

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    margin-top: -40px;
    min-width: 1003.24px;
    height: 808px;

    width: 1003.24px;
    height: 572.9px;
  }
`;

const DotContainer = styled.div`
  width: 590px;
  position: relative;
  margin: -130px auto 0;
  z-index: 1;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    margin-top: -140px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 100%;
    margin-top: 70px;
  }
`;

const Object1 = styled.img`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 0px;
  right: 30px;
  z-index: 1;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    width: 24px;
    height: 24px;
    right: 60px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    top: -100px;
    right: 50%;
    left: 50%;
    transform: translate(-50%);
    width: 15.77px;
    height: 15.77px;
    margin-left: 85px;
  }
`;

const Object2 = styled.img`
  width: 32px;
  height: 32px;
  position: absolute;
  left: 80px;
  top: 160px;
  z-index: 1;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    top: 157px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 21.02px;
    height: 21.02px;
    top: 0px;
    right: 50%;
    left: 50%;
    transform: translate(-50%);
    margin-left: -122px;
  }
`;

const Object3 = styled.img`
  width: 23px;
  height: 23px;
  position: absolute;
  right: 100px;
  top: 260px;
  z-index: 1;
  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    top: 230px;
    right: 140px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 15.11px;
    height: 15.11px;
    right: 50%;
    left: 50%;
    top: 80px;
    margin-left: 116px;
    transform: translate(-50%);
  }
`;
