import * as React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  isActive?: boolean;
}

const Bitmart: React.FC<Props> = ({
  isActive = false,
  width = 160,
  height = 46,
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 160 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1158_783"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="160"
        height="46"
      >
        <g clipPath="url(#clip0_1158_783)">
          <path
            d="M29.7595 8.57195C27.467 6.70098 24.3146 5.7655 20.3023 5.7655H10.3029V7.14992H20.1216C23.5336 7.14992 26.6242 7.84656 28.5099 9.46098C30.3955 11.0754 31.8214 13.4904 31.8214 16.4494C31.8214 19.3134 30.583 21.4365 28.6214 23.042C26.6599 24.6476 23.1609 25.3553 19.6597 25.3553H8.38379V26.7419H19.7489C23.9724 26.7419 27.2721 25.833 29.6479 24.0151C32.0238 22.1972 33.2139 19.6392 33.2184 16.3411C33.2184 13.0326 32.0721 10.4436 29.7796 8.57415"
            fill="#1C1E1D"
          />
          <path
            d="M28.9119 10.5778C27.4859 9.5052 25.848 8.6427 21.2131 8.6427H13.501V9.99174C13.501 9.99174 16.9509 9.94751 21.0413 9.99174C24.9933 10.0338 26.4438 10.4739 27.7738 11.4491C29.4742 12.6965 30.5319 14.2357 30.5319 16.3897C30.5319 18.4641 29.4586 19.9282 27.7492 21.1821C25.9997 22.4582 23.3264 22.4737 22.2374 22.509C21.8268 22.509 16.5113 22.509 16.5113 22.509V23.8581C16.5113 23.8581 19.1556 23.8957 20.7914 23.8581C24.5425 23.7763 26.7696 23.5551 28.8404 22.0291C30.7863 20.5983 31.8151 18.7118 31.8151 16.3212C31.8151 13.1675 30.224 11.5796 28.9141 10.5911"
            fill="#1C1E1D"
          />
          <path
            d="M28.2289 12.3603C27.16 11.6968 26.2206 11.2545 21.791 11.2545H16.2412V12.1391H21.6326C25.5556 12.1391 27.0418 12.8313 27.4925 13.1056C28.3851 13.6408 29.5366 14.5121 29.5366 16.288C29.5366 17.4225 28.8671 18.8489 27.8585 19.4085C26.9458 19.9171 25.2789 20.3727 22.3422 20.3727H4.2334V21.2573H22.4091C25.9506 21.2573 27.1645 20.8681 28.4142 20.1847C29.6638 19.5013 30.5006 18.0948 30.5006 16.2924C30.5006 14.2755 29.3648 13.068 28.2222 12.3603"
            fill="#1C1E1D"
          />
          <path
            d="M31.7482 22.1574C29.4572 20.2864 26.3048 19.351 22.291 19.351H15.5965V20.7354H22.0991C25.5134 20.7354 28.604 21.4342 30.4896 23.0464C32.3753 24.6586 33.8079 27.0648 33.8079 30.0349C33.8079 32.8988 32.5717 35.0219 30.6101 36.6275C28.6486 38.2331 25.1496 38.9408 21.6461 38.9408H10.377V40.3252H21.7309C25.9545 40.3252 29.2534 39.4162 31.6277 37.5984C34.0021 35.7805 35.1922 33.2232 35.1982 29.9265C35.1982 26.6181 34.0519 24.0291 31.7594 22.1596"
            fill="#1C1E1D"
          />
          <path
            d="M30.9066 24.161C29.4807 23.0907 27.8427 22.226 23.2079 22.226H12.7197V23.575C12.7197 23.575 18.9412 23.5308 23.0338 23.575C26.9858 23.617 28.4341 24.0593 29.7641 25.0346C31.469 26.2819 32.5245 27.8189 32.5245 29.973C32.5245 32.0496 31.4511 33.5114 29.7418 34.7676C27.9923 36.0436 25.3189 36.0591 24.2277 36.0945C23.8171 36.0945 18.5016 36.0945 18.5016 36.0945V37.4435C18.5016 37.4435 21.1482 37.4789 22.7839 37.4435C26.5328 37.3573 28.7621 37.1361 30.833 35.6124C32.7766 34.1793 33.8054 32.2951 33.8054 29.9022C33.8054 26.753 32.2165 25.1607 30.9044 24.1721"
            fill="#1C1E1D"
          />
          <path
            d="M14.96 28.7434H24.7853V27.9893H14.96V28.7434Z"
            fill="#1C1E1D"
          />
          <path
            d="M19.0127 31.3817H24.7299V30.7338H19.0127V31.3817Z"
            fill="#1C1E1D"
          />
          <path
            d="M10.3721 28.7434H13.282V27.9893H10.3721V28.7434Z"
            fill="#1C1E1D"
          />
          <path
            d="M8.55762 31.4348H17.3989V30.6807H8.55762V31.4348Z"
            fill="#1C1E1D"
          />
          <path
            d="M10.7363 12.0773H14.1818V11.4536H10.7363V12.0773Z"
            fill="#1C1E1D"
          />
          <path
            d="M8.33691 17.1239H16.2276V16.4008H8.33691V17.1239Z"
            fill="#1C1E1D"
          />
          <path
            d="M8.55762 37.3374H16.8767V36.4351H8.55762V37.3374Z"
            fill="#1C1E1D"
          />
          <path
            d="M6.48242 40.2699H8.5622V38.9607H6.48242V40.2699Z"
            fill="#1C1E1D"
          />
          <path
            d="M11.6816 14.9146H24.7472V14.1914H11.6816V14.9146Z"
            fill="#1C1E1D"
          />
          <path
            d="M18.2158 17.1239H24.4998V16.4008H18.2158V17.1239Z"
            fill="#1C1E1D"
          />
          <path
            d="M30.2128 26.0807C29.1394 25.4371 28.2044 25.0059 23.7726 25.0059H16.7098V25.875H23.6275C27.5505 25.875 29.0412 26.5385 29.4897 26.806C30.369 27.3235 31.5338 28.1639 31.5338 29.8779C31.5338 30.9836 30.8644 32.3504 29.8535 32.89C28.943 33.381 27.2738 33.821 24.3372 33.821H6.22168V34.6791H24.3952C27.9388 34.6791 29.155 34.3032 30.4024 33.6463C31.6499 32.9895 32.4867 31.6272 32.4867 29.8867C32.4867 27.9428 31.3531 26.7773 30.2128 26.0939"
            fill="#1C1E1D"
          />
          <path
            d="M48.8165 24.1412V32.0319H53.1657C55.0461 32.0319 56.5048 31.6751 57.5417 30.9616C58.0342 30.6462 58.4365 30.2106 58.7101 29.6967C58.9836 29.1827 59.1192 28.6077 59.1038 28.0268C59.1038 25.4349 56.905 24.1397 52.5074 24.1412H48.8165ZM48.8165 14.7488V21.8257H52.1057C53.8597 21.8257 55.2388 21.4873 56.243 20.8106C56.7242 20.5021 57.1161 20.0748 57.3802 19.5707C57.6443 19.0666 57.7715 18.5031 57.7493 17.9356C57.7493 15.8037 56.0012 14.7377 52.5052 14.7377L48.8165 14.7488ZM45.6143 34.3585V12.4244H53.3956C55.7595 12.4244 57.634 12.8881 59.019 13.8155C60.404 14.7429 61.0973 15.9511 61.0988 17.4402C61.1156 18.6418 60.6648 19.8036 59.8402 20.6845C59.0011 21.6031 57.8445 22.2555 56.3702 22.6417V22.7015C58.2134 22.8769 59.6877 23.4357 60.7931 24.3778C61.3318 24.827 61.7611 25.3912 62.0486 26.0281C62.3361 26.665 62.4745 27.3581 62.4533 28.0556C62.4533 29.931 61.6143 31.4503 59.9362 32.6136C58.258 33.7768 56.1411 34.3585 53.5852 34.3585H45.6143Z"
            fill="#1C1E1D"
          />
          <path
            d="M66.0192 34.3584H69.1433V18.6963H66.0192V34.3584ZM67.6214 14.7178C67.1058 14.7322 66.6008 14.5706 66.191 14.26C66.0059 14.1275 65.8555 13.9529 65.7526 13.7509C65.6497 13.5488 65.5972 13.3252 65.5997 13.0989C65.5975 12.8711 65.6499 12.646 65.7528 12.4422C65.8556 12.2384 66.0058 12.0617 66.191 11.9268C66.5989 11.6117 67.1045 11.4468 67.6214 11.4602C68.1474 11.4463 68.6623 11.6109 69.0809 11.9268C69.2661 12.0592 69.417 12.2333 69.521 12.4347C69.6251 12.6362 69.6793 12.8593 69.6793 13.0856C69.6793 13.312 69.6251 13.5351 69.521 13.7365C69.417 13.938 69.2661 14.1121 69.0809 14.2445C68.6646 14.5653 68.1486 14.7327 67.6214 14.7178Z"
            fill="#1C1E1D"
          />
          <path
            d="M82.9748 34.2058C82.2309 34.5331 81.258 34.696 80.0559 34.6946C76.6343 34.6946 74.9235 33.1649 74.9235 30.1056V20.8371H71.5762V18.6963H74.9235V14.8726L78.0476 14.0609V18.7051H82.9681V20.8459H78.0543V29.6722C78.0543 30.7204 78.2774 31.4724 78.7237 31.9191C79.17 32.3658 79.9042 32.5825 80.9374 32.5825C81.6532 32.6016 82.36 32.4207 82.977 32.0606V34.2036"
            fill="#1C1E1D"
          />
          <path
            d="M111.367 34.3585H108.437V20.8217C108.452 19.5099 108.533 18.1997 108.682 16.8962H108.613C108.426 17.5704 108.173 18.2251 107.859 18.8512L99.2628 34.3585H97.8235L89.2455 18.964C88.9219 18.3027 88.6691 17.6097 88.4913 16.8962H88.4221C88.5363 18.2103 88.5833 19.5294 88.5627 20.8482V34.3585H85.7197V14.1804H89.6137L97.3326 28.2502C97.7863 29.0239 98.1737 29.834 98.4907 30.6719H98.5956C99.0984 29.5661 99.5023 28.7405 99.8073 28.1949L107.682 14.1804H111.367V34.3585Z"
            fill="#1C1E1D"
          />
          <path
            d="M127.259 26.4345L122.551 26.9564C121.1 27.1186 120.007 27.4061 119.27 27.8189C118.534 28.2318 118.162 28.963 118.154 30.0128C118.151 30.3852 118.243 30.7523 118.422 31.0802C118.6 31.408 118.859 31.686 119.174 31.8882C119.853 32.3732 120.758 32.615 121.89 32.6135C123.442 32.6135 124.723 32.1779 125.733 31.3065C126.22 30.9016 126.609 30.3932 126.872 29.819C127.134 29.2447 127.263 28.6194 127.248 27.9892L127.259 26.4345ZM130.383 34.3584H127.259V31.9103H127.185C125.824 33.7842 123.822 34.7211 121.178 34.7211C119.232 34.7211 117.71 34.3083 116.61 33.4827C116.086 33.1089 115.662 32.614 115.375 32.0409C115.087 31.4678 114.945 30.8339 114.961 30.1941C114.961 27.2675 117.11 25.5639 121.408 25.0833L127.259 24.4198C127.259 21.766 125.92 20.4353 123.242 20.428C120.97 20.4051 118.746 21.0778 116.874 22.3542V19.7977C118.793 18.8187 121.005 18.3292 123.51 18.3292C128.1 18.3292 130.394 20.2768 130.394 24.1721V34.3584H130.383Z"
            fill="#1C1E1D"
          />
          <path
            d="M144.901 21.2307C144.354 20.8931 143.565 20.7243 142.536 20.7243C141.899 20.7145 141.269 20.8454 140.69 21.1075C140.111 21.3695 139.598 21.7561 139.189 22.2392C138.296 23.2491 137.85 24.6262 137.85 26.3704V34.354H134.726V18.6963H137.85V21.923H137.926C138.327 20.8781 139.039 19.9787 139.967 19.3443C140.87 18.731 141.941 18.4082 143.036 18.4199C143.667 18.3988 144.297 18.4734 144.906 18.641V21.2307"
            fill="#1C1E1D"
          />
          <path
            d="M155.789 34.2058C155.053 34.5331 154.08 34.696 152.872 34.6946C149.451 34.6946 147.74 33.1649 147.74 30.1056V20.8371H144.393V18.6963H147.74V14.8726L150.864 14.0609V18.7051H155.773V20.8459H150.864V29.6722C150.864 30.7204 151.087 31.4724 151.533 31.9191C151.98 32.3658 152.714 32.5825 153.745 32.5825C154.461 32.6022 155.168 32.4213 155.784 32.0606V34.2036"
            fill="#1C1E1D"
          />
        </g>
      </mask>
      <g mask="url(#mask0_1158_783)">
        <rect
          x="-24"
          y="-14"
          width="208"
          height="74"
          fill={isActive ? '#ffffff' : '#878787'}
        />
      </g>
      <defs>
        <clipPath id="clip0_1158_783">
          <rect width="160" height="46" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Bitmart;
