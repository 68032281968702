import { useRef, useState } from 'react';
import styled from 'styled-components';
import { ButtonGradient, Section } from 'components';
import { MEDIA_QUERY } from 'properties/constant';
import { useResponsive } from 'hooks/useResponsive';
import { requestCreateSubscriber } from 'apis/stibee';
import { image } from 'assets';

export const Newsletter = () => {
  const { isMobile } = useResponsive();
  const [invalidEmail, setInvalidEmail] = useState<boolean>(false);
  const emailRef = useRef<HTMLInputElement>(null);

  const onSubmitEmail = async () => {
    const email = emailRef.current?.value;

    if (email && validEmailCheck(email)) {
      requestCreateSubscriber('ENG', email);
      setInvalidEmail(false);
      emailRef.current.value = '';
    } else {
      setInvalidEmail(true);
    }
  };

  const validEmailCheck = (email: string) => {
    const pattern =
      /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
    return email.match(pattern) != null;
  };

  return (
    <Container>
      <TitleContainer>
        <Title>Newsletter</Title>
      </TitleContainer>
      <Text>Stay updated on the latest Protocon News.</Text>
      <InputContainer>
        <Input
          placeholder="Your email"
          ref={emailRef}
          onKeyUp={(e) => e.key === 'Enter' && onSubmitEmail()}
        />
        <ButtonGradient
          full={isMobile ? true : false}
          label="Subscribe"
          onClick={onSubmitEmail}
        />
      </InputContainer>
      {invalidEmail && (
        <InvalidEmail>
          <WarnIcon src={image.icon.Warn} />
          <InvalidEmailText>Invalid email address.</InvalidEmailText>
        </InvalidEmail>
      )}
    </Container>
  );
};

export default Newsletter;

const Container = styled(Section)`
  padding-bottom: 150px;
  text-align: center;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    padding-bottom: 190px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    padding-bottom: 104px;
  }
`;

const Title = styled.div`
  font-family: ofelia-text, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 58px;
  letter-spacing: -0.06em;
  text-align: left;
  padding-bottom: 20px;
  white-space: pre-line;
  padding-bottom: 20px;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 32px;
    line-height: 46px;
    padding-bottom: 6px;
  }
`;
const Text = styled.div`
  font-family: basic-sans, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 21px;
  line-height: 31px;
  letter-spacing: -0.06em;
  white-space: pre-line;
  padding-bottom: 53px;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-weight: 300;
    font-size: 17.5px;
    line-height: 30px;
    padding-bottom: 25px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
  }
`;
const Input = styled.input`
  font-family: ofelia-text, sans-serif;
  font-weight: 400;
  font-size: 15px;

  border: 0.7px solid #000000;
  border-radius: 13px;
  width: 387px;
  padding: 15px;
  box-sizing: border-box;

  letter-spacing: -0.06em;

  &::placeholder {
    color: rgba(0, 0, 0, 0.4);
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 100%;
    height: 63px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  gap: 14px;
  justify-content: center;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    flex-direction: column;
    gap: 28px;
  }
`;

const InvalidEmail = styled.div`
  padding: 15px;
  display: flex;
  gap: 4px;
  width: 560px;
  margin: 0 auto;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 100%;
    margin: 0;
  }
`;

const WarnIcon = styled.img`
  width: 18.12px;
  height: 16.16px;
`;
const InvalidEmailText = styled.div`
  font-family: basic-sans, sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.04em;
  color: #f86363;
  text-align: left;
`;
