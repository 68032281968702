export const WHITEPAPER_EN = 'http://bit.ly/3m2zdqm';
export const WHITEPAPER_KR = 'http://bit.ly/3Z6FbF8';
export const YELLOWPAPER_1_EN = 'http://bit.ly/3m3jWpi';
export const YELLOWPAPER_2_EN = 'http://bit.ly/41kboKY';
export const YELLOWPAPER_1_KR = 'http://bit.ly/3xSXmSV';
export const YELLOWPAPER_2_KR = 'http://bit.ly/3lZkc8S';

export const DEVELOPER_DOC = 'https://protocon-general-doc.readthedocs.io';
export const AUDIT_REPORT = 'https://www.certik.com/projects/protocon';

export const TELEGRAM_NOTICE_EN = 'https://t.me/PEN_announcements_Eng';
export const TELEGRAM_NOTICE_KR = 'https://t.me/PEN_announcements_Kor';
export const TELEGRAM_COMMUNITY_EN = 'https://t.me/Protocon_Official';
export const TELEGRAM_COMMUNITY_KR = 'https://t.me/Protocon_Korea';
export const TWITTER = 'https://twitter.com/protoconpen';
export const GITHUB = 'https://github.com/ProtoconNet';
export const YOUTUBE = 'https://www.youtube.com/c/Protocon';
export const MEDIUM = 'https://medium.com/protoconpen';
export const NAVER_BLOG = 'https://blog.naver.com/protoconpen';

export const PROTOCON_DASHBOARD =
  'https://info.demo.protocon.network/dashboard/protocon';
export const PROTOCON_EXPLORER =
  'https://explorer.test.protocon.network/dashboard';
export const PROTOCON_WALLET = 'https://www.protoconwallet.io/';
