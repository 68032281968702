import axios from 'axios';
import {
  AIRTABLE_API_URL,
  AIRTABLE_BASE_ID,
  AIRTABLE_TOKEN,
} from 'properties/constant';
import type {
  AboutPenDataResponse,
  CommunityChannelListResponse,
  CommunityNewsBannerResponse,
  CommunityNewsListResponse,
  CommunityProfileListResponse,
} from './airtable.type';

const instance = axios.create();
instance.defaults.baseURL = `${AIRTABLE_API_URL}/${AIRTABLE_BASE_ID}`;
instance.defaults.headers.post['Content-Type'] = 'application/json';
instance.defaults.headers['Authorization'] = `Bearer ${AIRTABLE_TOKEN}`;

export const requestCommunityNewsBanner =
  async (): Promise<CommunityNewsBannerResponse> => {
    const { data } = await instance.get('/CommunityNewsBanner', {
      params: {
        sort: [{ field: 'Order', direction: 'asc' }],
      },
    });
    return data;
  };

export const requestCommunityNewsList =
  async (): Promise<CommunityNewsListResponse> => {
    const { data } = await instance.get('/CommunityNewsList', {
      params: {
        sort: [{ field: 'Create', direction: 'desc' }],
      },
    });
    return data;
  };

export const requestCommunityChannelList =
  async (): Promise<CommunityChannelListResponse> => {
    const { data } = await instance.get('/CommunityChannelList', {
      params: {
        sort: [{ field: 'Order', direction: 'asc' }],
      },
    });
    return data;
  };

export const requestCommunityProfileList =
  async (): Promise<CommunityProfileListResponse> => {
    const { data } = await instance.get('/CommunityProfileList', {
      params: {
        sort: [{ field: 'Order', direction: 'asc' }],
      },
    });
    return data;
  };

export const requestAboutPenData = async (): Promise<AboutPenDataResponse> => {
  const { data } = await instance.get('/AboutPenData');
  return data;
};
