import {
  RouterProvider,
  createBrowserRouter,
  Navigate,
  Outlet,
} from 'react-router-dom';
import { Channel, Home, News, About } from 'pages';
import type { RouteObject } from 'react-router-dom';
import { PageLayout } from 'components';

const routes: RouteObject[] = [
  {
    path: '/',
    element: <PageLayout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: 'about',
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <Navigate to="pen-token" />,
          },
          {
            path: 'pen-token',
            element: <About />,
          },
        ],
      },
      {
        path: 'community',
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <Navigate to="news" />,
          },
          {
            path: 'news',
            element: <News />,
          },
          {
            path: 'channel',
            element: <Channel />,
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/" />,
  },
];

const router = createBrowserRouter(routes);

const AppRouter = () => <RouterProvider router={router} />;

export default AppRouter;
