import * as React from 'react';

const Menu: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => {
  // const name = "icon-menu";

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 25 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0 1h25M0 8.742h25M0 17h25" stroke="#000" strokeWidth={1.3} />
    </svg>
  );
};

export default Menu;
