import * as React from 'react';
import styled from 'styled-components';
import { Icon } from 'components';
import { GradientBorder } from 'styles/Common';
import type { NewsCategoryListType, NewsCategoryType } from 'types/types';

interface Props {
  category: NewsCategoryListType;
  current: NewsCategoryType;
  setCategory: (category: NewsCategoryType) => void;
}

const Category: React.FC<Props> = ({ category, current, setCategory }) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const handleClickOption = (key: NewsCategoryType) => {
    setCategory(key);
    setIsOpen(false);
  };

  return (
    <Container>
      <Selected onClick={() => setIsOpen(true)}>
        <div className="value">{category[current]}</div>
        <div className="arrow">
          <Icon.CaretDownGradient />
        </div>
      </Selected>

      <Option isOpen={isOpen}>
        <Selected onClick={() => setIsOpen(false)}>
          <div className="value">{category[current]}</div>
          <div className="arrow">
            <Icon.CaretDownGradient />
          </div>
        </Selected>
        {Object.entries(category).map((el) => {
          const categoryKey = el[0] as NewsCategoryType;
          const categoryValue = el[1];

          if (current === categoryKey) return null;

          return (
            <span
              key={categoryKey}
              onClick={(e) => handleClickOption(categoryKey)}
            >
              {categoryValue}
            </span>
          );
        })}
      </Option>
    </Container>
  );
};

export default Category;

const Container = styled(GradientBorder)`
  position: relative;
  width: 100%;
  margin-bottom: 42px;
  display: flex;
  flex-direction: column;
  background: #fff;

  ::before {
    border-radius: 0px;
    border-width: 1px;
  }
`;

const Selected = styled.div`
  position: relative;
  width: 100%;
  padding: 18px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  .value {
    font-family: basic-sans, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 21px;
    letter-spacing: -0.05em;
    color: #000000;
  }
  .arrow {
    width: 13px;
    height: 13px;
  }
`;

const Option = styled(GradientBorder)<{ isOpen: boolean }>`
  position: absolute;
  z-index: 1;
  width: 100%;
  padding-bottom: 22px;
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 28px;
  background: #fff;

  ::before {
    border-radius: 0px;
    border-width: 1px;
  }

  & > span {
    position: relative;
    width: 100%;
    padding: 0px 15px;

    font-family: basic-sans, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 21px;
    line-height: 21px;
    letter-spacing: -0.05em;
    color: #000000;
    cursor: pointer;
  }
`;
