import * as React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { SITE_MAP } from 'properties/sitemap';
import { MEDIA_QUERY, SIDE_NAV_Z_INDEX } from 'properties/constant';
import { Icon } from 'components';
import type { Sitemap } from 'types/types';

interface Props {
  onClose: () => void;
}

const SideNavigation: React.FC<Props> = ({ onClose }) => {
  const location = useLocation();
  const currentPath = location.pathname.split('/');

  const navigate = useNavigate();

  const [dropdown, setDropdown] = React.useState<string | undefined>(undefined);

  const handleLink = (link?: string) => {
    if (link === undefined) return;

    // TODO: useNavigate와 location.href 통합
    if (link[0] === '/') {
      navigate(link);
      onClose();
    } else {
      window.open(link, '_blank', 'noopener noreferrer');
    }
  };

  const handleClickItem = (item: Sitemap) => {
    const { id, link, sub } = item;
    setDropdown((prev) => (prev === id ? undefined : id));
    if (currentPath[1] === id) return;
    if (link && sub === undefined) {
      handleLink(link);
    }
  };

  return (
    <Container>
      <Header>
        <Logo to={'/'} onClick={onClose}>
          <Icon.LogoProtocon />
        </Logo>
        <Spacing />
        <Button onClick={onClose}>
          <Icon.XButton />
        </Button>
      </Header>
      <MenuList>
        {SITE_MAP.map((el, index) => (
          <React.Fragment key={el.id}>
            <Menu
              data-index={index}
              onClick={() => handleClickItem(el)}
              active={currentPath[1] === el.id}
            >
              <span>{el.label}</span>
              {el.sub && (
                <React.Fragment>
                  <Dropdown isOpen={dropdown === el.id}>
                    {el.sub.map((subEl) => (
                      <DropdownItem
                        key={subEl.id}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleLink(subEl.link);
                        }}
                        isOpen={dropdown === el.id}
                        isLink={!!subEl.link}
                        active={
                          currentPath[1] === el.id &&
                          currentPath[2] === subEl.id
                        }
                      >
                        {typeof subEl.label === 'string' ? (
                          <span>{subEl.label}</span>
                        ) : (
                          subEl.label.map((labelEl, _i) => (
                            <span key={_i}>{labelEl}</span>
                          ))
                        )}
                      </DropdownItem>
                    ))}
                  </Dropdown>
                  <Spacing />
                  <IconBox active={dropdown === el.id}>
                    <Icon.CaretDown />
                  </IconBox>
                </React.Fragment>
              )}
            </Menu>
            {index !== SITE_MAP.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </MenuList>
    </Container>
  );
};

export default SideNavigation;

const Container = styled.div`
  position: fixed;
  z-index: ${SIDE_NAV_Z_INDEX};
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background: #fff;
  overflow-y: auto;
  user-select: none;
`;

const Header = styled.div`
  width: 100%;
  height: ${MEDIA_QUERY.TABLET.HEADER_HEIGHT}px;
  padding: 0 ${MEDIA_QUERY.TABLET.BASE_X_PADDING}px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    height: ${MEDIA_QUERY.TABLET.HEADER_HEIGHT}px;
    padding: 0 ${MEDIA_QUERY.TABLET.BASE_X_PADDING}px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    height: ${MEDIA_QUERY.MOBILE.HEADER_HEIGHT}px;
    padding: 0 ${MEDIA_QUERY.MOBILE.BASE_X_PADDING}px;
  }
`;

const Logo = styled(Link)`
  flex: 0 0 auto;
  width: 142px;
  height: 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    width: 134px;
    height: 24px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 120px;
    height: 22px;
  }
`;

const Spacing = styled.div`
  flex: 1 0 auto;
`;

const Button = styled.div`
  width: 36px;
  padding: 7px;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const MenuList = styled.div`
  width: 100%;
  left: 0;
  padding: 0 ${MEDIA_QUERY.TABLET.BASE_X_PADDING}px;
  margin-top: 31px;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    padding: 0 ${MEDIA_QUERY.TABLET.BASE_X_PADDING}px;
    margin-top: 31px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    padding: 0 ${MEDIA_QUERY.MOBILE.BASE_X_PADDING}px;
    margin-top: 36px;
  }
`;

const Menu = styled.div<{ active: boolean }>`
  padding: 40px 0;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  &[data-index='0'] {
    padding-top: 0;
  }

  & > span {
    width: 124px;
    height: 38px;
    display: flex;
    align-items: center;

    font-family: ofelia-text, sans-serif;
    font-style: normal;
    font-weight: ${({ active }) => (active ? 'bold' : 500)};
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.03em;
    color: #000000;
    cursor: pointer;

    :hover,
    :active {
      font-weight: bold;
    }
  }

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    padding: 40px 0;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    padding: 30px 0;
    flex-wrap: wrap;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: linear-gradient(111.93deg, #4ae6e6 3.86%, #fc72ff 115.46%);
`;

const Dropdown = styled.div<{ isOpen: boolean }>`
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  row-gap: ${({ isOpen }) => (isOpen ? '26px' : 0)};

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    padding: 0 10px;
    row-gap: ${({ isOpen }) => (isOpen ? '26px' : 0)};
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 100%;
    padding: 0;
    margin-top: ${({ isOpen }) => (isOpen ? '25px' : 0)};
    order: 1;
    row-gap: ${({ isOpen }) => (isOpen ? '28px' : 0)};
  }
`;

const DropdownItem = styled.div<{
  isOpen: boolean;
  isLink: boolean;
  active: boolean;
}>`
  width: 100%;
  height: ${({ isOpen }) => (isOpen ? '38px' : 0)};
  display: flex;
  /* flex-direction: column; */
  justify-content: flex-start;
  align-items: center;
  transition: height 0.25s;
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};

  & > span {
    font-family: basic-sans, sans-serif;
    font-style: normal;
    font-weight: ${({ active }) => (active ? 600 : 300)};
    font-size: 20px;
    line-height: 16px;
    letter-spacing: -0.05em;
    color: #000000;
    cursor: ${({ isLink }) => (isLink ? 'pointer' : 'default')};
  }

  :hover,
  :active {
    & > span {
      ${({ isLink }) => isLink && `font-weight: 600`};
    }
  }

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    & > span {
      font-size: 20px;
      line-height: 16px;
    }
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    & > span {
      font-size: 18px;
      line-height: 16px;
    }
  }
`;

const IconBox = styled.div<{ active: boolean }>`
  width: 36px;
  height: 38px;
  padding: 7px;
  display: flex;

  transform: ${({ active }) => (active ? 'rotate(180deg)' : 'none')};
  transition: transform 0.25s;
`;
