import * as React from 'react';

const CaretDownGradient: React.FC<React.SVGProps<SVGSVGElement>> = ({
  ...props
}) => {
  const name = 'icon-caret-down-gradient';
  const gradientId = `${name}-gradient`;

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 11 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.366 8.5a1 1 0 0 1-1.732 0L.737 1.75a1 1 0 0 1 .866-1.5h7.794a1 1 0 0 1 .866 1.5L6.366 8.5Z"
        fill={`url(#${gradientId})`}
      />
      <defs>
        <linearGradient
          id={gradientId}
          x1={9.887}
          y1={13.5}
          x2={-7.623}
          y2={6.451}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4AE6E6" />
          <stop offset={1} stopColor="#FC72FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CaretDownGradient;
