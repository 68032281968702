import styled from 'styled-components';
import { useResponsive } from 'hooks/useResponsive';
import { GradientText } from 'styles/Common';
import { MEDIA_QUERY } from 'properties/constant';
import { HOME_MITUM } from 'properties/contents';
import { CardGradient } from 'components';
import { image } from 'assets';

const PillarsOfMitum = () => {
  const { isMobile } = useResponsive();
  const { title, text } = HOME_MITUM;
  return (
    <Container>
      <Inner>
        <SectionTitle>
          {title}&nbsp;
          <GradientText as="span">{`Public Mainnet.`}</GradientText>
        </SectionTitle>
        <Text>{isMobile ? text.mobile : text.desktop}</Text>

        <CardList>
          <CardGradient
            title={'ISAAC++'}
            desc={
              'Our ISAAC++ is a powerful engine on an open network developed with Protocon-BFT. \nThrough a consensus-based proof-of-authority (PoA) with one vote per node, the issues of a network monopoly and network vulnerabilities are solved.'
            }
            imgSrc={image.object.ObjIssac}
            imgAlt={'issac++'}
            buttonLabel={'coming soon'}
            onClick={() => {}}
          />
          <CardGradient
            title={'Contract Model'}
            desc={
              'Our Contract Model is an alternative to Smart Contract (EVM) as our Contract Model has processing speeds that are 10x faster than EVM. Safety and security related vulnerabilities are reduced immensely by verifying and distributing the model in advance.'
            }
            imgSrc={image.object.ObjContractModel}
            imgAlt={'issac++'}
            buttonLabel={'coming soon'}
            onClick={() => {}}
          />
        </CardList>
      </Inner>
    </Container>
  );
};
export default PillarsOfMitum;

const Container = styled.section`
  margin-top: 642px;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    margin-top: 672px;
    padding: 0 76px;
  }

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    margin-top: 453px;
    padding: 0 22px;
  }
`;

const SectionTitle = styled.div`
  font-family: ofelia-text, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 58px;
  letter-spacing: -0.06em;
  padding-bottom: 20px;
  white-space: pre;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    padding-bottom: 40px;
    white-space: inherit;
    width: 590px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 46px;
    letter-spacing: -0.06em;
    max-width: 310px;
    width: 100%;
    padding-bottom: 30px;
  }
`;
const Inner = styled.div`
  max-width: 945px;
  margin: 0 auto;
  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
  }
`;

const Text = styled.div`
  font-family: basic-sans, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 21px;
  line-height: 31px;
  letter-spacing: -0.06em;
  color: #000000;
  padding-bottom: 48px;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    width: 532px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    max-width: 376px;
    width: 100%;
    white-space: pre-line;
    font-size: 17.5px;
    line-height: 30px;
    padding-bottom: 44px;
  }
`;
const CardList = styled.div`
  display: flex;
  gap: 19px;
  z-index: 1;
  height: 485px;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    flex-direction: column;
    gap: 27px;
    max-width: 530px;
    height: auto;
  }

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    height: auto;
  }
`;
