import { useMediaQuery } from 'react-responsive';
import { MEDIA_QUERY } from 'properties/constant';

export const useResponsive = () => {
  const isMobile = useMediaQuery({ maxWidth: MEDIA_QUERY.MOBILE.MAX_WIDTH });
  const isTablet = useMediaQuery({
    minWidth: MEDIA_QUERY.MOBILE.MAX_WIDTH + 1,
    maxWidth: MEDIA_QUERY.TABLET.MAX_WIDTH,
  });
  const isDesktop = useMediaQuery({
    minWidth: MEDIA_QUERY.TABLET.MAX_WIDTH + 1,
  });

  return { isMobile, isTablet, isDesktop };
};
