import styled from 'styled-components';
import { MEDIA_QUERY } from 'properties/constant';
import { image } from 'assets';
import { useResponsive } from 'hooks';
import { useScrollY } from 'hooks/useScrollY';
import { useEffect, useRef, useState } from 'react';

const ScrollAnimation2 = () => {
  const { isMobile, isTablet } = useResponsive();
  const { getOffset } = useScrollY();
  const [offsetTop, setOffsetTop] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setOffsetTop(containerRef.current?.offsetTop!);
  }, []);

  return (
    <div ref={containerRef}>
      <Container>
        {isMobile ? (
          <BgObjectMobile src={image.group.Group68Mobile} alt="background" />
        ) : (
          <BgObject src={image.group.Group68} alt="background" />
        )}

        <Main>
          <ObjectContainer>
            <Object1
              src={
                isMobile ? image.object.Obj32_1_Mobile : image.object.Obj32_1
              }
              alt="object"
            />
          </ObjectContainer>
          <ObjectContainer
            style={{
              transform: `rotate(${getOffset(0.05, offsetTop) + -90}deg)`,
            }}
          >
            <Object2 src={image.object.Obj15} alt="object" />
          </ObjectContainer>
          <ObjectContainer
            style={{
              transform: `rotate(${
                getOffset(0.03, offsetTop) +
                (isMobile ? -10 : isTablet ? 10 : 30)
              }deg)`,
            }}
          >
            <Object3 src={image.object.Obj14} alt="object" />
          </ObjectContainer>
        </Main>
      </Container>
    </div>
  );
};

export default ScrollAnimation2;

const Container = styled.section`
  position: relative;
  padding-top: 300px;
  max-width: 945px;
  margin: 0 auto;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    position: relative;
    min-width: 945px;
    padding-top: 640px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    position: relative;
    padding-top: 1200px;
  }
`;

const BgObject = styled.img`
  position: absolute;
  height: 1556px;
  min-width: 1832px;
  left: 50%;
  right: 50%;
  transform: translate(-50%);

  margin-top: -825px;
  margin-left: -170px;

  mix-blend-mode: soft-light;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    margin-top: -1004px;
    margin-left: -25px;
    mix-blend-mode: initial;
    opacity: 0.2;
  }
`;

const BgObjectMobile = styled.img`
  position: absolute;
  width: 472.7px;
  min-height: 907.2px;

  transform: rotate(19deg);
  margin-top: -1285px;
  margin-left: -22px;
  mix-blend-mode: soft-light;
  opacity: 0.7;
`;

const ObjectContainer = styled.div`
  height: 300px;
  width: 300px;
  transform-origin: center;

  &:nth-child(1) {
    position: absolute;
    left: -100px;
    top: -40px;
    @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
      left: 40px;
      top: -15px;
    }
    @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
      top: -0px;
      left: 29px;
    }
  }

  &:nth-child(2) {
    position: absolute;
    left: 251px;
    top: 81px;
    @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
      top: 140px;
      left: 421px;
    }
    @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
      left: 140px;
    }
  }
  &:nth-child(3) {
    position: absolute;
    top: 280px;
    left: 372px;
    @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
      top: 320px;
      left: 562px;
    }
    @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
      left: 165px;
      top: 460px;
    }
  }
`;

const Object1 = styled.img`
  position: absolute;
  width: 247.67px;
  height: 222.62px;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    transform: rotate(2deg);
  }

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 232.6px;
    height: 254px;
  }
`;
const Object2 = styled.img`
  position: absolute;
  width: 70px;
  height: 76px;
  transform: rotate(41.17deg);

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 65.74px;
    height: 71.38px;
  }
`;
const Object3 = styled.img`
  position: absolute;
  width: 51.67px;
  height: 52.33px;
  transform: rotate(46.85deg);

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 48.53px;
    height: 49.15px;
  }
`;

const Main = styled.div`
  position: relative;
  height: 600px;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    position: absolute;
    top: 434px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    height: 800px;
  }
`;
