import * as React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  isActive?: boolean;
}

const Digfinex: React.FC<Props> = ({
  isActive = false,
  width = 181,
  height = 39,
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 181 39`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1158_773"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="181"
        height="39"
      >
        <g clipPath="url(#clip0_1158_773)">
          <path
            d="M36.3043 20.8364C34.288 31.2878 28.2713 36.5146 18.2542 36.5146H11.9046L10.5659 36.4391L12.3795 29.232H17.8359C23.2636 29.232 26.5503 26.1554 27.6975 20.0018C27.7791 19.6067 27.8455 19.2087 27.8964 18.8087L36.6033 13.7991C36.7131 14.5884 36.7698 15.4185 36.7698 16.2909C36.7698 17.5894 36.6146 19.1051 36.3041 20.8364H36.3043ZM33.5818 7.22276L25.7738 11.7172C24.6855 10.9941 23.2011 10.6318 21.3232 10.6318H8.72363L8.76147 10.4506L10.2514 3.48914H23.3243C25.1512 3.48914 26.8002 3.69671 28.2767 4.11377C30.2383 4.6689 31.8894 5.58795 33.2351 6.87341C33.3531 6.98749 33.4687 7.10396 33.5818 7.22276Z"
            fill="#6E45B7"
          />
          <path
            d="M21.132 16.8306L19.9753 24.0336H14.5663L11.9216 36.4393L11.9045 36.5146H0L4.46965 30.5026L7.40244 16.8306H21.132Z"
            fill="#00D8CD"
          />
          <path
            d="M46.7707 27.314H52.8976C56.2745 27.314 59.5097 24.5718 60.1052 21.2053C60.7007 17.8384 58.4352 15.0962 55.0584 15.0962H48.9316L46.7707 27.314ZM55.6586 11.7026C60.9144 11.7026 64.4403 15.938 63.5137 21.1782C63.1007 23.5131 61.8774 25.6578 60.1685 27.314C59.9717 27.5041 59.7472 27.6941 59.5232 27.8839C57.5 29.6217 54.9438 30.6807 52.3297 30.6807H42.7988L46.1602 11.6755L55.6586 11.7026ZM63.222 30.6806L66.5829 11.6754H69.9866L66.6255 30.6806H63.222ZM73.0712 21.1782C72.4761 24.545 74.7416 27.2869 78.1181 27.2869H84.2449L84.8885 23.6487H78.7617L79.3616 20.2551H88.8925L87.0486 30.6804H77.5177C72.2621 30.6804 68.7407 26.4183 69.6675 21.1782C70.5941 15.938 75.6233 11.6755 80.8791 11.6755H90.4097L89.8093 15.0691H80.2788C76.902 15.0691 73.6666 17.8113 73.0712 21.1782ZM88.4098 30.6806L91.7708 11.6754H95.1745L91.8135 30.6806H88.4098Z"
            fill="#6E45B7"
          />
          <path
            d="M115.624 11.6754L115.024 15.0694H101.109C100.156 15.0694 99.2274 15.8566 99.0595 16.8068L98.7473 18.5716H111.001L110.401 21.9652H98.1198L96.5786 30.6806H93.2021L95.6554 16.8069C96.1548 13.9834 98.8501 11.6755 101.682 11.6755L115.624 11.6754ZM113.598 30.6806L116.959 11.6754H120.362L117.001 30.6806H113.598ZM140.78 11.7025L137.419 30.7078L134.477 28.1014L123.889 18.6802L121.762 30.7078H118.358L121.709 11.7567L124.652 14.3632L135.24 23.7845L137.382 11.6754L140.78 11.7025ZM144.643 16.8069L144.331 18.5718H156.585L155.985 21.9652H143.731L143.097 25.5493C142.929 26.4995 143.579 27.2867 144.532 27.2867H158.447L157.847 30.6807H143.932C141.1 30.6807 139.221 28.3729 139.72 25.5493L141.266 16.8068C141.766 13.9829 144.461 11.6754 147.293 11.6754H161.208L160.608 15.0694H146.693C145.74 15.0694 144.811 15.8566 144.643 16.8068M181 11.7026L171.935 21.2323L177.612 30.7078H173.309L169.294 24.0016L162.907 30.7078H158.604L167.605 21.2323L161.911 11.7026H166.214L170.247 18.463L176.67 11.7026H180.945H181Z"
            fill="#00D8CD"
          />
        </g>
      </mask>
      <g mask="url(#mask0_1158_773)">
        <rect
          x="-13"
          y="-17"
          width="208"
          height="74"
          fill={isActive ? '#ffffff' : '#878787'}
        />
      </g>
      <defs>
        <clipPath id="clip0_1158_773">
          <rect width="181" height="39" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Digfinex;
