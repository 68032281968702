import styled from 'styled-components';

export const H5 = styled.p<{ pb?: number }>`
  font-family: ofelia-text, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 42px;
  letter-spacing: -0.05em;
  padding-bottom: ${(props) => (props.pb ? `${props.pb}px` : '0px')};
`;

export const H6 = styled.h6<{ pb?: number }>`
  font-family: ofelia-text, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 40px;
  letter-spacing: -0.06em;
  padding-bottom: ${(props) => (props.pb ? `${props.pb}px` : '0px')};
`;
