import axios from 'axios';
import {
  STIBEE_API_URL,
  STIBEE_LIST_ID_ENG,
  STIBEE_LIST_ID_KOR,
  STIBEE_ACCESS_TOKEN,
} from 'properties/constant';
import type {
  CreateSubscriberRequest,
  CreateSubscriberResponse,
} from './stibee.type';

const instance = axios.create();
instance.defaults.baseURL = `${STIBEE_API_URL}`;
instance.defaults.headers.post['Content-Type'] = 'application/json';
instance.defaults.headers.common['AccessToken'] = STIBEE_ACCESS_TOKEN;

export const requestCreateSubscriber = async (
  lang: 'ENG' | 'KOR',
  email: string
): Promise<CreateSubscriberResponse> => {
  const listId = lang === 'ENG' ? STIBEE_LIST_ID_ENG : STIBEE_LIST_ID_KOR;
  const requestBody: CreateSubscriberRequest = {
    eventOccuredBy: 'SUBSCRIBER',
    confirmEmailYN: 'N',
    subscribers: [
      {
        email: email,
        name: '',
        $ad_agreed: 'N',
      },
    ],
  };
  const { data } = await instance.post(
    `/lists/${listId}/subscribers`,
    requestBody
  );
  return data;
};
