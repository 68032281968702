import * as React from 'react';
import styled from 'styled-components';
import { HEADER_HEIGHT, MEDIA_QUERY } from 'properties/constant';

interface Props {
  background?: string;
  children: React.ReactNode;
}

const Content: React.FC<Props> = ({ background, children }) => {
  return <Container background={background}>{children}</Container>;
};

export default Content;

const Container = styled.div<{ background?: string }>`
  position: relative;
  z-index: 0;
  padding-top: ${HEADER_HEIGHT}px;
  background: ${({ background }) =>
    background ? `url(${background})` : 'transparent'};
  background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-position: center top;
  /* TODO: for background issue */
  border-bottom: 1px solid transparent;
  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    padding-top: ${MEDIA_QUERY.TABLET.HEADER_HEIGHT}px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    padding-top: ${MEDIA_QUERY.MOBILE.HEADER_HEIGHT}px;
  }
`;
