import styled from 'styled-components';

export const GradientBorder = styled.div`
  position: relative;

  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(111.93deg, #4ae6e6 3.86%, #fc72ff 115.46%)
      border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    border-radius: 12px;
    border: 1px solid transparent;
  }
`;

export const GradientText = styled.div`
  background: linear-gradient(111.93deg, #4ae6e6 3.86%, #fc72ff 115.46%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  display: inline-block;
`;

export const Relative = styled.div`
  position: relative;
`;
