import * as React from 'react';

const CaretDown: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => {
  // const name = "icon-caret-down";

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 23 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="m1 1 10.5 10L22 1" stroke="#000" strokeLinecap="round" />
    </svg>
  );
};

export default CaretDown;
