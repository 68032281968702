import * as React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  isActive?: boolean;
}

const Youtube: React.FC<Props> = ({ isActive = false, ...props }) => {
  const name = 'icon-youtube';
  const gradientId = `${name}-gradient`;
  const gradientId2 = `${name}-gradient-2`;

  const circleFill = isActive ? '#fff' : `url(#${gradientId})`;
  const circleStroke = isActive ? `url(#${gradientId})` : '#fff';
  const pathFill = isActive ? `url(#${gradientId2})` : '#fff';

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={25.001}
        cy={25.001}
        r={24.001}
        fill={circleFill}
        stroke={circleStroke}
        strokeWidth={0.7}
        strokeMiterlimit={10}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.644 16.643a4.95 4.95 0 0 0-4.95 4.95v6.931a4.95 4.95 0 0 0 4.95 4.95h16.832a4.95 4.95 0 0 0 4.95-4.95v-6.93a4.95 4.95 0 0 0-4.95-4.951H16.644Zm5.941 4.95v6.932l5.94-3.78-5.94-3.151Z"
        fill={pathFill}
      />
      <defs>
        <linearGradient
          id={gradientId}
          x1={8.803}
          y1={-11.923}
          x2={73.456}
          y2={14.106}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4AE6E6" />
          <stop offset={1} stopColor="#FC72FF" />
        </linearGradient>
        <linearGradient
          id={gradientId2}
          x1={16.039}
          y1={12.111}
          x2={45.739}
          y2={31.102}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4AE6E6" />
          <stop offset={1} stopColor="#FC72FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Youtube;
