import styled from 'styled-components';
import { H6 } from 'styles/Fonts';
import { MEDIA_QUERY } from 'properties/constant';
import { COMMUNITY_LIST } from 'properties/community';
import { Section } from 'components';

export const Community = () => {
  const onClickCommunity = (url: string | null) => {
    if (url) {
      window.open(url);
    }
  };
  return (
    <Container>
      <Inner>
        <Header>
          <Title>{`Join our Global\nCommunity`}</Title>
          <Text>
            {`Join a growing community of developers, builders, and innovators all over the world that are contributing to the new Protocol Economy.`}
          </Text>
        </Header>
        <CardContainer>
          <CardBg />
          <Card>
            <CommunityList>
              {COMMUNITY_LIST.map((community) => {
                const { id, icon, iconHover, name, text, url } = community;
                return (
                  <CommunityListItem
                    key={id}
                    onClick={() => onClickCommunity(url)}
                    isCursorPointer={url}
                    hoverImage={iconHover}
                  >
                    <Icon image={icon} />
                    <TextContainer>
                      <H6>{name}</H6>
                      <CommunityText>{text}</CommunityText>
                    </TextContainer>
                  </CommunityListItem>
                );
              })}
            </CommunityList>
          </Card>
        </CardContainer>
      </Inner>
    </Container>
  );
};

export default Community;

const Container = styled(Section)`
  padding-bottom: 320px;
  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    padding: 0 77px 544px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    padding: 0 ${MEDIA_QUERY.MOBILE.BASE_X_PADDING}px 320px;
    margin-top: -240px;
  }
`;

const Header = styled.div`
  width: 352px;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    width: 400px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 100%;
  }
`;
const CardContainer = styled.div`
  width: 486px;
  position: relative;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    width: 530px;
  }

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 100%;
  }
`;
const Inner = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  gap: 48px;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    flex-direction: column;
    gap: 0px;
  }

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
  }
`;
const Title = styled.div`
  font-family: ofelia-text, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 58px;
  letter-spacing: -0.06em;
  text-align: left;
  padding-bottom: 20px;
  white-space: pre-line;

  padding-bottom: 20px;
  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    padding-bottom: 30px;
  }

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-family: ofelia-text, sans-serif;
    font-size: 32px;
    line-height: 46px;
    letter-spacing: -0.06em;
    padding-bottom: 21px;
  }
`;

const Text = styled.div`
  font-family: basic-sans, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 21px;
  line-height: 31px;
  letter-spacing: -0.06em;
  white-space: pre-line;
  padding-bottom: 36px;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    padding-bottom: 40px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 17.5px;
    line-height: 30px;
    max-width: 340px;
    width: 100%;
    padding-bottom: 23px;
  }
`;
const CardBg = styled.div`
  background: linear-gradient(111.93deg, #4ae6e6 3.86%, #fc72ff 115.46%);
  opacity: 0.3;
  backdrop-filter: blur(7.5px);
  border-radius: 20px;
  height: 100%;
  width: 486px;
  position: absolute;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    width: 530px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 100%;
    max-width: 530px;
  }
`;
const Card = styled.div`
  padding: 53px 36px;
  position: relative;
  z-index: 1;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    width: 530px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    padding: 30px 6px 30px 16px;
    width: 100%;
  }
`;

const CommunityText = styled.p`
  font-family: basic-sans, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.04em;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    padding-right: 20px;
  }

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    max-width: 240px;
    padding-right: 0px;
  }
`;

const CommunityList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 35px;
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    gap: 28px;
  }
`;
const CommunityListItem = styled.div<{
  hoverImage: string;
  isCursorPointer: string | null;
}>`
  display: flex;
  align-items: center;
  gap: 30px;
  cursor: ${(props) => (props.isCursorPointer ? 'pointer' : 'normal')};
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    gap: 19px;
  }
  @media (max-width: 350px) {
    flex-wrap: wrap;
  }

  &:hover {
    div:first-child {
      background-image: url(${(props) => props.hoverImage});
    }
  }
`;

const Icon = styled.div<{ image: string }>`
  width: 65px;
  height: 65px;
  background-size: contain;
  transition: background-image 0.2s;
  background-repeat: no-repeat;
  background-image: url(${(props) => props.image});
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 56.89px;
    height: 56.89px;
  }
`;

const TextContainer = styled.div`
  flex: 1;
`;
