import * as React from 'react';
import styled from 'styled-components';
import { useResponsive } from 'hooks/useResponsive';
import { Section } from 'components';
import { MEDIA_QUERY } from 'properties/constant';

const PageTitle: React.FC = () => {
  const { isDesktop, isTablet, isMobile } = useResponsive();

  return (
    <Container>
      <Title>Meet Team Protocon</Title>
      <SubTitle>
        We are a curated team of Web3
        {isMobile && <br />} experts united
        {isTablet && <br />} by one single mission
        <br /> - to bring the true protocol economy
        {isMobile && <br />}
        to the masses.
      </SubTitle>
      <Text>
        We are a tight-knit team focused on empowering
        {isMobile && <br />} protocol economy, Protocon.
      </Text>
      {isMobile && <br />}
      <Text>
        We’ve walked a long and sometimes a bumpy road
        {isMobile && <br />} together to build a company
        {(isDesktop || isTablet) && <br />} where we do
        {isMobile && <br />} big things. With over 100 years of combined
        {isMobile && <br />} experience in Web3,
        {(isDesktop || isTablet) && <br />} we've got a well-seasoned,
        {isMobile && <br />} intimate team at the helm.
      </Text>
    </Container>
  );
};

export default PageTitle;

const Container = styled(Section)`
  margin-bottom: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    margin-bottom: 93px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    margin-bottom: 84px;
  }
`;

const Title = styled.h2`
  margin-bottom: 24px;

  font-family: ofelia-text, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 58px;
  letter-spacing: -0.06em;
  text-align: center;

  background: linear-gradient(111.93deg, #4ae6e6 3.86%, #fc72ff 115.46%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    font-size: 45px;
    line-height: 58px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 32px;
    line-height: 46px;
  }
`;

const SubTitle = styled.h3`
  margin-bottom: 24px;
  font-family: ofelia-text, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 38px;
  text-align: center;
  letter-spacing: -0.05em;
  color: #000000;
  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    font-weight: 700;
    font-size: 26px;
    line-height: 38px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-weight: 600;
    font-size: 17px;
    line-height: 28px;
  }
`;

const Text = styled.p`
  max-width: 650px;

  font-family: basic-sans, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 21px;
  line-height: 31px;
  text-align: center;
  letter-spacing: -0.06em;
  color: #000000;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    max-width: 674px;

    font-size: 21px;
    line-height: 31px;
    letter-spacing: -0.06em;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    max-width: 356px;

    font-size: 17.5px;
    line-height: 30px;
    letter-spacing: -0.07em;
  }
`;
