import * as React from 'react';
import { Content } from 'components';
import { image } from 'assets';
import { useResponsive } from 'hooks/useResponsive';
import Background from './Background';
import WhatIsProtocon from './WhatIsProtocon';
import WelcomeToProtocon from './WelcomeToProtocon';
import ScrollAnimation1 from './ScrollAnimation1';
import PillarsOfMitum from './PillarsOfMitum';
import HowToStart from './HowToStart';
import MainNet from './MainNet';
import ProtocolEconomy from './ProtocolEconomy';
import FeeFi from './FeeFi';
import Roadmap from './Roadmap';
import Community from './Community';
import Newsletter from './Newsletter';
import ScrollAnimation2 from './ScrollAnimation2';
import HiddenPreview from './HiddenPreview';

const Home: React.FC = () => {
  const { isMobile, isTablet } = useResponsive();

  const background = isMobile
    ? image.background.PageHomeMobile
    : isTablet
    ? image.background.PageHomeTablet
    : image.background.PageHomeDesktop;

  return (
    <Content background={background}>
      <HiddenPreview />
      <Background />
      <WelcomeToProtocon />
      <WhatIsProtocon />
      <ScrollAnimation1 />
      <PillarsOfMitum />
      <HowToStart />
      <MainNet />
      <ProtocolEconomy />
      <FeeFi />
      <ScrollAnimation2 />
      <Roadmap />
      <Community />
      <Newsletter />
    </Content>
  );
};

export default Home;
