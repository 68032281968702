import * as React from 'react';
import styled from 'styled-components';
import { Icon } from 'components';
import {
  APP_MAX_WIDTH,
  BASE_X_PADDING,
  GOTOTOP_Z_INDEX,
  MEDIA_QUERY,
} from 'properties/constant';

const GoToTop: React.FC = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const [isHover, setIsHover] = React.useState<boolean>(false);

  return (
    <Container>
      <Inner>
        <Button
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          onClick={scrollToTop}
        >
          <Icon.GoToTop isActive={isHover} />
        </Button>
      </Inner>
    </Container>
  );
};

export default GoToTop;

const Container = styled.div`
  position: fixed;
  z-index: ${GOTOTOP_Z_INDEX};
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  display: flex;
  justify-content: center;
`;

const Inner = styled.div`
  position: relative;
  width: 100%;
  max-width: ${APP_MAX_WIDTH}px;
  padding: 0 ${BASE_X_PADDING}px;
  display: flex;
  justify-content: flex-end;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    padding: 0 ${MEDIA_QUERY.TABLET.BASE_X_PADDING}px;
  }

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    padding: 0 ${MEDIA_QUERY.MOBILE.BASE_X_PADDING}px;
  }
`;

const Button = styled.div`
  position: absolute;
  right: 0;
  bottom: 100px;
  width: 32px;
  height: 32px;
  cursor: pointer;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    right: 50px;
    bottom: 62px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    right: 22px;
    bottom: 16px;
  }
`;
