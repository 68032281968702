import * as React from 'react';
import styled from 'styled-components';
import { useCommunityChannelListQuery } from 'apis/hooks';
import { Section, Icon } from 'components';
import { GradientBorder } from 'styles/Common';
import { MEDIA_QUERY } from 'properties/constant';

const setChannelIcon = (type: string) => {
  if (type === 'github') return <Icon.Github />;
  if (type === 'medium') return <Icon.Medium />;
  if (type === 'naver') return <Icon.Naver />;
  if (type === 'telegram') return <Icon.Telegram />;
  if (type === 'twitter') return <Icon.Twitter />;
  if (type === 'youtube') return <Icon.Youtube />;
  return null;
};

const ChannelList: React.FC = () => {
  const { data: channelList } = useCommunityChannelListQuery();

  if (channelList === undefined) return null;

  const handleLink = (link?: string) => {
    if (link === undefined) return;
    window.open(link, '_blank', 'noopener noreferrer');
  };

  return (
    <Container>
      <CardList>
        {channelList.map((el) => {
          const { key, icon, title, subtitle, link } = el.fields;

          if (key === undefined) return null;
          if (icon === undefined) return null;
          if (title === undefined) return null;
          if (subtitle === undefined) return null;

          const type = icon.toLowerCase();

          return (
            <ChannelCard key={el.id} onClick={() => handleLink(link)}>
              <div className="inner">
                <div className="icon">{setChannelIcon(type)}</div>
                <div className="title">{title}</div>
                <div className="text">{subtitle}</div>
              </div>
            </ChannelCard>
          );
        })}
      </CardList>
    </Container>
  );
};

export default ChannelList;

const Container = styled(Section)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CardList = styled.div`
  width: 100%;
  max-width: 946px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 23px;
  row-gap: 23px;
  flex-wrap: wrap;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    column-gap: 16px;
    row-gap: 16px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    column-gap: 10px;
    row-gap: 10px;
  }
`;

const ChannelCard = styled(GradientBorder)`
  width: calc(33.3333% - (23px * 2 / 3));
  background: #ffffff;
  border-radius: 12px;
  overflow: hidden;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    width: calc(33.3333% - (16px * 2 / 3));
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: calc(50% - (10px / 2));
  }

  .inner {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    padding: 35px 23px 27px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
      padding: 35px 16px 30px;
    }
    @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
      padding: 26px 10px 22px;
    }

    .icon {
      width: 58px;
      height: 58px;
      @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
        width: 66px;
        height: 66px;
      }
      @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
        width: 48px;
        height: 48px;
      }
    }

    .title {
      width: 100%;
      margin-top: 16px;
      text-align: center;
      font-family: ofelia-text, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 19px;
      line-height: 40px;
      letter-spacing: -0.06em;
      color: #000000;
      @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
        margin-top: 15px;
        font-size: 26px;
        line-height: 56px;
      }
      @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
        margin-top: 11px;
        font-size: 20px;
        line-height: 41px;
      }
      @media (max-width: 389px) {
        margin-top: 11px;
        font-size: 16px;
        line-height: 36px;
      }
    }

    .text {
      width: 100%;
      text-align: center;
      font-family: basic-sans, sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.04em;
      color: #000000;
      @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
        font-size: 21px;
        line-height: 34px;
      }
      @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
        font-size: 16px;
        line-height: 25px;
      }
      @media (max-width: 389px) {
        font-size: 10px;
        line-height: 20px;
      }
    }
  }

  :hover {
    ::before {
      -webkit-mask: none;
    }
    .icon circle {
      fill: none;
    }
    .title,
    .text {
      color: #ffffff;
    }
  }
`;
