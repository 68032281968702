import styled, { keyframes } from 'styled-components';
import { useResponsive } from 'hooks';
import { MEDIA_QUERY } from 'properties/constant';
import { Section } from 'components';
import { image } from 'assets';
import { useScrollY } from 'hooks/useScrollY';
import { useEffect, useRef, useState } from 'react';

const MainNet = () => {
  const { isMobile } = useResponsive();
  const { getOffset } = useScrollY();
  const [offsetTop, setOffsetTop] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setOffsetTop(containerRef.current?.offsetTop!);
  }, []);

  return (
    <div ref={containerRef}>
      <Container>
        <div>
          <TextContainer>
            <Title>
              {`Build an independent and\ninteroperable mainnet ecosystem\nthrough `}
              <em className="gradient-font-color">{`multi-chain.`}</em>
            </Title>
            <Text>{`Protocon enables inter-blockchain\ntransfers of any type of data or assets.`}</Text>
          </TextContainer>
          <Relative>
            <CardContainer>
              <CardTitle>{'Protocon Mainnet\n Enterprise'}</CardTitle>
              <Button>
                <span>{'comming soon'}</span>
              </Button>
            </CardContainer>
            <BgObject
              src={image.object.BgObjMainnet}
              className="bg-obj-1"
              alt="symbol"
            />
            <AnimatedObject src={image.object.ObjChain} alt="chain" />
            <div>
              <DotsContainer
                style={{
                  transform: `translateY(${getOffset(
                    isMobile ? 0.08 : 0.12,
                    offsetTop
                  )}px)`,
                }}
              >
                <Object1 src={image.object.Obj0} alt="object" />
                <Object2 src={image.object.Obj0} alt="object" />
                <Object3 src={image.object.Obj0} alt="object" />
              </DotsContainer>
            </div>
          </Relative>
        </div>
      </Container>
    </div>
  );
};

export default MainNet;

const Container = styled(Section)`
  padding-bottom: 328px;
  padding-left: 200px;
  max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px;
  margin: 300px auto 0px;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    /* max-width: 640px; */
    margin-top: 547px;
    padding-bottom: 1010px;
    padding-left: 30px;
  }

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    margin: 300px 0px 0px;
    padding-bottom: 387px;
    padding-left: 30px;
  }
`;

const Relative = styled.div`
  position: relative;
`;

const TextContainer = styled.div`
  position: relative;
  z-index: 1;
`;
const Title = styled.h3`
  font-family: ofelia-text, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 58px;
  letter-spacing: -0.06em;
  padding-bottom: 20px;
  white-space: pre-line;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    white-space: inherit;
    width: 580px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    max-width: 360px;
    width: 100%;
    font-size: 28px;
    line-height: 40px;
    letter-spacing: -0.05em;
    padding-bottom: 30px;
  }
`;

const Text = styled.p`
  font-family: basic-sans, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 21px;
  line-height: 31px;
  padding-bottom: 48px;
  letter-spacing: -0.06em;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 17.5px;
    line-height: 30px;
    letter-spacing: -0.05em;
    padding-bottom: 280px;
    max-width: 280px;
    width: 100%;
    white-space: pre-line;
  }
`;

const CardContainer = styled.div`
  z-index: 10;
  background: linear-gradient(
    174.83deg,
    rgba(151, 242, 239, 0.3) 6.7%,
    rgba(252, 114, 255, 0.3) 146.55%
  );
  backdrop-filter: blur(2.5px);
  border-radius: 20px;
  padding: 35px 30px;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  flex: 1;

  width: 488px;
  height: 328px;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    width: 469px;
    height: 289px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 100%;
    padding: 25px;
    height: 220px;
    /* max-width: 420px; */
  }
`;

const CardTitle = styled.div`
  font-family: ofelia-text, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  letter-spacing: -0.05em;
  padding-bottom: 30px;
  white-space: pre-line;
  line-height: 42px;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 19px;
    line-height: 30px;
  }
`;

const Button = styled.div`
  color: #000000;
  border: 1px solid #000000;
  border-radius: 13px;
  padding: 20px 34px;
  position: absolute;
  right: 35px;
  bottom: 35px;

  span {
    font-family: ofelia-text, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: -0.05em;
  }

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    padding: 20px 34px;
    right: 25px;
    bottom: 25px;
  }
`;

const MoveUpDown = keyframes`
  0%, 100% {
    margin-top: -70px;
  }
  50% {
    margin-top: 0px;
  }
`;

const MoveUpDownMobile = keyframes`
  0%, 100% {
    margin-top: -75px;
  }
  50% {
    margin-top: -50px;
  }
`;

const BgObject = styled.img`
  position: absolute;
  right: -120px;
  top: -100px;
  width: 917px;
  height: 587px;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    top: 320px;
    right: -180px;
    min-width: 839px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    top: -360px;
    right: -360px;
    min-width: 639px;
    height: 409px;
  }
`;

const AnimatedObject = styled.img`
  width: 294.07px;
  height: 267.71px;
  position: absolute;
  margin-left: 280px;
  top: 120px;

  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  animation: ${MoveUpDown} 5s ease-in-out infinite;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    margin-left: 200px;
    top: 600px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 130.24px;
    height: 118.57px;
    margin-left: 100px;
    top: -100px;
    animation: ${MoveUpDownMobile} 4s ease-in-out infinite;
  }
`;

const DotsContainer = styled.div`
  width: 346px;
  height: 393px;
  position: absolute;
  top: -80px;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  margin-left: 100px;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    top: -100px;
    margin-left: 40px;
  }

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    top: -180px;
    margin-left: 40px;
  }
`;

const Object1 = styled.img`
  position: absolute;
  width: 26px;
  height: 26px;
  left: 40px;
  opacity: 0.5;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    left: 30px;
    top: 420px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    top: 0px;
    left: 40px;
    width: 10.15px;
    height: 11.27px;
  }
`;

const Object2 = styled.img`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 300px;
  left: 300px;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    top: 700px;
    left: 300px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 9.02px;
    height: 10.15px;
    top: 100px;
    left: 120px;
  }
`;

const Object3 = styled.img`
  width: 32px;
  height: 32px;
  position: absolute;
  top: 335px;
  left: 0px;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    top: 780px;
    left: 0px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 14.66px;
    height: 13.53px;
    top: 140px;
    left: 10px;
  }
`;
