import * as React from 'react';
import styled from 'styled-components';
import { useResponsive } from 'hooks/useResponsive';

import { image as assetImage } from 'assets';
import { GradientText } from 'styles/Common';
import { ButtonWithArrow, Section } from 'components';

import { MEDIA_QUERY } from 'properties/constant';
import { CONTENT_CREATE_PROTOCON_WELLET } from 'properties/contents';

const ProtoconWallet: React.FC = () => {
  const { isTablet } = useResponsive();
  const { title, subTitle, text, buttonLabel, image, imageAlt } =
    CONTENT_CREATE_PROTOCON_WELLET;

  const textText = isTablet ? text.tablet : text.desktop;

  const handleLink = (link?: string) => {
    if (link === undefined) return;
    window.open(link, '_blank', 'noopener noreferrer');
  };

  return (
    <Section>
      <Container>
        <div>
          <SubTitle>{subTitle}</SubTitle>
          <Title>{title}</Title>
          <Text>{textText}</Text>
          <ButtonWithArrow
            label={buttonLabel}
            onClick={() => handleLink('https://www.protoconwallet.io/')}
          />
          <Object1 src={assetImage.group.Group83} alt="object" />
        </div>
        <Image src={image} alt={imageAlt} />
      </Container>
    </Section>
  );
};

export default ProtoconWallet;

const Container = styled.div`
  position: relative;
  max-width: 1024px;
  margin: 254px auto 240px;
  display: flex;
  justify-content: space-between;
  padding-left: 42px;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    max-width: 744px;
    margin: 254px auto 829px;
    padding-left: 70px;
  }

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    display: block;
    margin: 494px auto 219px;
    padding-left: 0px;
  }
`;

const SubTitle = styled(GradientText)`
  font-family: ofelia-text, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.05em;
`;

const Title = styled.h2`
  font-family: ofelia-text, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 75px;
  letter-spacing: -0.06em;
  color: #000000;
  padding-bottom: 28px;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 32px;
    line-height: 46px;
    white-space: pre-line;
    padding-bottom: 35px;
  }
`;

const Text = styled.div`
  font-family: basic-sans, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 21px;
  line-height: 31px;
  letter-spacing: -0.06em;
  color: #000000;
  padding-bottom: 48px;
  width: 438px;
  white-space: pre-line;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    width: 290px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 17.5px;
    line-height: 30px;
    max-width: 345px;
    width: 100%;
  }
`;

const Image = styled.img`
  width: 362px;
  height: 724px;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    position: absolute;
    top: 220px;
    right: 30px;
    width: 333px;
    height: 666px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    position: relative;
    min-width: calc(100% + 44px);

    height: auto;
    top: 0px;
    right: 50%;
    left: 50%;
    transform: translate(-50%);
  }
  @media (max-width: 300px) {
    min-width: 140%;
    height: auto;
  }
`;

const Object1 = styled.img`
  width: 163px;
  height: 134px;
  padding-top: 5px;
  margin-top: 155px;
  margin-left: -30px;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    position: absolute;
    top: 840px;
    right: 250px;
  }

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    position: absolute;
    top: -340px;
    right: 30px;

    width: 114px;
    height: 94px;
  }
`;
