import * as React from 'react';
import styled from 'styled-components';
import { MEDIA_QUERY } from 'properties/constant';
import { Icon } from 'components';
import {
  TELEGRAM_NOTICE_EN,
  TWITTER,
  GITHUB,
  YOUTUBE,
  MEDIUM,
} from 'properties/links';

type SNStype = 'telegram' | 'twitter' | 'github' | 'youtube' | 'medium';

interface SNS {
  id: SNStype;
  order: number;
  icon: (isHover: boolean) => JSX.Element;
  link?: string;
}

const snsList: SNS[] = [
  {
    id: 'telegram',
    order: 1,
    icon: (isHover) => <Icon.Telegram isActive={isHover} />,
    link: TELEGRAM_NOTICE_EN,
  },
  {
    id: 'twitter',
    order: 2,
    icon: (isHover) => <Icon.Twitter isActive={isHover} />,
    link: TWITTER,
  },
  {
    id: 'github',
    order: 3,
    icon: (isHover) => <Icon.Github isActive={isHover} />,
    link: GITHUB,
  },
  {
    id: 'youtube',
    order: 4,
    icon: (isHover) => <Icon.Youtube isActive={isHover} />,
    link: YOUTUBE,
  },
  {
    id: 'medium',
    order: 5,
    icon: (isHover) => <Icon.Medium isActive={isHover} />,
    link: MEDIUM,
  },
];

interface Props {
  onClick: (link?: string) => void;
}

const SNSList: React.FC<Props> = ({ onClick }) => {
  const [hoverId, setHoverId] = React.useState<SNStype | undefined>(undefined);

  return (
    <Container>
      {snsList.map((sns) => (
        <SNSIcon
          key={sns.id}
          order={sns.order}
          onMouseEnter={() => setHoverId(sns.id)}
          onMouseLeave={() => setHoverId(undefined)}
          onClick={() => onClick(sns.link)}
        >
          {sns.icon(sns.id === hoverId)}
        </SNSIcon>
      ))}
    </Container>
  );
};

export default SNSList;

const Container = styled.div`
  margin-bottom: 18px;
  display: flex;
  column-gap: 9px;
  cursor: pointer;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    margin-bottom: 26px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    margin-bottom: 40px;
  }
`;

const SNSIcon = styled.div<{ order: number }>`
  max-width: 48px;
  max-height: 48px;
  order: ${({ order }) => order};
`;
