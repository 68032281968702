import * as React from 'react';
import styled from 'styled-components';
import {
  BASE_X_PADDING,
  MEDIA_QUERY,
  APP_MAX_WIDTH,
} from 'properties/constant';

interface Props {
  className?: string;
  children?: React.ReactNode;
}

const Section: React.FC<Props> = ({ className, children }) => {
  return <Container className={className}>{children}</Container>;
};

export default Section;

const Container = styled.section`
  width: 100%;
  max-width: ${APP_MAX_WIDTH}px;
  height: fit-content;
  padding: 0 ${BASE_X_PADDING}px;
  margin: 0 auto;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    padding: 0 ${MEDIA_QUERY.TABLET.BASE_X_PADDING}px;
  }

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    padding: 0 ${MEDIA_QUERY.MOBILE.BASE_X_PADDING}px;
  }
`;
