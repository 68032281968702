import { useQuery } from '@tanstack/react-query';
import { requestCommunityNewsBanner } from 'apis/airtable';
import type {
  CommunityNewsBanner,
  CommunityNewsBannerResponse,
} from 'apis/airtable.type';

const useCommunityNewsBannerQuery = () => {
  return useQuery(['CommunityNewsBanner'], async () => {
    const { records }: CommunityNewsBannerResponse =
      await requestCommunityNewsBanner();

    const resp: CommunityNewsBanner[] = records.map((el) => ({
      id: el.id,
      fields: {
        key: el.fields['Key'],
        order: el.fields['Order'],
        image: el.fields['Image'],
        title: el.fields['Title'],
        text: el.fields['Description'],
        link: el.fields['Link'],
      },
    }));

    return resp;
  });
};

export default useCommunityNewsBannerQuery;
