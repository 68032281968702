import styled from 'styled-components';
import { Section } from 'components';
import { useResponsive } from 'hooks/useResponsive';
import { MEDIA_QUERY } from 'properties/constant';
import { HOME_WHAT_IS_PROTOCON } from 'properties/contents';
import { image } from 'assets';

const WhatIsProtocon = () => {
  const { isMobile } = useResponsive();
  const { title, text } = HOME_WHAT_IS_PROTOCON;

  return (
    <Container>
      <TextContainer>
        <div>
          <TitleContainer>
            <Title>{title}</Title>
            <TitleBg src={image.object.ObjTitleRing} alt="background" />
          </TitleContainer>
          <Text>{isMobile ? text.mobile : text.desktop}</Text>
        </div>
        {/* TODO: 문서 작업 미완료으로 인한 숨김 처리, 문서 완료 후 오픈 예정 (23.2.10) */}
        {/* <Center>
          <ButtonWithArrow label={"Learn more"} onClick={() => {}} />
        </Center> */}
      </TextContainer>
    </Container>
  );
};

export default WhatIsProtocon;

const Container = styled(Section)`
  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    padding-top: 30px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    padding-top: 240px;
  }
`;

const TextContainer = styled.div`
  position: relative;
  z-index: 20;
`;

const TitleContainer = styled.div`
  position: relative;
`;
const TitleBg = styled.img`
  position: absolute;

  width: 311px;
  height: 76px;
  top: 52px;

  left: 50%;
  right: 50%;
  transform: translate(-50%);
  z-index: 0;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 232px;
    height: 52px;
    top: 43px;
  }
`;

const Title = styled.h2`
  font-family: ofelia-text, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.05em;
  white-space: pre-line;

  z-index: 1;
  position: inherit;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 32px;
    line-height: 46px;
    letter-spacing: -0.06em;
  }
`;

const Text = styled.div`
  padding: 30px 0 40px;

  font-family: basic-sans, sans-serif;
  font-weight: 300;
  font-size: 21px;
  line-height: 31px;
  text-align: center;
  letter-spacing: -0.06em;
  color: #000000;
  width: 708px;
  margin: 0 auto;
  white-space: pre-line;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    max-width: 344px;
    width: 100%;
    font-size: 17.5px;
    line-height: 30px;
    text-align: center;
    padding-bottom: 38px;
  }
`;

// const Center = styled.div`
//   text-align: center;
// `;
