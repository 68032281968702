import * as React from 'react';
import styled from 'styled-components';

import { Section } from 'components';
import { GradientText } from 'styles/Common';

import { image } from 'assets';
import { CONTENT_ABOUT } from 'properties/contents';
import { MEDIA_QUERY } from 'properties/constant';
import { useResponsive } from 'hooks/useResponsive';

const WhatIsPen: React.FC = () => {
  const { isTablet, isMobile } = useResponsive();
  const { title, subTitle, description } = CONTENT_ABOUT;

  const descriptionText = isTablet
    ? description.tablet
    : isMobile
    ? description.mobile
    : description.desktop;

  const titleBg = isMobile ? image.group.Group82Mobile : image.group.Group82;
  return (
    <Container>
      <SubTitle>{subTitle}</SubTitle>
      <TitleContainer>
        <TitleBg src={titleBg} />
        <Title>{title}</Title>
      </TitleContainer>
      <Text>{descriptionText}</Text>
    </Container>
  );
};

export default WhatIsPen;

const Container = styled(Section)`
  text-align: center;
  margin: 124px auto 92px;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    width: 480px;
    margin: 29px auto 126px;
  }

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    margin: 24px auto 70px;
    max-width: 390px;
    width: 100%;
  }
`;

const SubTitle = styled(GradientText)`
  font-family: ofelia-text, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.05em;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 15px;
    line-height: 30px;
  }
`;

const Title = styled.div`
  font-family: ofelia-text, sans-serif;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  letter-spacing: -0.05em;
  color: #000000;
  padding-bottom: 36px;
  z-index: 1;
  position: inherit;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 32px;
    line-height: 46px;
    letter-spacing: -0.06em;
    padding-bottom: 46px;
  }
`;

const Text = styled.div`
  font-family: basic-sans, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 21px;
  line-height: 31px;
  text-align: center;
  letter-spacing: -0.06em;
  white-space: pre-line;
  color: #000000;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 17.5px;
    line-height: 30px;
  }
`;

const TitleContainer = styled.div`
  position: relative;
  padding: 10px;
`;

const TitleBg = styled.img`
  width: 421px;
  height: 104.99px;

  position: absolute;
  top: -4px;
  left: 50%;
  right: 50%;
  transform: translate(-50%);
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    top: -12px;
    width: 272px;
    height: 91.78px;
  }
`;
