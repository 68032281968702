import { MEDIA_QUERY } from 'properties/constant';
import * as React from 'react';
import styled from 'styled-components';

interface Props {
  title: string;
  subTitle?: string;
  desc?: string;
  imgSrc?: string;
  imgAlt?: string;
  buttonLabel: string;
  onClick: () => void;
}

const CardGradient: React.FC<Props> = ({
  title,
  subTitle,
  desc,
  imgSrc,
  imgAlt,
  buttonLabel,
  onClick,
}) => {
  return (
    <Container>
      {subTitle && <SubTitle>{subTitle}</SubTitle>}
      <Title>{title}</Title>
      <Text>{desc}</Text>
      <Bottom>
        <div>{imgSrc && <Image src={imgSrc} alt={imgAlt} />}</div>
        <Button onClick={onClick}>
          <span>{buttonLabel}</span>
        </Button>
      </Bottom>
    </Container>
  );
};

export default CardGradient;

const Container = styled.figure`
  background: linear-gradient(
    174.83deg,
    rgba(151, 242, 239, 0.3) 6.7%,
    rgba(252, 114, 255, 0.3) 146.55%
  );
  backdrop-filter: blur(2.5px);
  border-radius: 20px;
  padding: 35px 30px;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  flex: 1;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    padding: 30px;
  }
`;

const Title = styled.h3`
  font-family: ofelia-text, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  letter-spacing: -0.05em;
  padding-bottom: 30px;
  white-space: pre-line;
  line-height: 42px;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 23px;
    line-height: 31px;
    padding-bottom: 25px;
  }
`;

const SubTitle = styled.h4`
  font-family: ofelia-text, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 36px;
  letter-spacing: -0.05em;
  padding-bottom: 10px;
`;

const Text = styled.p`
  font-family: basic-sans, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 34px;
  letter-spacing: -0.05em;
  white-space: pre-line;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 16.5px;
    line-height: 31px;
  }
`;

const Bottom = styled.div`
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    padding-top: 25px;
  }
`;

const Button = styled.div`
  color: #000000;
  border: 1px solid #000000;
  border-radius: 13px;
  padding: 20px 34px;
  position: absolute;
  right: 35px;
  bottom: 35px;

  span {
    font-family: ofelia-text, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: -0.05em;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    right: 19px;
    bottom: 30px;
    padding: 17px 26px;
  }
`;

const Image = styled.img`
  width: 127px;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 92px;
  }
`;
