import * as React from 'react';
import styled from 'styled-components';
import { Icon } from 'components';

interface Props {
  label: string;
  onClick: () => void;
}

const ButtonWithArrow: React.FC<Props> = ({ label, onClick }) => {
  return (
    <Container onClick={onClick}>
      <span>{label}</span>
      <div className="arrow">
        <Icon.ArrowRight />
      </div>
    </Container>
  );
};

export default ButtonWithArrow;

const Container = styled.a`
  display: inline-flex;
  column-gap: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  span {
    font-family: ofelia-text, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: -0.05em;
    color: #000000;
  }

  .arrow {
    height: 12px;
    width: 6px;
  }
`;
