import * as React from 'react';

const XButton: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => {
  // const name = "icon-x-button";

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22 1 1 22M1 1l21 21"
        stroke="#000"
        strokeWidth={1.3}
        strokeLinecap="square"
      />
    </svg>
  );
};

export default XButton;
