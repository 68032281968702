import styled from 'styled-components';
import { ButtonGradient, Section } from 'components';
import { image } from 'assets';
import { MEDIA_QUERY } from 'properties/constant';
import { useEffect, useRef, useState } from 'react';
import { useScrollY } from 'hooks/useScrollY';

const HowToStart = () => {
  const { getOffset } = useScrollY();
  const [offsetTop, setOffsetTop] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setOffsetTop(containerRef.current?.offsetTop!);
  }, []);

  return (
    <div ref={containerRef}>
      <Container>
        <ListItem>
          <ObjectList>
            <div
              style={{
                transform: `translateY(${getOffset(0.15, offsetTop)}px)`,
              }}
            >
              <Object1 src={image.object.ObjCrossBlendMode} alt="testnet" />
            </div>
          </ObjectList>
          <TextContainer>
            <Title>{`Run a node\non testnet`}</Title>
            <Text>
              Test nodes on the testnet in preparation for the Mainnet.
            </Text>
            <ButtonGradient label="coming soon" onClick={() => {}} />
          </TextContainer>
        </ListItem>
        <ListItem>
          <ObjectList>
            <div
              style={{
                transform: `translateY(${getOffset(-0.06, offsetTop)}px)`,
              }}
            >
              <Object2 src={image.object.Obj20} alt="object" />
            </div>

            <div
              style={{
                transform: `translateY(${getOffset(0.08, offsetTop)}px)`,
              }}
            >
              <Object3 src={image.object.ObjDonutBlendMode} alt="object" />
            </div>
          </ObjectList>
          <TextContainer>
            <Title>{`Developer\nResources`}</Title>
            <Text>{`See our Get Started Guide, Tutorials, SDKs`}</Text>
            <ButtonGradient label="coming soon" onClick={() => {}} />
          </TextContainer>
        </ListItem>
      </Container>
    </div>
  );
};

export default HowToStart;

const Container = styled(Section)`
  width: 925px;
  margin: 416px auto 0px;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    margin-top: 507px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    margin-top: 480px;
    width: 100%;
  }
`;

const Text = styled.p`
  font-family: basic-sans, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 21px;
  line-height: 31px;
  letter-spacing: -0.06em;
  padding-bottom: 24px;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    width: 337px;
    padding-bottom: 30px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 17.5px;
    line-height: 30px;
    padding-bottom: 24px;
    max-width: 280px;
    width: 100%;
  }
`;

const ObjectList = styled.div`
  width: 324px;
  text-align: center;
  margin-right: 22px;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    position: absolute;
  }
`;

const TextContainer = styled.div`
  z-index: 1;
  position: relative;
`;

const Title = styled.h3`
  font-family: ofelia-text, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 58px;
  letter-spacing: -0.06em;
  padding-bottom: 20px;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 32px;
    line-height: 46px;
    padding-bottom: 15px;
  }
`;

const ListItem = styled.div`
  display: flex;
  padding-bottom: 100px;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    display: block;
    padding-left: 137px;
    white-space: pre-line;
    padding-bottom: 103px;
  }
  &:nth-child(2) {
    position: relative;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    padding-left: 0px;
    padding-bottom: 74px;
  }
`;

const Object1 = styled.img`
  width: 324px;
  height: 294px;
  margin-top: -20px;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    margin-top: -120px;
    margin-left: 256px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 248px;
    height: 225.65px;
    margin-left: 140px;
  }
`;

const Object2 = styled.img`
  width: 52px;
  height: 43px;
  margin-left: 140px;
  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    margin-left: 420px;
    margin-top: 60px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    margin-top: 0px;
    margin-left: 200px;
    width: 43px;
    height: 36px;
  }
`;

const Object3 = styled.img`
  width: 160px;
  height: 160px;
  margin-left: -40px;
  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    margin-left: 430px;
    margin-top: -220px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    margin-top: -160px;
    width: 109.36px;
    height: 109.36px;
    margin-left: 250px;
  }
`;
