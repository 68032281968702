import { useQuery } from '@tanstack/react-query';
import { requestCommunityChannelList } from 'apis/airtable';
import type {
  CommunityChannelList,
  CommunityChannelListResponse,
} from 'apis/airtable.type';

const useCommunityChannelListQuery = () => {
  return useQuery(['CommunityChannelList'], async () => {
    const { records }: CommunityChannelListResponse =
      await requestCommunityChannelList();

    const resp: CommunityChannelList[] = records.map((el) => ({
      id: el.id,
      fields: {
        key: el.fields['Key'],
        order: el.fields['Order'],
        icon: el.fields['Icon'],
        title: el.fields['Title'],
        subtitle: el.fields['SubTitle'],
        link: el.fields['Link'],
      },
    }));

    return resp;
  });
};

export default useCommunityChannelListQuery;
