import * as React from 'react';

const SliderNext: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => {
  // const name = "icon-slider-next";

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 17 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="m1 1 15 15L1 31" stroke="#000" strokeWidth={1.2} />
    </svg>
  );
};
export default SliderNext;
