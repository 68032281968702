import * as React from 'react';
import styled from 'styled-components';
import { MEDIA_QUERY } from 'properties/constant';
import { SITE_MAP } from 'properties/sitemap';

interface Props {
  onClick: (link?: string) => void;
}

const SiteMapList: React.FC<Props> = ({ onClick }) => {
  return (
    <Container>
      {SITE_MAP.map((el) => (
        <SiteMapColumn key={el.id}>
          <span>{el.label}</span>
          {el.sub && (
            <SiteMapItem>
              {el.sub.map((subEl) => {
                return (
                  <LinkLabel
                    key={subEl.id}
                    onClick={() => onClick(subEl.link)}
                    isLink={!!subEl.link}
                  >
                    {typeof subEl.label === 'string' ? (
                      <span>{subEl.label}</span>
                    ) : (
                      subEl.label.map((labelEl, _i) => (
                        <span className="multi" key={_i}>
                          {labelEl}
                        </span>
                      ))
                    )}
                  </LinkLabel>
                );
              })}
            </SiteMapItem>
          )}
        </SiteMapColumn>
      ))}
    </Container>
  );
};

export default SiteMapList;

const Container = styled.div`
  width: 100%;
  padding: 0 66px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 60px;
  row-gap: 50px;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    padding: 0;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 40px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    padding: 0;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 4px;
  }
`;

const SiteMapColumn = styled.div`
  width: 100%;
  min-width: 130px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 10px;

  & > span {
    font-family: ofelia-text, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: -0.05em;
    color: #000000;
    mix-blend-mode: normal;
  }

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    min-width: 130px;
    row-gap: 10px;
    & > span {
      font-size: 16px;
      line-height: 23px;
    }
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    min-width: 120px;
    row-gap: 7px;
    & > span {
      font-size: 15px;
      line-height: 21px;
    }
  }
`;

const SiteMapItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const LinkLabel = styled.div<{ isLink: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  cursor: pointer;

  & > span {
    font-family: basic-sans, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 35px;
    letter-spacing: -0.05em;
    color: #000000;
    mix-blend-mode: normal;
    white-space: pre-line;
    cursor: ${({ isLink }) => (isLink ? 'pointer' : 'default')};

    @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
      line-height: 35px;
    }
    @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
      line-height: 30px;
    }

    &.multi {
      line-height: 1.25;
    }
  }

  :hover,
  :active {
    & > span {
      ${({ isLink }) => isLink && `font-weight: 600;`};
    }
  }
`;
