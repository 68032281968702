import * as React from 'react';
import styled from 'styled-components';
interface Props {
  label: string;
  onClick: () => void;
  full?: boolean;
}

const ButtonGradient: React.FC<Props> = ({ label, onClick, full = false }) => {
  return (
    <Container full={full}>
      <div onClick={onClick}>
        <span>{label}</span>
      </div>
    </Container>
  );
};

export default ButtonGradient;

const Container = styled.div<{ full: boolean }>`
  width: ${({ full }) => (full ? '100%' : 'fit-content')};
  background: linear-gradient(111.93deg, #4ae6e6 3.86%, #fc72ff 115.46%);
  border-radius: 13px;
  cursor: pointer;
  color: white;

  & > div {
    font-family: ofelia-text, sans-serif;
    display: block;
    background: linear-gradient(111.93deg, #4ae6e6 3.86%, #fc72ff 115.46%);
    border-radius: 13px;
    padding: 20px 40px;
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    letter-spacing: -0.05em;
  }

  span {
    color: #ffffff;
  }

  :hover {
    background-image: linear-gradient(#fff, #fff),
      linear-gradient(111.93deg, #4ae6e6 3.86%, #fc72ff 115.46%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    border: 1px solid transparent;
    border-radius: 13px;

    & > div {
      background: white;
      padding: 19px 39px;
    }

    span {
      background: linear-gradient(111.93deg, #4ae6e6 3.86%, #fc72ff 115.46%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
`;
