import styled from 'styled-components';

import { BackgroundVideo, ButtonGradient, Section } from 'components';
import { image, video } from 'assets';
import { MEDIA_QUERY } from 'properties/constant';
import { HOME_WELCOME_TO_PROTOCON } from 'properties/contents';
import { useResponsive } from 'hooks';
import { useEffect, useRef, useState } from 'react';
import { useScrollY } from 'hooks/useScrollY';

const WelcomeToProtocon = () => {
  const { isDesktop } = useResponsive();
  const { title, subTitle, text, buttonLabel } = HOME_WELCOME_TO_PROTOCON;
  const [offsetTop, setOffsetTop] = useState(0);
  const { getOffset } = useScrollY();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setOffsetTop(containerRef.current?.offsetTop!);
  }, []);

  const handleLink = (link?: string) => {
    if (link === undefined) return;
    window.open(link, '_blank', 'noopener noreferrer');
  };

  return (
    <div ref={containerRef}>
      <Container>
        <BackgroundVideo
          video={video.MainMotion}
          image={image.poster.MainMotion}
        />
        <div>
          {/* 스크롤 이벤터 */}
          <AnimationContainer>
            <div
              style={{
                transform: `translateY(${getOffset(0.25, offsetTop)}px)`,
              }}
            >
              <Object1 src={image.object.Obj31} alt="object" />
            </div>
            <div
              style={{
                transform: `translateY(${getOffset(-0.25, offsetTop)}px)`,
              }}
            >
              <Object2 src={image.object.Obj32} alt="object" />
            </div>
          </AnimationContainer>

          {/* 텍스트 */}
          <TextContainer>
            <SubTitle>{subTitle}</SubTitle>
            <Title>{isDesktop ? title.desktop : title.tablet}</Title>
            <Text>{isDesktop ? text.desktop : text.tablet}</Text>
            <ButtonContainer>
              <ButtonGradient
                label={buttonLabel}
                onClick={() =>
                  handleLink(
                    'https://protocon-general-doc.readthedocs.io/en/latest/'
                  )
                }
              />
            </ButtonContainer>
          </TextContainer>
        </div>
      </Container>
    </div>
  );
};
export default WelcomeToProtocon;

const Container = styled(Section)`
  position: relative;
  padding-top: 151px;
  padding-bottom: 480px;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    padding-top: 49px;
    padding-bottom: 950px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    padding-top: 54px;
    padding-bottom: 83px;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const AnimationContainer = styled.div`
  position: relative;
  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    top: 540px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    top: 300px;
  }
`;
const SubTitle = styled.div`
  font-family: ofelia-text, sans-serif;
  padding-bottom: 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;

  letter-spacing: -0.05em;
  color: #000000;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    text-align: center;
    padding-bottom: 31px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 15px;
    letter-spacing: -0.05em;
    padding-bottom: 16px;
  }
`;

const TextContainer = styled.div`
  z-index: 1;
  position: relative;
`;

const Title = styled.div`
  font-family: ofelia-text, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 57px;
  line-height: 75px;
  letter-spacing: -0.06em;
  color: #000000;
  max-width: 900px;
  white-space: pre-line;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    font-size: 60px;
    line-height: 77px;
    text-align: center;
    letter-spacing: -0.06em;
    padding-bottom: 42px;
    margin: 0 auto;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 38px;
    line-height: 51px;
    max-width: 345px;

    padding-bottom: 27px;
  }
`;

const Text = styled.div`
  font-family: basic-sans, sans-serif;
  padding: 24px 0 48px;
  font-weight: 300;
  font-size: 23px;
  line-height: 33px;
  letter-spacing: -0.05em;
  color: #000000;
  max-width: 650px;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    font-family: basic-sans, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 21px;
    line-height: 31px;
    margin: 0 auto;
    width: 400px;
    white-space: pre-line;

    text-align: center;
    letter-spacing: -0.06em;
    padding-top: 0px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.03em;
    width: 264px;
    white-space: normal;
    padding-bottom: 240px;
  }
`;

const ButtonContainer = styled.div`
  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    display: flex;
    justify-content: center;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
  }
`;

const Object1 = styled.img`
  position: absolute;
  width: 65.27px;
  height: 101.32px;
  right: 10px;
  top: 80px;

  filter: drop-shadow(43px 50px 5px rgba(176, 233, 231, 0.38));
  transform: rotate(35.95deg);
  -webkit-transform: rotate(35.95deg) translateZ(0);

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    left: calc(50% + 240px);
    top: 60px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    top: 0px;
    width: 33.34px;
    height: 51.74px;
    left: calc(50% + 100px);
    filter: drop-shadow(32px 36px 3px rgba(176, 233, 231, 0.38));
  }

  @media (max-width: 365px) {
    top: 240px;
  }
  @media (max-width: 292px) {
    top: 280px;
  }
`;

const Object2 = styled.img`
  position: absolute;
  width: 98.45px;
  height: 88.49px;
  left: 550px;
  top: 460px;
  filter: drop-shadow(-37px 59px 5px rgba(176, 233, 231, 0.38));
  transform: rotate(-35.87deg);
  -webkit-transform: rotate(-35.87deg) translateZ(0);

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    top: 360px;
    left: 50%;
    right: 50%;
    margin-left: -290px;
    transform: translate(-50%), rotate(-35.87deg);
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 45.09px;
    height: 40.53px;
    margin-left: -140px;
    top: 150px;

    filter: drop-shadow(-26px 42px 3px rgba(176, 233, 231, 0.38));
  }
`;
