import * as React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import GlobalStyled from 'styles/global';
import AppRouter from 'Router';

const App: React.FC = () => {
  const queryClient = new QueryClient();
  return (
    <>
      <GlobalStyled />
      <QueryClientProvider client={queryClient}>
        <AppRouter />
      </QueryClientProvider>
    </>
  );
};

export default App;
