import * as React from 'react';
import styled from 'styled-components';
import { isMobile, isSafari } from 'react-device-detect';
import { MEDIA_QUERY } from 'properties/constant';

interface BackgroundVideoProps {
  video: string;
  image: string;
}

const BackgroundVideo: React.FC<BackgroundVideoProps> = ({ video, image }) => {
  return (
    <Container>
      {isMobile || isSafari ? (
        <img src={image} alt={image} />
      ) : (
        <video autoPlay muted loop src={video} />
      )}
    </Container>
  );
};

export default BackgroundVideo;

const Container = styled.div`
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  width: 1440px;

  img {
    width: 100%;
    object-fit: contain;
  }
  video {
    width: 100%;
  }

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    width: 1600px;
    top: 280px;
    left: 50%;
    right: 50%;
    transform: translate(-50%);
    margin-left: -200px;
  }

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 960px;
    left: 50%;
    right: 50%;
    transform: translate(-50%);
    margin-left: -130px;
    top: 120px;
  }
  @media (max-width: 360px) {
    top: 180px;
  }
`;
