import { useQuery } from '@tanstack/react-query';
import { requestAboutPenData } from 'apis/airtable';
import type { AboutPenData, AboutPenDataResponse } from 'apis/airtable.type';

const useAboutPenDataQuery = () => {
  return useQuery(['AboutPenData'], async () => {
    const { records }: AboutPenDataResponse = await requestAboutPenData();

    const resp: AboutPenData[] = records.map((el) => ({
      id: el.id,
      fields: {
        key: el.fields['Key'],
        name: el.fields['Name'],
        id: el.fields['Id'],
        symbol: el.fields['Symbol'],
        price: el.fields['Price'],
        marketCap: el.fields['MarketCap'],
        tradingVolume24h: el.fields['TradingVolume24h'],
        numberOfWallets: el.fields['NumberOfWallets'],
        lastUpdated: el.fields['LastUpdated'],
        cronDate: el.fields['CronDate'],
      },
    }));

    return resp;
  });
};

export default useAboutPenDataQuery;
