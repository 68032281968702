import styled from 'styled-components';
import { MEDIA_QUERY } from 'properties/constant';
import { GradientText } from 'styles/Common';
import { image } from 'assets';
import { Section } from 'components';
import { HOME_PROTOCON_ECONOMY } from 'properties/contents';
import { useResponsive } from 'hooks/useResponsive';

const ProtocolEconomy = () => {
  const { title, subTitle, text, pointText } = HOME_PROTOCON_ECONOMY;
  const { isMobile, isTablet } = useResponsive();
  return (
    <Container>
      <Object1 src={image.object.BgObjEconomy} alt="object" />
      <TextCenter>
        <TitleContainer
          bgImage={
            isMobile
              ? image.group.Group81Mobile
              : isTablet
              ? image.group.Group81Tablet
              : image.group.Group81
          }
        >
          <Title>{title}</Title>
        </TitleContainer>
        <SubTitle>{subTitle}</SubTitle>
        <Text>{isMobile ? text.mobile : text.desktop}</Text>
        <PointText>{pointText}</PointText>
      </TextCenter>
    </Container>
  );
};
export default ProtocolEconomy;

const Container = styled(Section)`
  padding-bottom: 530px;
  position: relative;
`;

const TextCenter = styled.div`
  text-align: center;
  z-index: 10;
  position: inherit;
`;

const TitleContainer = styled.div<{ bgImage: string }>`
  position: relative;
  background-image: url(${({ bgImage }) => bgImage});
  background-repeat: no-repeat;
  background-position: center 13px;
  background-size: 559px 132px;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    background-size: 508px 136px;
    background-position: center 7px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    background-position: left 8px;
    background-size: min(100%, 358px) 138px;
    margin: 0 -20px;
    padding: 0 20px;
  }
`;

const Title = styled.div`
  font-family: ofelia-text, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 58px;
  letter-spacing: -0.06em;
  padding: 54px 0px;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    font-family: ofelia-text, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 46px;
    letter-spacing: -0.06em;
    color: #000000;
    padding-bottom: 35px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    text-align: left;
  }
`;

const SubTitle = styled.div`
  font-family: ofelia-text, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 42px;
  letter-spacing: -0.05em;
  padding-bottom: 38px;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    text-align: left;
    padding-bottom: 22px;
  }
`;

const Text = styled.div`
  font-family: basic-sans, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 21px;
  line-height: 31px;
  letter-spacing: -0.06em;
  white-space: pre-line;
  padding-bottom: 38px;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 17.5px;
    line-height: 30px;
    text-align: left;
    padding-bottom: 27px;
  }
`;

const PointText = styled(GradientText)`
  font-family: basic-sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 35px;
  text-align: center;
  letter-spacing: -0.06em;
  white-space: pre-line;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
  }

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    text-align: left;
    font-size: 21px;
    line-height: 28px;
    display: block;
  }
`;

const Object1 = styled.img`
  position: absolute;
  left: 50%;
  right: 50%;
  transform: translate(-50%);

  width: 1000px;
  height: 602px;
  top: 500px;
  margin-left: 400px;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    min-width: 950px;
    width: 950px;
    height: 572px;
    top: 602px;
    margin-left: 0px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    bottom: 98px;

    width: 700px;
    min-width: 700px;
    margin-left: 54px;
    height: 421px;
    top: auto;
  }
`;
