import * as React from 'react';
import { useResponsive } from 'hooks/useResponsive';
import { image } from 'assets';
import { Content, Space } from 'components';
import Background from './Background';
import PageTitle from './PageTitle';
import ChannelList from './ChannelList';

const Channel: React.FC = () => {
  const { isMobile, isTablet } = useResponsive();

  const background = (() => {
    if (isTablet) return image.background.PageChannelTablet;
    if (isMobile) return image.background.PageChannelMobile;
    return image.background.PageChannel;
  })();

  return (
    <Content background={background}>
      <Background />
      <Space desktop={80} tablet={44} mobile={20} />
      <PageTitle />
      <ChannelList />
      <Space desktop={220} />
    </Content>
  );
};

export default Channel;
