import * as React from 'react';
import styled from 'styled-components';
import { useResponsive } from 'hooks/useResponsive';
import { Section } from 'components';
import { MEDIA_QUERY } from 'properties/constant';

const PageTitle: React.FC = () => {
  const { isMobile } = useResponsive();

  return (
    <Container>
      <Title>Stay Connected</Title>
      <Text>
        Join the Protocon community today and
        <br />
        become a pioneer of the true
        {isMobile && <br />}
        protocol economy!
      </Text>
    </Container>
  );
};

export default PageTitle;

const Container = styled(Section)`
  margin-bottom: 68px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    margin-bottom: 49px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    margin-bottom: 54px;
  }
`;

const Title = styled.h3`
  font-family: ofelia-text, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 58px;
  letter-spacing: -0.06em;
  text-align: center;
  white-space: pre-line;
  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    font-size: 45px;
    line-height: 58px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 32px;
    line-height: 46px;
  }
`;

const Text = styled.p`
  margin-top: 20px;
  text-align: center;

  font-family: basic-sans, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 21px;
  line-height: 31px;
  letter-spacing: -0.06em;
  white-space: pre-line;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    font-size: 21px;
    line-height: 31px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 17.5px;
    line-height: 30px;
  }
`;
