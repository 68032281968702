import * as React from 'react';
import styled from 'styled-components';
import { image } from 'assets';
import { MEDIA_QUERY } from 'properties/constant';

const Background: React.FC = () => {
  return (
    <Container>
      <Object1 src={image.object.Obj26} alt={'bg-obj'} />
      <Object2 src={image.object.Obj32} alt={'bg-obj'} />
      <Object3 src={image.object.Obj20} alt={'bg-obj'} />
      <Object4 src={image.object.Obj20} alt={'bg-obj'} />
      <Object5 src={image.object.Obj20} alt={'bg-obj'} />
    </Container>
  );
};

export default Background;

const Container = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
  * {
    pointer-events: none;
    user-select: none;
  }
`;

const Object1 = styled.img`
  position: absolute;
  width: 435.91px;
  height: 464.34px;
  left: 218.85px;
  top: 95px;

  filter: blur(7.5px);
  transform: rotate(49.46deg);
  opacity: 0.2;
  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    width: 229.74px;
    height: 244.73px;
    left: -95.22px;
    top: 55.47px;
    filter: blur(2.5px);
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 201.27px;
    height: 214.4px;
    left: -100.76px;
    top: 40.96px;
    filter: blur(2.5px);
  }
`;

const Object2 = styled.img`
  position: absolute;
  width: 198.31px;
  height: 178.25px;
  top: 260.21px;
  right: 239px;

  transform: rotate(-35.87deg);
  opacity: 0.5;
  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    width: 180.19px;
    height: 161.96px;
    right: -84.55px;
    top: 204.43px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 150.04px;
    height: 134.86px;
    right: -63.32px;
    top: 220.17px;
  }
`;

const Object3 = styled.img`
  position: absolute;
  z-index: 3;
  width: 208.33px;
  height: 172.27px;
  right: 306px;
  top: 981.32px;

  transform: rotate(69.84deg);
  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    width: 159.61px;
    height: 131.99px;
    right: 131.74px;
    top: 1016.69px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 123.65px;
    height: 102.25px;
    right: 116.88px;
    top: 1153.21px;
  }
`;

const Object4 = styled.img`
  position: absolute;
  z-index: 2;
  width: 208.33px;
  height: 172.27px;
  right: 223px;
  top: 950.95px;

  transform: rotate(69.84deg);
  opacity: 0.3;
  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    width: 159.61px;
    height: 131.99px;
    right: 68.34px;
    top: 993.42px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 123.65px;
    height: 102.25px;
    right: 67.77px;
    top: 1135.18px;
  }
`;

const Object5 = styled.img`
  position: absolute;
  z-index: 1;
  width: 208.33px;
  height: 172.27px;
  right: 174px;
  top: 933px;

  transform: rotate(69.84deg);
  opacity: 0.15;
  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    width: 159.61px;
    height: 131.99px;
    right: 30.89px;
    top: 979.67px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 123.65px;
    height: 102.25px;
    right: 38.75px;
    top: 1124.53px;
  }
`;
