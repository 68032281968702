import { useQuery } from '@tanstack/react-query';
import { requestCommunityNewsList } from 'apis/airtable';
import type {
  CommunityNewsList,
  CommunityNewsListResponse,
} from 'apis/airtable.type';

const useCommunityNewsListQuery = () => {
  return useQuery(['CommunityNewsList'], async () => {
    const { records }: CommunityNewsListResponse =
      await requestCommunityNewsList();

    const resp: CommunityNewsList[] = records.map((el) => ({
      id: el.id,
      fields: {
        key: el.fields['Key'],
        create: el.fields['Create'],
        update: el.fields['Update'],
        category: el.fields['Category'],
        tags: el.fields['Tags'],
        image: el.fields['Image'],
        imageLink: el.fields['Image Link'],
        title: el.fields['Title'],
      },
    }));

    return resp;
  });
};

export default useCommunityNewsListQuery;
