import * as React from 'react';
import { useResponsive } from 'hooks/useResponsive';
import { image } from 'assets';
import { Content, Space } from 'components';
import Background from './Background';
import PageTitle from './PageTitle';
import TopBanner from './TopBanner';
import NewsList from './NewsList';

const News: React.FC = () => {
  const { isMobile, isTablet } = useResponsive();

  const background = (() => {
    if (isTablet) return image.background.PageNewsTablet;
    if (isMobile) return image.background.PageNewsMobile;
    return image.background.PageNews;
  })();

  return (
    <Content background={background}>
      <Background />
      <Space desktop={80} tablet={44} mobile={20} />
      <PageTitle />
      <TopBanner />
      <NewsList />
      <Space desktop={220} />
    </Content>
  );
};

export default News;
