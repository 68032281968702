import styled from 'styled-components';
import { useResponsive } from 'hooks/useResponsive';

import { MEDIA_QUERY } from 'properties/constant';

import { image } from 'assets';

const Roadmap = () => {
  const { isTablet, isMobile } = useResponsive();
  const roadmapImage = isMobile
    ? image.roadmap.RoadmapMobile
    : isTablet
    ? image.roadmap.RoadmapTablet
    : image.roadmap.Roadmap;

  const roadmapBg1 = isMobile
    ? image.roadmap.RoadmapBg1Mobile
    : isTablet
    ? image.roadmap.RoadmapBg1Tablet
    : image.roadmap.RoadmapBg1;

  const roadmapBg2 = isMobile
    ? image.roadmap.RoadmapBg2Mobile
    : isTablet
    ? image.roadmap.RoadmapBg2Tablet
    : image.roadmap.RoadmapBg2;

  return (
    <Container>
      <RoadMapBg1 style={{ backgroundImage: `url(${roadmapBg1})` }} />
      <RoadMapBg2 style={{ backgroundImage: `url(${roadmapBg2})` }} />
      <ImageContainer>
        <RoadmapImage style={{ backgroundImage: `url(${roadmapImage})` }} />
      </ImageContainer>
    </Container>
  );
};

export default Roadmap;

const Container = styled.section`
  margin-bottom: 800px;
  position: relative;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    position: relative;
    margin-bottom: 0px;
    margin-top: -400px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    padding-bottom: 0px;
    margin-bottom: 0px;
    margin-top: -600px;
  }
`;

const ImageContainer = styled.div`
  padding: 0 22px;
`;

const RoadmapImage = styled.div`
  width: 100%;
  height: 343px;
  max-width: 1269px;
  margin: auto;
  background-size: contain;
  -o-background-size: contain;
  -moz-background-size: contain;
  -webkit-background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 3;
  position: relative;
  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    height: 2960px;
    width: 483px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    max-width: 346px;
    width: 100%;
    height: 2083px;
  }
`;

const RoadMapBg1 = styled.div`
  width: 105%;
  height: 100%;

  position: absolute;
  left: -2.5%;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  mix-blend-mode: overlay;
  filter: blur(25px);
  z-index: 0;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    height: 2860px;
    width: 483px;
    left: 53%;
    right: 50%;
    transform: translate(-50%);
    filter: none;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    display: none;
  }
`;

const RoadMapBg2 = styled.div`
  position: absolute;
  left: 0px;
  z-index: 1;

  width: 100%;
  height: 100%;

  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  background-blend-mode: color-dodge;
  mix-blend-mode: color-dodge;
  filter: blur(7.5px);

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    height: 2960px;
    left: 13px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    mix-blend-mode: overlay;

    height: 2083px;
    width: 27.06px;
    background-size: cover;
    left: 50%;
    right: 50%;
    transform: translate(-50%);
  }
`;
