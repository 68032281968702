import { image } from 'assets';

export const COMMUNITY_LIST = [
  {
    id: '1',
    name: 'Community Chat',
    text: 'Ask general questions and partake in discussions \nwith our global community on Telegram!',
    icon: image.icon.CommunityChat,
    iconHover: image.icon.CommunityChatActive,
    url: 'https://bit.ly/3LbzeQd',
  },
  {
    id: '2',
    name: 'Twitter',
    text: 'Follow @Protoconpen to receive the latest news and updates about the Protocon Ecosystem',
    icon: image.icon.Twitter,
    iconHover: image.icon.TwitterActive,
    url: 'https://bit.ly/36idMug',
  },
  {
    id: '3',
    name: 'Github',
    text: 'Are you a developer? Contribute to dev discussions and code on our GitHub!',
    icon: image.icon.Github,
    iconHover: image.icon.GithubActive,
    url: 'https://bit.ly/36FOiqk',
  },
  {
    id: '4',
    name: 'Developer Chat',
    text: 'If you have any technical questions about Protocon, please reach out to our fellow developers in our community.',
    icon: image.icon.DeveloperChat,
    iconHover: image.icon.DeveloperChat,
    url: null,
  },
];
