import * as React from 'react';
import styled from 'styled-components';
import { Section, Icon } from 'components';
import { GradientText } from 'styles/Common';
import { CONTENT_BUY_PEN } from 'properties/contents';
import { MEDIA_QUERY } from 'properties/constant';
import { useResponsive } from 'hooks/useResponsive';

// TODO: 버튼 정보 properties로 빼기
const iconSize = {
  desktop: {
    mexc: { width: 208, height: 34 },
    bitmart: { width: 160, height: 46 },
    digfinex: { width: 180, height: 39 },
  },
  tablet: {
    mexc: { width: 130, height: 22 },
    bitmart: { width: 100, height: 29 },
    digfinex: { width: 113, height: 25 },
  },
};

const BuyPen: React.FC = () => {
  const [activePen, setActivePen] = React.useState(-1);
  const { isTablet } = useResponsive();
  const { subTitle, title, text } = CONTENT_BUY_PEN;

  const { mexc, bitmart, digfinex } = isTablet
    ? iconSize.tablet
    : iconSize.desktop;

  const handleLink = (link?: string) => {
    if (link === undefined) return;
    window.open(link, '_blank', 'noopener noreferrer');
  };

  return (
    <Container>
      <SubTitle>{subTitle}</SubTitle>
      <Title>{title}</Title>
      <Text>{text}</Text>

      <ButtonList>
        <Button
          onClick={() => handleLink('https://www.mexc.com/')}
          onMouseEnter={() => setActivePen(0)}
          onMouseLeave={() => setActivePen(-1)}
        >
          <Icon.Mexc
            isActive={activePen === 0}
            height={mexc.height}
            width={mexc.width}
          />
        </Button>

        <Button
          onClick={() => handleLink('https://www.bitmart.com/en-US')}
          onMouseEnter={() => setActivePen(1)}
          onMouseLeave={() => setActivePen(-1)}
        >
          <Icon.Bitmart
            isActive={activePen === 1}
            height={bitmart.height}
            width={bitmart.width}
          />
        </Button>
        <Button
          onClick={() => handleLink('https://www.digifinex.com/en-ww')}
          onMouseEnter={() => setActivePen(2)}
          onMouseLeave={() => setActivePen(-1)}
        >
          <Icon.Digfinex
            isActive={activePen === 2}
            height={digfinex.height}
            width={digfinex.width}
          />
        </Button>
      </ButtonList>
    </Container>
  );
};

export default BuyPen;

const Container = styled(Section)`
  padding-bottom: 200px;
  width: fit-content;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    max-width: 744px;
    padding-bottom: 152px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    padding-bottom: 108px;
  }
`;

const SubTitle = styled(GradientText)`
  font-family: ofelia-text, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.05em;
`;

const Title = styled.h2`
  font-family: ofelia-text, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 75px;
  letter-spacing: -0.06em;
  color: #000000;
  padding-bottom: 28px;

  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 32px;
    line-height: 46px;
    padding-bottom: 35px;
  }
`;

const Text = styled.div`
  font-family: basic-sans, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 21px;
  line-height: 31px;
  letter-spacing: -0.06em;
  color: #000000;
  padding-bottom: 48px;
  white-space: pre-line;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    padding-bottom: 38px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 17.5px;
    line-height: 30px;
    white-space: normal;
    padding-bottom: 36px;
    max-width: 345px;
    width: 100%;
  }
`;

const ButtonList = styled.div`
  display: flex;
  gap: 28px;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    gap: 18px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    flex-direction: column;
  }
`;

const Button = styled.div`
  width: 297px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  background-image: linear-gradient(#fff, #fff),
    linear-gradient(111.93deg, #4ae6e6 3.86%, #fc72ff 115.46%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  border: 1px solid transparent;
  border-radius: 13px;

  &:hover {
    background: linear-gradient(111.93deg, #4ae6e6 3.86%, #fc72ff 115.46%);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-width: 0px;
  }

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    width: 185.04px;
    height: 74.76px;
    border-radius: 8px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 100%;
    height: 120px;
    box-sizing: border-box;
    max-width: 390px;
  }
`;
