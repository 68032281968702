import * as React from 'react';
import styled from 'styled-components';
import { useResponsive } from 'hooks/useResponsive';
import { useCommunityNewsListQuery } from 'apis/hooks';
import { Section } from 'components';
import { MEDIA_QUERY } from 'properties/constant';
import CategoryDesktop from './Category.Desktop';
import CategoryMobile from './Category.Mobile';
import type { NewsCategoryListType, NewsCategoryType } from 'types/types';

const CATEGORY_LIST: NewsCategoryListType = {
  default: 'Latest news',
  announcement: 'Announcement',
  media: 'Media',
  report: 'Report',
  article: 'Article',
};

const NewsList: React.FC = () => {
  const { data: newsList } = useCommunityNewsListQuery();

  const { isMobile } = useResponsive();

  const [category, setCategory] = React.useState<NewsCategoryType>('default');

  if (newsList === undefined) return null;

  return (
    <Container>
      {!isMobile ? (
        <CategoryDesktop
          category={CATEGORY_LIST}
          current={category}
          setCategory={setCategory}
        />
      ) : (
        <CategoryMobile
          category={CATEGORY_LIST}
          current={category}
          setCategory={setCategory}
        />
      )}
      <List>
        {newsList.map((el) => {
          const {
            key,
            category: dataCategory,
            tags,
            image,
            imageLink,
            title,
          } = el.fields;

          if (key === undefined) return null;
          if (dataCategory === undefined) return null;
          if (image === undefined) return null;
          if (title === undefined) return null;

          if (category !== 'default' && category !== dataCategory.toLowerCase())
            return null;

          const chips = tags ? [dataCategory, ...tags] : [dataCategory];

          return (
            <ListItem key={el.id}>
              <ListItemImage
                href={imageLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={image[0].url} alt={image[0].filename} />
              </ListItemImage>
              <ListItemTitle>{title}</ListItemTitle>
              <ListItemTags>
                {chips.map((tag) => (
                  <span key={el.id + '-' + tag}>{tag}</span>
                ))}
              </ListItemTags>
            </ListItem>
          );
        })}
      </List>
    </Container>
  );
};

export default NewsList;

const Container = styled(Section)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const List = styled.div`
  width: 100%;
  margin-bottom: 58px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 110px;
  column-gap: 30px;
  flex-wrap: wrap;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    padding: 0 24px;
    column-gap: 16px;
    row-gap: 110px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    padding: 0;
    column-gap: 16px;
    row-gap: 100px;
  }
`;

const ListItem = styled.div`
  width: calc(33.3333% - (30px * 2 / 3));

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    width: calc(50% - (16px / 2));
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 100%;
  }
`;

const ListItemImage = styled.a`
  margin-bottom: 18px;
  display: flex;

  img {
    object-fit: cover;
    border-radius: 6px;
  }
`;

const ListItemTitle = styled.div`
  width: 100%;
  margin-bottom: 18px;

  font-family: ofelia-text, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 35px;
  letter-spacing: -0.06em;
  color: #000000;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    font-size: 20;
    line-height: 31px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    font-size: 20px;
    line-height: 35px;
  }
`;

const ListItemTags = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  row-gap: 10px;
  column-gap: 10px;
  flex-wrap: wrap;

  span {
    flex: 0 0 auto;
    width: fit-content;
    padding: 6px 14px;
    background: #ceeff0;
    border: 1.5px solid #ffffff;
    border-radius: 13px;

    font-family: basic-sans, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.04em;
    color: #2a7b7b;
    cursor: pointer;

    :hover {
      background: #9eebed;
      color: #000000;
    }
  }
`;
