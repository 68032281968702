import * as React from 'react';

const ArrowRight: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => {
  // const name = "icon-arrow-right";

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="m1 1 6 6-6 6" stroke="#000" strokeLinecap="round" />
    </svg>
  );
};
export default ArrowRight;
