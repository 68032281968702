import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  APP_MAX_WIDTH,
  BASE_X_PADDING,
  HEADER_HEIGHT,
  HEADER_Z_INDEX,
  MEDIA_QUERY,
} from 'properties/constant';
import { useResponsive } from 'hooks/useResponsive';
import { Icon, Navigation, SideNavigation } from 'components';
import { useBodyScrollLock } from 'hooks';

const Header: React.FC = () => {
  const { isDesktop } = useResponsive();

  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const { lockScroll, openScroll } = useBodyScrollLock();

  return (
    <Container>
      <Inner>
        <Logo to={'/'}>
          <Icon.LogoProtocon />
        </Logo>
        <Spacing />
        {isDesktop ? (
          <Navigation />
        ) : (
          <IconBox
            onClick={() => {
              lockScroll();
              setIsOpen(true);
            }}
          >
            <Icon.Menu />
          </IconBox>
        )}
        {isOpen && (
          <SideNavigation
            onClose={() => {
              openScroll();
              setIsOpen(false);
            }}
          />
        )}
      </Inner>
    </Container>
  );
};

export default Header;

const Container = styled.div`
  position: absolute;
  z-index: ${HEADER_Z_INDEX};
  width: 100%;
  height: ${HEADER_HEIGHT}px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    height: ${MEDIA_QUERY.TABLET.HEADER_HEIGHT}px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    height: ${MEDIA_QUERY.MOBILE.HEADER_HEIGHT}px;
  }
`;

const Inner = styled.div`
  width: 100%;
  max-width: ${APP_MAX_WIDTH}px;
  height: 100%;
  padding: 0 ${BASE_X_PADDING}px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    padding: 0 ${MEDIA_QUERY.TABLET.BASE_X_PADDING}px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    padding: 0 ${MEDIA_QUERY.MOBILE.BASE_X_PADDING}px;
  }
`;

const Logo = styled(Link)`
  flex: 0 0 auto;
  width: 142px;
  height: 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${MEDIA_QUERY.TABLET.MAX_WIDTH}px) {
    width: 134px;
    height: 24px;
  }
  @media (max-width: ${MEDIA_QUERY.MOBILE.MAX_WIDTH}px) {
    width: 120px;
    height: 22px;
  }
`;

const Spacing = styled.div`
  flex: 1 0 auto;
`;

const IconBox = styled.div`
  width: 36px;
  padding: 4px;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
