// Airtable
export const AIRTABLE_API_URL = 'https://api.airtable.com/v0';
export const AIRTABLE_BASE_ID = 'appkYNzsquoWZCkiY';
export const AIRTABLE_TOKEN = process.env.REACT_APP_AIRTABLE_TOKEN;

// Stibee
export const STIBEE_API_URL = 'https://api.stibee.com/v1/';
export const STIBEE_LIST_ID_ENG = '181152';
export const STIBEE_LIST_ID_KOR = '115064';
export const STIBEE_ACCESS_TOKEN = process.env.REACT_APP_STIBEE_ACCESS_TOKEN;

// STYLE
export const APP_MAX_WIDTH = 1200;
export const HEADER_Z_INDEX = 1000;
export const SIDE_NAV_Z_INDEX = 2000;
export const GOTOTOP_Z_INDEX = 900;
export const HEADER_HEIGHT = 108;
export const BASE_X_PADDING = 30;
export const FOOTER_HEIGHT = 300;

export const MEDIA_QUERY = {
  TABLET: {
    MAX_WIDTH: 1199,
    HEADER_HEIGHT: 72,
    BASE_X_PADDING: 16,
  },
  MOBILE: {
    MAX_WIDTH: 743,
    HEADER_HEIGHT: 62,
    BASE_X_PADDING: 22,
  },
};
